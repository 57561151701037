import { BaseService } from '../baseService';
import { RoleType, UserInfo } from '../user/userInfo';
import { PERMISSION_API } from '../../../constants/apiConstants';
import { ProjectPermissions } from './projectPermissions';

class PermissionService extends BaseService {
  private static instance: PermissionService;

  public static getInstance(): PermissionService {
    if (!PermissionService.instance) {
      PermissionService.instance = new PermissionService();
    }
    return PermissionService.instance;
  }

  public transferSuperAdminToUser(userID: number) {
    return PermissionService.instance.post(
      PERMISSION_API.transferSuperAdminToUser(userID)
    );
  }

  public addPermissionToUser(userID: number, permission: RoleType) {
    return PermissionService.instance.post(
      PERMISSION_API.addPermissionToUser(userID, permission)
    );
  }

  public removePermissionToUser(userID: number, permission: RoleType) {
    return PermissionService.instance.delete(
      PERMISSION_API.removePermissionToUser(userID, permission)
    );
  }

  public getUsersWithSpecifiedPermission(permission: RoleType) {
    return PermissionService.instance.get<UserInfo[]>(
      PERMISSION_API.getUsersWithSpecifiedPermission(permission)
    );
  }
  public getMembershipPermissions() {
    return PermissionService.instance.get<ProjectPermissions>(
      PERMISSION_API.getProjectMembersPermissions()
    );
  }
}

export default PermissionService.getInstance();
