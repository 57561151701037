import React, { useState } from 'react';
import Modal from '../applicant-custom-dialog-modal';
import '../applicant-custom-dialog-modal/styles.scss';
import { Button, IconButton, FlexRow, TextInput } from '@epam/promo';
import { Text } from '@epam/uui';
import { ReactComponent as navigationClose } from '@epam/assets/icons/common/navigation-close-24.svg';
import { IApplicant } from 'modules/list-opportunities/models/model';

interface ApplicantCustomDialogProps {
  isOpen: boolean;
  onClose: () => void;
  applicant: IApplicant;
  position: string;
  onAccept: (
    applicantId: number,
    comment: string,
    closeOpportunity: boolean
  ) => void;
  onReject: (applicantId: number, comment: string) => void;
  actionType: 'accept' | 'reject';
  projectName: string;
}

const ApplicantCustomDialog = ({
  isOpen,
  onClose,
  applicant,
  position,
  onAccept,
  onReject,
  actionType,
  projectName,
}: ApplicantCustomDialogProps) => {
  const [comments, setComments] = useState('');

  const handleAccept = (closeOpportunity: boolean) => {
    onAccept(applicant.membershipRequestId, comments, closeOpportunity);
    onClose();
  };

  const handleReject = () => {
    onReject(applicant.membershipRequestId, comments);
    onClose();
  };

  const dialogTitle =
    actionType === 'accept' ? 'Accept application?' : 'Reject request?';
  const applicantName = `${applicant?.user.firstName} ${applicant?.user.lastName}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <FlexRow cx="header-diaolog-container">
        <Text cx="dialog-title-text">{dialogTitle}</Text>
        <IconButton
          color="gray50"
          onClick={onClose}
          aria-label="Close modal"
          icon={navigationClose}
          cx="close-button"
        />
      </FlexRow>
      <div className="message-text-container">
        <div className="message-text-inner-container">
          {actionType === 'accept' ? (
            <>
              <Text cx="message-text">{applicantName}</Text> is going to be
              added to {projectName} as a{' '}
              <Text cx="message-text">{position}</Text>. You can keep the
              opportunity open to receive more applications.
            </>
          ) : (
            <>
              <Text cx="message-text">{applicantName}</Text> will be rejected
              and removed from the applicants for{' '}
              <Text cx="message-text">{position}</Text> opportunity.
            </>
          )}
        </div>
      </div>
      <Text cx="dialog-comments">Comments</Text>
      <FlexRow cx="text-input-container">
        <TextInput
          placeholder="Please leave a comment"
          value={comments}
          onValueChange={(newValue) => setComments(newValue ?? '')}
          cx="text-input-custom-size"
        />
      </FlexRow>
      <FlexRow cx="buttons-container">
        {actionType === 'accept' && (
          <>
            <Button
              color="gray50"
              fill="white"
              iconPosition="right"
              caption="Accept and keep opportunity"
              onClick={() => handleAccept(false)}
            />
            <Button
              color="blue"
              iconPosition="right"
              caption="Accept and close opportunity"
              onClick={() => handleAccept(true)}
            />
          </>
        )}
        {actionType === 'reject' && (
          <>
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={onClose}
              iconPosition="right"
            />
            <Button
              color="blue"
              caption="Reject applicant"
              onClick={handleReject}
              iconPosition="right"
            />
          </>
        )}
      </FlexRow>
    </Modal>
  );
};
export default ApplicantCustomDialog;
