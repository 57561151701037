import { AuthProvider, useAuth } from 'react-oidc-context';
import React, { useEffect } from 'react';

interface IAuth {
  children: React.ReactElement;
}

const handleRedirectCallback = (): string => {
  const { href } = window.location;
  return href.includes(process.env.REACT_APP_NEW_DOMAIN_URL as string)
    ? (process.env.REACT_APP_NEW_DOMAIN_URL as string)
    : href.includes(process.env.REACT_APP_BASE_URL as string) ||
      href.includes(process.env.REACT_APP_LAB_URL as string)
    ? (process.env.REACT_APP_BASE_URL as string)
    : (process.env.REACT_APP_PROXY_URL as string);
};

export const REACT_APP_ENV =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_ENV
    : 'development';

const oidcConfig = {
  authority:
    REACT_APP_ENV === 'production'
      ? (process.env.REACT_APP_OIDC_ISSUER_LIVE as string)
      : (process.env.REACT_APP_OIDC_ISSUER as string),
  client_id:
    REACT_APP_ENV === 'production'
      ? (process.env.REACT_APP_OIDC_CLIENT_ID_LIVE as string)
      : (process.env.REACT_APP_OIDC_CLIENT_ID as string),
  redirect_uri: handleRedirectCallback(),
};

const AuthComponent = (props: IAuth) => {
  return (
    <AuthProvider
      {...oidcConfig}
      onSigninCallback={(User) => {
        const redirectURI = User
          ? (User.state as string)
          : window.location.pathname;
        window.history.replaceState({}, document.title, redirectURI);
      }}
    >
      <OIDCProvider>{props.children}</OIDCProvider>
    </AuthProvider>
  );
};

const OIDCProvider = ({ children }: IAuth) => {
  const auth = useAuth();
  const login = async () => {
    const { href } = window.location;
    if (!auth.isLoading && !auth.isAuthenticated) {
      await auth.signinRedirect({
        state: href,
      });
    }
  };

  const relogin = async () => {
    const { href } = window.location;
    if (!auth.isLoading && auth.isAuthenticated && auth.user?.expired) {
      await auth.signinRedirect({
        state: href,
      });
    }
  };

  useEffect(() => {
    login();
  }, [auth.isLoading]);

  useEffect(() => {
    relogin();
  }, [auth.user?.expired]);

  if (auth.isLoading) return <></>;
  if (auth.user?.access_token) {
    sessionStorage.setItem('token', auth.user.access_token);
  }

  return children;
};

export default AuthComponent;
