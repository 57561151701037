import React from 'react';
import './opportunities.scss';
import { HEADER_STYLES } from '../../constants/uiConstants';
import { PageHeader } from '../../component/page-header/page-header';
import OpportunityList from './index';
import { OpportunitySubscriptionButton } from 'component/Buttons/OpportunitySubscriptionButton';

const PATH_NAME = '/opportunities';

interface Props {
  editorSwitch: boolean;
}

function Opportunities({ editorSwitch }: Props): React.ReactElement {
  return (
    <div className="opportunities-page-container">
      <PageHeader
        title="Join an innovation Dream Team"
        background={HEADER_STYLES.COMMON.background}
        color={HEADER_STYLES.COMMON.color}
        actionBtn={OpportunitySubscriptionButton()}
      />
      <OpportunityList editorSwitch={editorSwitch} pathName={PATH_NAME} />
    </div>
  );
}

export default Opportunities;
