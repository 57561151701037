import React, { useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { IModal } from '@epam/uui';
import {
  ModalBlocker,
  ModalWindow,
  Panel,
  ModalHeader,
  ScrollBars,
  FlexRow,
  ModalFooter,
  FlexSpacer,
  Button,
} from '@epam/promo';

import 'cropperjs/dist/cropper.css';
import './styles.scss';

interface Props {
  modalProps: IModal<string>;
  imageSrc: string;
}

export const CropImageModal = ({ modalProps, imageSrc }: Props) => {
  const cropperEle = useRef<ReactCropperElement>(null);
  const getCroppedData = () => {
    const cropperInstance = cropperEle.current?.cropper;
    if (cropperInstance) {
      return cropperInstance.getCroppedCanvas().toDataURL('image/png', 0.5);
    }
    return '';
  };

  return (
    <ModalBlocker blockerShadow="dark" {...modalProps}>
      <ModalWindow height={'auto'} width={'600'}>
        <Panel background="white">
          <ModalHeader title="Crop Image" onClose={() => modalProps.abort()} />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24">
              <Cropper
                ref={cropperEle}
                style={{ height: '100%', width: '100%' }}
                zoomTo={0}
                aspectRatio={1}
                preview=".img-preview"
                src={imageSrc}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive
                autoCropArea={1}
                checkOrientation={false}
                guides
              />
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => modalProps.abort()}
            />
            <Button
              color="green"
              caption="Crop"
              onClick={() => modalProps.success(getCroppedData())}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default CropImageModal;
