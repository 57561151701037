import { IModal, useUuiContext } from '@epam/uui';
import {
  Button,
  FlexRow,
  FlexSpacer,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  ScrollBars,
  Text,
} from '@epam/promo';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'App';
import projectManagementService from 'services/api/project-management/projectManagementService';
import { QUERY_KEYS } from 'constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

interface Props {
  modalProps: IModal<string>;
  firstName: string;
  lastName: string;
  projectId: string;
  userId: string;
  setConfirmationWindow: (confirmationWindow: boolean) => void;
  setValue: any;
}

const TransferOwnershipModal = ({
  modalProps,
  firstName,
  lastName,
  projectId,
  userId,
  setValue,
}: Props) => {
  const svc = useUuiContext();

  const requestToTransferOwnership = useMutation<
    any,
    any,
    { projectId: number; externalUserID: string }
  >(
    ({ projectId, externalUserID }) =>
      projectManagementService.transferProjectOwnership(
        projectId,
        externalUserID
      ),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.USERS.PROJECT_COUNT]),
        ]);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const onCancel = () => {
    modalProps.abort();
    setValue(null);
  };
  return (
    <ModalBlocker blockerShadow="dark" {...modalProps} abort={onCancel}>
      <ModalWindow>
        <Panel background="white">
          <ModalHeader title="Transfer ownership?" onClose={onCancel} />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24" vPadding="12">
              <Text size="36">
                After transferring ownership to{' '}
                <b>
                  {firstName} {lastName}
                </b>
                , you will be excluded from the project and not be able to
                contribute anymore.
              </Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={onCancel}
            />
            <Button
              color="blue"
              caption="Transfer ownership"
              onClick={async () => {
                modalProps.success('Success action');
                svc.uuiModals
                  .show((props) => <BlockerModal modalProps={props} />, {
                    modalId: 'blocker',
                  })
                  .catch(() => null);
                requestToTransferOwnership.mutate({
                  projectId: parseInt(projectId),
                  externalUserID: userId,
                });

                setValue(null);
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};
export default TransferOwnershipModal;
