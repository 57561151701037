import {
  Button,
  FlexRow,
  FlexSpacer,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  ScrollBars,
  Text,
} from '@epam/promo';
import { IModal, useUuiContext } from '@epam/uui';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'App';
import { BlockerModal } from 'component/BlockerModal';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import React from 'react';
import opportunityService from 'services/api/opportunity/opportunityService';

interface MemberDeletionModalProps {
  modalBlockerProps: IModal<string>;
  projectId: string;
  opportunityId: number | undefined;
}

const AutoOpportunityDeletionModal: React.FC<MemberDeletionModalProps> = ({
  modalBlockerProps,
  projectId,
  opportunityId,
}) => {
  const svc = useUuiContext();

  const deleteOpportunityMutation = useMutation<
    any,
    any,
    { opportunityId: any }
  >(({ opportunityId }) => opportunityService.delOpportunity(opportunityId), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries([QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST]),
        queryClient.refetchQueries([
          QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
          projectId,
        ]),
      ]);
      useCloseBlocker(svc);
    },
    onError: (error) => {
      useCloseBlocker(svc);
      svc.uuiNotifications
        .show((props) => (
          <DefaultError notificationProps={props} error={error} />
        ))
        .catch(() => null);
    },
  });
  return (
    <ModalBlocker blockerShadow="dark" {...modalBlockerProps}>
      <ModalWindow>
        <Panel background="white">
          <ModalHeader
            title="Delete opportunity?"
            onClose={() => modalBlockerProps.abort()}
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24" vPadding="12">
              <Text size="36">
                You have just added a new team member under the same position as
                your open opportunity. Would you like to delete the opportunity?
              </Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Keep opportunity"
              onClick={() => modalBlockerProps.abort()}
            />
            <Button
              color="blue"
              caption="Delete opportunity"
              onClick={async () => {
                modalBlockerProps.success('success');
                svc.uuiModals
                  .show((props) => <BlockerModal modalProps={props} />, {
                    modalId: 'blocker',
                  })
                  .catch(() => null);
                deleteOpportunityMutation.mutate({
                  opportunityId: opportunityId,
                });
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default AutoOpportunityDeletionModal;
