import { CommonContexts } from '@epam/uui';

export const useCloseBlocker = (
  svc: CommonContexts<any, any>
): JSX.Element | null => {
  const operations = svc.uuiModals?.getOperations();
  const blockerModal = operations.find(
    (o) => o.props?.isActive && o.props?.parameters?.modalId === 'blocker'
  );

  blockerModal?.props.abort();
  return null;
};
