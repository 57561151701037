import { TimePickerValue } from '@epam/uui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export const prepareFormData = (event: any): FormData => {
  const formData = new FormData();
  Object.keys(event).forEach((key: string) => {
    formData.set(key, event[key]);
  });
  return formData;
};

export const formatName = (userInput: string): string =>
  userInput.replace(/\s{2,}/g, ' ').trim();

export const buildDate = (
  date: string,
  time?: TimePickerValue,
  timezone?: string
) => {
  const datetimeStr = time
    ? `${date}T${time.hours}:${time.minutes}`
    : `${date}T00:00`;
  if (timezone) {
    return dayjs.tz(datetimeStr, timezone);
  }
  return dayjs(datetimeStr);
};

export const compareTimes = (
  time1: TimePickerValue | null,
  time2: TimePickerValue | null
) => {
  if (time1 && time2)
    if (
      time1.hours > time2.hours ||
      (time1.hours === time2.hours && time1.minutes >= time2.minutes)
    )
      return true;
  return false;
};
