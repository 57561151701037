import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@epam/uui-components/styles.css';
import '@epam/promo/styles.css';
import AuthComponent from './services/auth-client';
import ReactGA from 'react-ga4';

if (process.env.REACT_APP_ENV === 'production') {
  ReactGA.initialize('G-80H3Q9G6T3');
}

ReactDOM.render(
  <AuthComponent>
    <App />
  </AuthComponent>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
