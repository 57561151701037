import React, { FC } from 'react';
import { Button, FillStyle, Tooltip } from '@epam/promo';
import { ButtonColor } from '@epam/promo/components/buttons/Button';
import { ControlSize } from '@epam/promo/components/types';

type Props = {
  color: ButtonColor;
  caption: string;
  loading?: boolean;
  fill?: FillStyle;
  disabled?: boolean;
  onClick: () => void;
  cx?: string;
  size?: ControlSize | '18';
  tooltipText?: string;
};

/**
 * Why do we use this rather than just using a normal UUI Button? Just the ToolTip?
 * If so it should probably be renamed to TooltipButton or something.
 */
export const ActionButton: FC<Props> = ({
  color,
  caption,
  loading,
  fill,
  disabled,
  onClick,
  cx,
  size,
  tooltipText,
}) => {
  return (
    <Tooltip content={tooltipText}>
      <Button
        size={size}
        cx={cx}
        color={color}
        fill={fill}
        isDisabled={disabled || loading}
        caption={caption}
        onClick={onClick}
      />
    </Tooltip>
  );
};
