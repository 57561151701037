import './styles.scss';
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  CheckboxGroup,
  Panel,
  Text,
  LabeledInput,
  PickerInput,
  LinkButton,
  Spinner,
} from '@epam/promo';
import { LazyDataSourceApiRequest, useLazyDataSource } from '@epam/uui';
import { ReactComponent as clearIcon } from '@epam/assets/icons/common/content-clear-12.svg';
import { useDictionary } from 'services/dictionary/dictionary-provider';
import ProjectListService from 'services/api/project-list/projectListService';
import { ISkilloTech } from 'modules/project-list/components/project-card/modal';
import { MAX_RESULTS_SKILLO_API } from 'constants/uiConstants';

export interface Props {
  onPositionsChange: (positions: string[]) => void;
  onTechnologiesChange: (technologies: string[]) => void;
}

export const OpportunityFilter: FC<Props> = ({
  onPositionsChange,
  onTechnologiesChange,
}) => {
  const { projectPositions } = useDictionary();
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState<
    ISkilloTech[]
  >([]);
  const [allPositions, setAllPositions] = useState<
    { name: string; id: string }[]
  >([]);

  const loadTechnologies = useCallback(
    async (request: LazyDataSourceApiRequest<ISkilloTech, string, unknown>) => {
      const query = request.search || 'A';
      const response = await ProjectListService.getSkilloTechsByQuery(
        query,
        MAX_RESULTS_SKILLO_API
      );
      return { items: response.results };
    },
    []
  );
  const allTechnologies = useLazyDataSource(
    { api: loadTechnologies, selectAll: false },
    []
  );

  useEffect(() => {
    setAllPositions(
      projectPositions?.items.map((p) => ({ name: p.label, id: p.value })) ?? []
    );
  }, [projectPositions]);

  useEffect(() => {
    onPositionsChange(selectedPositions);
  }, [selectedPositions]);

  useEffect(() => {
    onTechnologiesChange(selectedTechnologies.map((item) => item.name));
  }, [selectedTechnologies]);

  const clearFilter = () => {
    if (selectedPositions.length) {
      setSelectedPositions([]);
    }
    if (selectedTechnologies.length) {
      setSelectedTechnologies([]);
    }
  };

  return (
    <Panel cx="opportunity-filter" shadow background="white">
      <div className="opportunity-filter__header">
        <Text size="24" fontSize="16" font="sans-semibold">
          Filters
        </Text>
        <LinkButton
          caption="Clear all"
          onClick={clearFilter}
          icon={clearIcon}
          isDisabled={!selectedPositions.length && !selectedTechnologies.length}
          color="blue"
          size="24"
        />
      </div>

      <LabeledInput label="Position">
        {allPositions.length ? (
          <CheckboxGroup
            onValueChange={(checked) => setSelectedPositions(checked)}
            items={allPositions}
            value={selectedPositions}
            direction="vertical"
          />
        ) : (
          <Spinner color="blue" cx="loading-spinner" />
        )}
      </LabeledInput>

      <LabeledInput label="Skills" cx="opportunity-filter__technologies">
        <PickerInput
          dataSource={allTechnologies}
          value={selectedTechnologies}
          onValueChange={(selected) => setSelectedTechnologies(selected)}
          emptyValue={[]}
          valueType="entity"
          selectionMode="multi"
          sorting={{ field: 'name', direction: 'asc' }}
        />
      </LabeledInput>
    </Panel>
  );
};
