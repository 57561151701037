import React, { FC, useState } from 'react';
import { cx, IModal, useUuiContext } from '@epam/uui';
import './request-to-join-modal.scss';
import {
  Button,
  FlexSpacer,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  ScrollBars,
  Text,
  TextArea,
} from '@epam/promo';
import { ActionButton } from '../../../../../component/Buttons/ActionButton/action-button';
import { AlertModal } from '../../../../../component/AlertModal';
import { useMutation } from '@tanstack/react-query';
import {
  IOpportunity,
  IOpportunityItem,
} from 'modules/list-opportunities/models/model';
import opportunityService from 'services/api/opportunity/opportunityService';
import { queryClient } from 'App';
import { QUERY_KEYS } from 'constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

type Props = {
  modalProps: IModal<string>;
  projectID: number;
  opportunity: IOpportunity;
};

export const RequestToJoinModal: FC<Props> = ({
  modalProps,
  projectID,
  opportunity,
}) => {
  const [comment, setComment] = useState('');
  const svc = useUuiContext();

  // TODO: Implement UseLeaveConfirm
  const showLeaveConfirmation = () => {
    comment.length > 0
      ? svc.uuiModals
          .show((confirmModalProps: IModal<string>) => (
            <AlertModal
              modalProps={confirmModalProps}
              alertContent={{
                title: 'Discard changes?',
                noBorder: true,
                description:
                  'You have unsaved changes. If you leave, all changes will be lost.',
                closeText: 'Discard changes',
                cancelBtn: true,
              }}
            />
          ))
          .then(() => modalProps.abort())
          .catch(() => null)
      : modalProps.abort();
  };

  const requestToJoinMutation = useMutation<
    IOpportunityItem,
    any,
    { id: number; comment: string }
  >(({ id, comment }) => opportunityService.joinOpportunity(id, comment), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries([QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST]),
        queryClient.refetchQueries([
          QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
          projectID,
        ]),
      ]);
      useCloseBlocker(svc);
    },
    onError: (error) => {
      useCloseBlocker(svc);
      svc.uuiNotifications
        .show((props) => (
          <DefaultError notificationProps={props} error={error} />
        ))
        .catch(() => null);
    },
  });

  return (
    <ModalBlocker
      blockerShadow="dark"
      {...modalProps}
      abort={showLeaveConfirmation}
    >
      <ModalWindow cx="request-to-join-modal" width="600">
        <Panel background="white">
          <ModalHeader
            title="Want to join?"
            onClose={showLeaveConfirmation}
            borderBottom
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <div className="modal-body">
              <Text size="36">
                Your request will be sent to the Project Owner. You will not be
                able to apply to other opportunities within the same project.
              </Text>

              <div className="form-group">
                <Text cx="control-label" fontSize="14">
                  Comments
                </Text>

                <div className="comments-wrapper">
                  <TextArea
                    rows={8}
                    value={comment}
                    onValueChange={(newValue) => setComment(newValue)}
                    placeholder="Please describe your motivation."
                    isInvalid={comment.length > 225}
                  />

                  {comment.length > 225 && (
                    <div className="error-message">
                      The character limit is exceeded.
                    </div>
                  )}
                </div>

                <div
                  className={cx(
                    'length-count-text',
                    comment.length > 225 && 'has-error'
                  )}
                >
                  {comment.length} / 225
                </div>
              </div>
            </div>
          </ScrollBars>
          <ModalFooter borderTop>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={showLeaveConfirmation}
            />
            <ActionButton
              color="blue"
              disabled={comment.length == 0 || comment.length > 225}
              caption="Request to join"
              onClick={async () => {
                modalProps.success('success');
                svc.uuiModals
                  .show((props) => <BlockerModal modalProps={props} />, {
                    modalId: 'blocker',
                  })
                  .catch(() => null);
                requestToJoinMutation.mutate({
                  id: opportunity.id,
                  comment: comment,
                });
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};
