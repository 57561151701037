import React, { useRef, useEffect, useState } from 'react';
import { Button, Tag } from '@epam/promo';
import './styles.scss';
import { VALIDATION_ERRORS } from '../../../validationSchema';
import keywordsService from 'services/api/keywords/keywordsService';
interface ITagInput {
  placeholder: string;
  borderColor?: boolean;
  keyDownAgree: string;
  isRemovedOnBackSpace: boolean;
  value: Array<string>;
  onValueChange: (value: Array<string>) => void;
  isRequired?: boolean;
  invalidKeyword: boolean;
  setInvalidKeyword: (value: boolean) => void;
  maxTagAllowed?: number;
  maxTextLengthEachTag?: number;
  setInfo?: (info: string) => void;
  setCount?: (count: string) => void;
  id?: string;
}
const TagInput = ({
  placeholder,
  keyDownAgree,
  isRemovedOnBackSpace = true,
  ...props
}: ITagInput) => {
  const [autocomplete, setAutocomplete] = useState<string>('');
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(true);
  const [searchPhrase, setSearchPhrase] = useState<string>('');

  useEffect(() => {
    let delayDebounceFn: any;
    const getAutocompleteWord = async () => {
      const keyword = await keywordsService.getKeywords(1, searchPhrase);
      setAutocomplete(keyword[0].slice(searchPhrase.length));
      setLoadingAutocomplete(false);
    };
    if (searchPhrase.length > 1) {
      delayDebounceFn = setTimeout(() => {
        setLoadingAutocomplete(true);
        getAutocompleteWord();
      }, 500);
    }
    return () => {
      if (delayDebounceFn) {
        clearTimeout(delayDebounceFn);
      }
    };
  }, [searchPhrase]);

  const { value, onValueChange } = props;
  const LIMIT_TEXT_LENGTH = props?.maxTextLengthEachTag || 40;
  const LIMIT_TAG = props?.maxTagAllowed || 20;
  useEffect(() => {
    if (value && value.length) {
      props.setInfo?.('');
      props.setCount?.(`${value.length}/${props.maxTagAllowed}`);
      if (value.length <= LIMIT_TAG) {
        props.setInvalidKeyword(false);
      }
    } else {
      props.setInfo?.('');
      props.setCount?.(`${0}/${props.maxTagAllowed}`);
    }
  }, [value]);

  useEffect(() => {
    adjustInputWidth();
  }, [value, searchPhrase]);

  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const removeValue = (indexToRemove: number) => {
    onValueChange([...value.filter((_, index) => index !== indexToRemove)]);
    inputRef.current && inputRef.current?.focus();
  };
  const addValue = (event: any) => {
    if (event.target.value !== '') {
      const currentValue = [...value];
      if (props.invalidKeyword) {
        return;
      }
      if (currentValue.length === props.maxTagAllowed) {
        props.setInfo?.(VALIDATION_ERRORS.KEYWORDS_LIMIT(props.maxTagAllowed));
        props.setInvalidKeyword(true);
        return;
      }
      if (!currentValue) {
        onValueChange([event.target.value]);
      }
      if (currentValue && !currentValue.includes(event.target.value)) {
        onValueChange([...currentValue, event.target.value]);
      }
    }
    event.target.value = '';
  };

  const handleOnKeyDown = (event: any) => {
    if (props.invalidKeyword) return;
    if (event.key === 'Tab' && autocomplete) {
      event.preventDefault();
      setSearchPhrase(searchPhrase + autocomplete);
      setAutocomplete('');
    }
    if (event.key === keyDownAgree) {
      addValue(event);
      setSearchPhrase('');
      setAutocomplete('');
    }
    if (
      isRemovedOnBackSpace &&
      event.key === 'Backspace' &&
      event.target.value === ''
    ) {
      const newArrPop = [...value];
      newArrPop.pop();
      onValueChange([...newArrPop]);
    }
    if (props.invalidKeyword && event.key === 'Backspace') {
      props.setInvalidKeyword(false);
    }
  };

  const adjustInputWidth = () => {
    if (inputRef.current && spanRef.current) {
      spanRef.current.textContent = inputRef.current.value.replace(
        / /g,
        '\u00a0'
      );

      if (inputRef.current.value === '') {
        if (value?.length > 0) {
          inputRef.current.style.width = '8px';
        } else {
          inputRef.current.style.width = '632px';
        }
      } else {
        inputRef.current.style.width = `${spanRef.current.offsetWidth + 6}px`;
      }
    }
  };

  return (
    <>
      <div
        className="tag-input"
        style={{
          borderColor:
            props.invalidKeyword || props.borderColor ? 'red' : '#ced0db',
        }}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div className="keywords-input">
          <ul className="tags">
            {value?.length > 0 &&
              value.map((tag: string, index: number) => (
                <Tag
                  key={index}
                  cx="tag"
                  caption={tag}
                  onClear={() => removeValue(index)}
                  size={'30'}
                />
              ))}
          </ul>
          <input
            value={searchPhrase}
            id={props.id}
            ref={inputRef}
            type="text"
            onKeyDown={(event) => handleOnKeyDown(event)}
            placeholder={value.length ? undefined : placeholder}
            onChange={(event) => {
              setLoadingAutocomplete(true);
              setSearchPhrase(event.target.value);
              if (event.target.value.length > 40) {
                props.setInfo?.(VALIDATION_ERRORS.KEYWORD_TAG_LENGTH);
                props.setInvalidKeyword(true);
              }
              props.setCount?.(
                `${event.target.value.length}/${LIMIT_TEXT_LENGTH}`
              );
              if (props.invalidKeyword && event.target.value?.length <= 40) {
                props.setInfo?.('');
                props.setInvalidKeyword(false);
              }
            }}
          />
          <span className="width-input-reference" ref={spanRef}></span>
          <span className="autocomplete">
            {!loadingAutocomplete && autocomplete}
          </span>
        </div>
        {value?.length > 0 && (
          <Button
            cx="clear-button"
            onClear={() => onValueChange([])}
            fill="light"
            color="gray50"
            size="30"
          />
        )}
      </div>
    </>
  );
};

export default TagInput;
