import { FlexCell, FlexRow, Panel } from '@epam/promo';
import './styles.scss';
import { useUuiContext } from '@epam/uui';
import ProjectInfo from './project-info';
import ProjectTime from './project-time';
import ProjectOwner from './project-owner';
import { Project } from 'modules/project-list/models/interfaces';
import { toProjectCardProps } from 'modules/project-list/utils';
import { ProjectDetailsWrapper } from 'modules/project-management/details/components/project-details-wrapper';

const ProjectCard = (
  props: Project & {
    editorSwitch: boolean;
    dataUpdatedAt: number;
    isShowroom: boolean;
  }
): React.ReactElement => {
  const svc = useUuiContext();
  const projectCardProps = toProjectCardProps(props);
  const { info, owner, time, status } = projectCardProps;

  return (
    <Panel
      background="white"
      shadow
      style={{ marginBottom: '24px' }}
      cx={'project-card__wrapper'}
    >
      <FlexRow
        cx="project-card-item"
        onClick={() => {
          const oldUrl = window.location.href;
          window.history.replaceState(
            null,
            projectCardProps.info.name,
            `/dashboard/project/${projectCardProps.info.id}`
          );
          svc.uuiModals
            .show((modalProps) => {
              const detailProps = {
                modalProps,
                projectDetailProps: props,
                editorSwitch: props.editorSwitch,
                projectID: props.id,
                isShowroom: props.isShowroom,
              };
              return <ProjectDetailsWrapper {...detailProps} />;
            })
            .catch(() => {
              window.history.replaceState(null, 'Dashboard', oldUrl);
            });
        }}
      >
        <FlexCell cx="project-card-item__infor">
          <ProjectInfo
            {...info}
            dataUpdatedAt={props.dataUpdatedAt}
            status={status}
          />
        </FlexCell>
        <FlexCell cx="project-card-item__owner-time">
          <ProjectOwner {...owner} />
          <ProjectTime {...time} />
        </FlexCell>
      </FlexRow>
    </Panel>
  );
};

export default ProjectCard;
