import { Metadata } from '@epam/uui';
import { MAX_TECHNOLOGIES } from '../../../constants/uiConstants';
import {
  validProjectName,
  validURLRegExp,
  emailRegExp,
} from '../../../constants/regexpConstants';
import { ProjectDetails } from '../models/interfaces';
import { LINK_TYPES } from '../details/components/project-links/constants';

const { EMAIL, SLIDE_DECK, CASE_STUDY } = LINK_TYPES;

export const VALIDATION_ERRORS = Object.freeze({
  FIELD_IS_REQUIRED: 'This field is required.',
  CHARACTER_LIMIT: 'The character limit is exceeded.',
  PROJECT_NAME_RESERVED: (name: string) => `Project name '${name}' is reserved`,
  PROJECT_NAME_INVALID:
    'Project name can contain only letters (from English alphabet), digits, dot, dash, space. The last character must be a letter or a digit.',
  PROJECT_NAME_EXISTS:
    'This project name already exists. Please try again with a different name.',
  LINK_FORMAT_INVALID:
    'Link does not have a proper URL pattern i.e. http://example.com',
  LINK_DUPLICATE:
    'Such Link Type and URL are already added to the project. Try a different Link Type or URL.',
  KEYWORD_TAG_LENGTH:
    'You reached maximum tag length. Maximum tag length is 40 characters',
  TECHNOLOGIES_LIMIT: (limit: number) =>
    `You can select up to ${limit} technologies.`,
  KEYWORDS_LIMIT: (limit: number) => `You can add up to ${limit} keywords`,
  WRONG_EMAIL_FORMAT:
    'Email should correspond to the format i.e. sample@domain.com.',
});

export const projectDetailsSchema = (
  projectData: ProjectDetails
): Metadata<ProjectDetails> => ({
  props: {
    projectMetadata: {
      props: {
        name: {
          isRequired: true,
          validators: [
            (value) => [
              !value && VALIDATION_ERRORS.FIELD_IS_REQUIRED,
              value?.length > 40 && VALIDATION_ERRORS.CHARACTER_LIMIT,
              !validProjectName.test(value) &&
                VALIDATION_ERRORS.PROJECT_NAME_INVALID,
            ],
          ],
        },
        description: {
          isRequired: true,
          validators: [
            (value) => [
              !value && VALIDATION_ERRORS.FIELD_IS_REQUIRED,
              value?.length > 512 && VALIDATION_ERRORS.CHARACTER_LIMIT,
            ],
          ],
        },
      },
    },
    keywords: {
      isRequired: true,
      validators: [
        (value) => [
          (!value || value?.length === 0) &&
            VALIDATION_ERRORS.FIELD_IS_REQUIRED,
        ],
      ],
    },
    technologies: {
      validators: [
        (value) => [
          value?.length > MAX_TECHNOLOGIES &&
            VALIDATION_ERRORS.TECHNOLOGIES_LIMIT(MAX_TECHNOLOGIES),
        ],
      ],
    },
    projectTimestamps: {
      props: {
        plannedEndDate: {
          isRequired: true,
          validators: [
            (value) => [!value && VALIDATION_ERRORS.FIELD_IS_REQUIRED],
          ],
        },
      },
    },
    projectLinks: {
      all: {
        props: {
          linkType: {
            validators: [
              (value: string | null, parentValues) => {
                if (
                  !!parentValues?.link &&
                  (value === null || value === undefined)
                ) {
                  return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
                }
                return [false];
              },
            ],
          },
          link: {
            validators: [
              (value: string, parentValues) => {
                if (
                  value &&
                  parentValues.linkType !== EMAIL &&
                  parentValues.linkType !== SLIDE_DECK &&
                  parentValues.linkType !== CASE_STUDY &&
                  !validURLRegExp.test(value)
                ) {
                  return [VALIDATION_ERRORS.LINK_FORMAT_INVALID];
                }
                if (
                  value &&
                  value.length > 0 &&
                  !emailRegExp.test(value) &&
                  parentValues.linkType === EMAIL
                ) {
                  return [VALIDATION_ERRORS.WRONG_EMAIL_FORMAT];
                }
                return [false];
              },
            ],
          },
        },
        validators: [
          () => {
            if (projectData.projectLinks.length > 1) {
              const linksList = projectData.projectLinks.map((link) => {
                return `${link.linkType}:${link.link}`;
              });
              const uniqueLinks = new Set(linksList);
              return [
                linksList.length !== uniqueLinks.size && 'duplicate-links',
              ];
            }
            return [false];
          },
        ],
      },
    },
  },
});
