import React from 'react';
import { IModal, useUuiContext } from '@epam/uui';

import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  FlexSpacer,
  Panel,
  ScrollBars,
  Text,
  Button,
} from '@epam/promo';
import { BlockerModal } from 'component/BlockerModal';

interface Props {
  modalProps: IModal<boolean>;
  callback: () => void;
}
const RemoveFromShowroomConfirmation = ({ modalProps, callback }: Props) => {
  const svc = useUuiContext();
  return (
    <ModalBlocker blockerShadow="dark" {...modalProps}>
      <ModalWindow>
        <Panel background="white">
          <ModalHeader
            title="Remove project from Showroom? "
            onClose={() => modalProps.abort()}
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24">
              <Text size="36">Your project will be excluded from Showroom</Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => modalProps.abort()}
            />
            <Button
              color="blue"
              caption="Remove from Showroom"
              onClick={async () => {
                modalProps.success(true);
                svc.uuiModals
                  .show((props) => <BlockerModal modalProps={props} />, {
                    modalId: 'blocker',
                  })
                  .catch(() => null);
                callback();
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};
export default RemoveFromShowroomConfirmation;
