export function formatStringWithParams(
  originalString: string,
  ...args: Array<string | number>
) {
  let value = originalString;
  let i = args.length;

  // replace {0} {1}... with input params
  while (i--) {
    value = value.replace(new RegExp(`\\{${i}\\}`, 'gm'), args[i]?.toString());
  }

  // remove duplicate slashes (for url purposes)
  value = value.replace(/([^:]\/)\/+/g, '$1');

  return value;
}

export function parseStringUnderScore(value: string) {
  function capitalizeFirstLetter(value: string) {
    if (value.length === 2) {
      return value.toUpperCase();
    } else return value.charAt(0).toUpperCase() + value.slice(1);
  }
  const result = value
    .split('_')
    .map((item) => capitalizeFirstLetter(item.toLowerCase()));
  return result.join(' ');
}

export function upperCaseFirstLetter(str: string) {
  return `${str[0].toUpperCase()}${str.toLowerCase().substring(1)}`;
}

export function lowerCaseFirstLetter(str: string) {
  return `${str[0].toLowerCase()}${str.toLowerCase().substring(1)}`;
}

export function anOrA(str: string) {
  if (['u', 'e', 'o', 'a', 'i'].indexOf(str[0].toLowerCase()) > -1) {
    return `an`;
  }

  return `a`;
}
