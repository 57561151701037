import { useState } from 'react';

import AddOpportunities from '../add-opportunities';
import './styles.scss';
import { IOpportunity } from 'modules/list-opportunities/models/model';
import dayjs from 'dayjs';
import { Opportunity } from './opportunity';
import { ScrollBars } from '@epam/promo';

function CurrentOpportunities({
  projectId,
  opportunities,
  userHasEditPermission,
  canModifyApplicants,
  projectName,
}: {
  opportunities: IOpportunity[];
  projectId: number;
  userHasEditPermission: boolean;
  canModifyApplicants: boolean;
  projectName: string;
}) {
  const [editOpportunityVisible, setEditOpportunityVisible] = useState(false);
  const [selectedOpportunityToEdit, setSelectedOpportunityToEdit] =
    useState<IOpportunity>();

  const onEdit = (opportunity: IOpportunity) => {
    setEditOpportunityVisible(true);
    setSelectedOpportunityToEdit(opportunity);
  };

  const byCreatedDate = (o1: IOpportunity, o2: IOpportunity): number => {
    return dayjs(o2.created).diff(dayjs(o1.created));
  };

  return (
    <ScrollBars>
      <div className="current-opportunities">
        {editOpportunityVisible && (
          <AddOpportunities
            projectId={projectId}
            setAddOpportunityVisible={setEditOpportunityVisible}
            opportunity={selectedOpportunityToEdit}
          />
        )}
        {opportunities.sort(byCreatedDate).map((item: IOpportunity) => (
          <Opportunity
            key={item.id}
            opportunity={item}
            userHasEditPermission={userHasEditPermission}
            projectId={projectId}
            projectName={projectName}
            onEdit={onEdit}
            canModifyApplicants={canModifyApplicants}
          />
        ))}
      </div>
    </ScrollBars>
  );
}

export default CurrentOpportunities;
