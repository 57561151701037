import { FC } from 'react';
import { IOpportunity } from '../../../../../list-opportunities/models/model';
import { Text, SuccessNotification } from '@epam/promo';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../../../../App';
import memberService from '../../../../../../services/api/member/memberService';
import { useUuiContext } from '@epam/uui';
import { RequestToJoinModal } from '../../../../../list-opportunities/components/opportunity-card/request-to-join-modal/request-to-join-modal';
import { ActionButton } from '../../../../../../component/Buttons/ActionButton/action-button';
import { AlertModal } from '../../../../../../component/AlertModal';

import { QUERY_KEYS } from 'constants/queryKeys';
import { useUserInfo } from '../../../../../../services/auth-client/user-info-provider';
import { DefaultError } from 'component/notification/DefaultError';
import { NOTIFICATION_MESSAGES } from 'constants/uiConstants';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

type Props = {
  opportunity: IOpportunity;
  projectID: number;
  isVisible: boolean;
};

export const OpportunityRequestButton: FC<Props> = ({
  opportunity,
  isVisible,
  projectID,
}) => {
  const { userInfo } = useUserInfo();
  const svc = useUuiContext();

  const revokeMembershipRequest = useMutation<any, any, { id: number }>(
    ({ id }) => memberService.revokeMembershipRequest(id),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectID,
          ]),
        ]);
        useCloseBlocker(svc);

        svc.uuiNotifications.show((props) => (
          <SuccessNotification {...props}>
            <Text>
              {NOTIFICATION_MESSAGES.success.revokedOpportunityRequest}
            </Text>
          </SuccessNotification>
        ));
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const membershipRequest = opportunity.applicants?.find(
    (applicant) => applicant.user.id == userInfo?.id
  );

  if (membershipRequest) {
    return (
      <ActionButton
        cx="request-btn"
        size="36"
        color="blue"
        fill="white"
        loading={revokeMembershipRequest.isLoading}
        caption="Revoke"
        onClick={() => {
          svc.uuiModals
            .show((modalProps) => {
              return (
                <AlertModal
                  modalProps={modalProps}
                  alertContent={{
                    title: 'Revoke request?',
                    noBorder: true,
                    description: (
                      <span>
                        Are you sure you want to revoke your request to{' '}
                        <b>{opportunity.name}</b> opportunity? Once confirmed,
                        you will be able to apply to other opportunities from
                        this project.
                      </span>
                    ),
                    closeText: 'Revoke',
                    cancelBtn: true,
                  }}
                />
              );
            })
            .then(async () => {
              svc.uuiModals
                .show((props) => <BlockerModal modalProps={props} />, {
                  modalId: 'blocker',
                })
                .catch(() => null);
              revokeMembershipRequest.mutate({
                id: membershipRequest.membershipRequestId,
              });
            })
            .catch(() => null);
        }}
      />
    );
  }

  const tooltipContent = {
    IN_TEAM: "You're already a member",
    REQUESTED: 'You have already applied for another opportunity.',
    CAN_REQUEST: undefined,
  };
  const canRequest = opportunity.relatedToUser === 'CAN_REQUEST';
  return canRequest || isVisible ? (
    <ActionButton
      tooltipText={
        tooltipContent[
          opportunity.relatedToUser ? opportunity.relatedToUser : 'CAN_REQUEST'
        ]
      }
      cx="request-btn"
      size="36"
      color="blue"
      caption="Request to join"
      onClick={() => {
        svc.uuiModals
          .show((modalProps) => {
            return (
              <RequestToJoinModal
                modalProps={modalProps}
                projectID={projectID}
                opportunity={opportunity}
              />
            );
          })
          .catch(() => null);
      }}
      disabled={!canRequest}
    />
  ) : null;
};
