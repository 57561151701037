import { GARAGE_USER_API } from 'constants/apiConstants';
import { BaseService } from '../baseService';
import { UserInfo } from '../user/userInfo';

class GarageUserService extends BaseService {
  private static instance: GarageUserService;

  public static getInstance(): GarageUserService {
    if (!GarageUserService.instance) {
      GarageUserService.instance = new GarageUserService();
    }
    return GarageUserService.instance;
  }

  public getListUsers(filter: {
    amount: number;
    startPoint: number;
    searchPhrase?: string;
  }) {
    return GarageUserService.instance.get<{
      totalNumber: number;
      userList: UserInfo[];
    }>(GARAGE_USER_API.getListGarageUsers(filter));
  }
}

export default GarageUserService.getInstance();
