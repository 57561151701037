import { SuccessNotification, Text } from '@epam/promo';
import { useUuiContext } from '@epam/uui';
import { useMutation, useQuery } from '@tanstack/react-query';
import { NOTIFICATION_MESSAGES } from 'constants/uiConstants';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { queryClient } from 'App';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import memberService from 'services/api/member/memberService';
import opportunityService from 'services/api/opportunity/opportunityService';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

const MembershipRequest = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();
  const { membershipId } = useParams();
  const svc = useUuiContext();
  const [action] = useState<string | null>(searchParams.get('action'));
  const [opportunity] = useState<string | null>(
    searchParams.get('opportunity')
  );
  const [isDone, setDone] = useState<boolean>(false);

  const updateProject = async () =>
    queryClient.refetchQueries([
      QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
      projectId,
    ]);

  const handleSuccess = (notificationMessage: string) => {
    updateProject().then(() => setDone(true));
    svc.uuiNotifications.show(
      (props) => (
        <SuccessNotification {...props}>
          <Text>{notificationMessage}</Text>
        </SuccessNotification>
      ),
      { position: 'bot-left' }
    );
  };

  const { data } = useQuery(
    [QUERY_KEYS.MEMBERSHIP.MEMBERSHIP_GET, membershipId],
    async () => {
      if (membershipId) {
        return memberService.getMembershipRequest(parseInt(membershipId));
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const deleteOpportunity = useMutation<any, any, { id: number }>(
    ({ id }) => opportunityService.delOpportunity(id),
    {
      onSuccess: async () => {
        handleSuccess(
          NOTIFICATION_MESSAGES.success.acceptedMembershipDeletedOpportunity
        );
      },
    }
  );

  const acceptMembershipRequest = useMutation<any, any, { id: number }>(
    ({ id }) => memberService.acceptMembershipRequest(id),
    {
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const declineMembershipRequest = useMutation<any, any, { id: number }>(
    ({ id }) => memberService.declineMembershipRequest(id),
    {
      onSuccess: async () => {
        handleSuccess(NOTIFICATION_MESSAGES.success.declinedMembershipRequest);
      },
      onError: (error) => {
        setDone(true);
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  useEffect(() => {
    if (membershipId && data) {
      switch (action) {
        case 'accept':
          acceptMembershipRequest
            .mutateAsync({
              id: parseInt(membershipId),
            })
            .then(() => {
              if (opportunity === 'delete') {
                deleteOpportunity.mutate({ id: data.opportunityId });
              } else {
                handleSuccess(
                  NOTIFICATION_MESSAGES.success
                    .acceptedMembershipKeptOpportunity
                );
              }
            });

          break;
        case 'decline':
          declineMembershipRequest.mutate({
            id: parseInt(membershipId),
          });
          break;
        default:
          setDone(true);
      }
    }
  }, [data]);

  return isDone ? <Navigate to={`/dashboard/project/${projectId}`} /> : <></>;
};

export default MembershipRequest;
