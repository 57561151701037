import {
  DatePicker,
  FlexCell,
  FlexRow,
  LabeledInput,
  Panel,
  PickerInput,
  Text,
  TimePicker,
} from '@epam/promo';
import { ILens, useAsyncDataSource } from '@epam/uui';
import { EventIF } from 'modules/events/models/interfaces';
import dayjs, { Dayjs } from 'dayjs';
import { Timezone, useTimezones } from '../../../../../services/timezone';
import { useLensProps } from 'hooks/useLens';

interface EventTimeDatesProps {
  lens: ILens<EventIF>;
  isNewEvent: boolean;
  duplicateEventName: boolean;
  isUrlInvalid: boolean;
}
const EventTimeDates = (props: EventTimeDatesProps) => {
  const { timezones } = useTimezones();
  const { getFullPropObject } = useLensProps(props.lens);
  const timezonesDataSource = useAsyncDataSource<Timezone, string, unknown>(
    {
      api: async () => timezones,
    },
    []
  );
  const minSelectableDate = dayjs()
    .set('h', 0)
    .set('m', 0)
    .set('s', 0)
    .set('ms', 0)
    .valueOf();

  const filter = props.isNewEvent
    ? (day: Dayjs) => day.valueOf() >= minSelectableDate
    : false;

  return (
    <>
      <FlexRow cx="event-edit-header">
        <Text
          fontSize="18"
          lineHeight="24"
          size="18"
          font="museo-sans"
          cx="section-title"
        >
          Time and dates
        </Text>
      </FlexRow>
      <Panel cx="has-margin-bottom">
        <LabeledInput
          {...getFullPropObject('timezone')}
          label={'Time zone'}
          cx="child-label"
          isRequired
        >
          <FlexRow>
            <FlexCell width={328}>
              <PickerInput
                dataSource={timezonesDataSource}
                selectionMode={'single'}
                {...getFullPropObject('timezone')}
                getName={(timezone: any) => timezone.value}
                entityName="time zone"
                placeholder="Select"
                valueType={'id'}
                searchPosition="input"
              />
            </FlexCell>
          </FlexRow>
        </LabeledInput>
      </Panel>

      <Panel cx="has-margin-top has-margin-bottom">
        <LabeledInput label="Event Duration" isRequired>
          <FlexRow spacing="18" cx="has-margin-bottom align-bottom">
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('eventStartDate')}
                label="Start Date"
                cx="child-label"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  filter={filter}
                  {...getFullPropObject('eventStartDate')}
                />
              </LabeledInput>
            </FlexCell>
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('eventStartTime')}
                label="Time"
                cx="child-label"
              >
                <TimePicker
                  format={24}
                  placeholder="HH:mm"
                  minutesStep={15}
                  {...getFullPropObject('eventStartTime')}
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow spacing="18" cx="has-margin-bottom align-bottom">
            <FlexCell width={159}>
              <LabeledInput
                {...getFullPropObject('eventEndDate')}
                label="End Date"
                cx="child-label"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  {...getFullPropObject('eventEndDate')}
                  filter={filter}
                />
              </LabeledInput>
            </FlexCell>
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('eventEndTime')}
                label="Time"
                cx="child-label"
              >
                <TimePicker
                  format={24}
                  placeholder="HH:mm"
                  minutesStep={15}
                  {...getFullPropObject('eventEndTime')}
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
        </LabeledInput>
      </Panel>

      <Panel cx="has-margin-top has-margin-bottom">
        <LabeledInput label="Registration Duration" isRequired>
          <FlexRow spacing="18" cx="has-margin-bottom align-bottom">
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('registrationStartDate')}
                label="Start Date"
                cx="child-label"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  {...getFullPropObject('registrationStartDate')}
                  filter={filter}
                />
              </LabeledInput>
            </FlexCell>
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('registrationStartTime')}
                label="Time"
                cx="child-label"
              >
                <TimePicker
                  format={24}
                  placeholder="HH:mm"
                  minutesStep={15}
                  {...getFullPropObject('registrationStartTime')}
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow spacing="18" cx="align-bottom">
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('registrationEndDate')}
                label="End Date"
                cx="child-label"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  {...getFullPropObject('registrationEndDate')}
                  filter={filter}
                />
              </LabeledInput>
            </FlexCell>
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('registrationEndTime')}
                label="Time"
                cx="child-label"
              >
                <TimePicker
                  format={24}
                  placeholder="HH:mm"
                  minutesStep={15}
                  {...getFullPropObject('registrationEndTime')}
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
        </LabeledInput>
      </Panel>

      <Panel cx="has-margin-top">
        <LabeledInput label="Deadline for results submission" isRequired>
          <FlexRow spacing="18" cx="align-bottom">
            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('deadlineDate')}
                label="Date"
                cx="child-label"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  {...getFullPropObject('deadlineDate')}
                  filter={filter}
                />
              </LabeledInput>
            </FlexCell>

            <FlexCell width={159} cx="event-form-subsection">
              <LabeledInput
                {...getFullPropObject('deadlineTime')}
                label="Time"
                cx="child-label"
              >
                <TimePicker
                  format={24}
                  placeholder="HH:mm"
                  {...getFullPropObject('deadlineTime')}
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
        </LabeledInput>
      </Panel>
    </>
  );
};

export default EventTimeDates;
