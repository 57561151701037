import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Button,
  FlexCell,
  FlexRow,
  FlexSpacer,
  LabeledInput,
  PickerInput,
  TextArea,
} from '@epam/promo';
import useNotificationsModal from 'component/NotifiactionsModal';
import {
  ArrayDataSource,
  LazyDataSourceApiRequest,
  useLazyDataSource,
  useUuiContext,
} from '@epam/uui';
import usePreventReloadOnFormChanged from 'component/hooks/use-prevent-reload';
import projectManagementService from 'services/api/project-management/projectManagementService';
import { queryClient } from 'App';
import { useMutation } from '@tanstack/react-query';
import opportunityService from 'services/api/opportunity/opportunityService';
import { IOpportunity } from 'modules/list-opportunities/models/model';
import { QUERY_KEYS } from '../../../../../constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { useDictionary } from 'services/dictionary/dictionary-provider';
import { ISkilloTech } from 'modules/project-list/components/project-card/modal';
import getSkilloResponse from '../../../../project-management/update/components/technologies/utils';
import { MAX_OPPORTUNITIES_TECHNOLOGIES } from 'constants/uiConstants';

const AddOpportunities = (props: {
  projectId: number;
  setAddOpportunityVisible: (value: boolean) => void;
  opportunity?: IOpportunity;
}) => {
  const { projectId, setAddOpportunityVisible, opportunity } = props;
  const initPosition = useRef<any>(null);
  const initDescription = useRef('');
  const [position, setPosition] = useState<any>(null);
  const [description, setDescription] = useState('');
  const [technologies, setTechnologies] = useState<ISkilloTech[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const svc = useUuiContext();
  const { projectPositions } = useDictionary();
  const { successNotification } = useNotificationsModal();
  // Maybe we can make this logic into a hook? it's used in multiple places
  const loadSkilloSkills = useCallback(
    (request: LazyDataSourceApiRequest<ISkilloTech, string, unknown>) => {
      return getSkilloResponse(request);
    },
    []
  );
  const skilloDS = useLazyDataSource(
    {
      api: loadSkilloSkills,
      selectAll: false,
    },
    []
  );
  // Hook Block

  useEffect(() => {
    if (opportunity) {
      const getPosition = async () => {
        const remotePosition = projectPositions?.items.find(
          (position) => position.label === opportunity.name
        )?.value;
        initPosition.current = remotePosition;
        initDescription.current = opportunity.description;
        setPosition(remotePosition);
        const techWithIds = opportunity.technologies.map((tech) => ({
          skilloId: tech.skilloId,
          id: tech.skilloId,
          name: tech.name,
        }));
        setTechnologies(techWithIds);
        setDescription(opportunity.description);
      };
      getPosition();
    }
  }, []);

  useEffect(() => {
    if (!opportunity) {
      return setIsChanged(!!description || !!position);
    }
    if (initDescription.current || initPosition.current) {
      setIsChanged(
        description !== initDescription.current ||
          position !== initPosition.current
      );
    }
  }, [description, position]);

  usePreventReloadOnFormChanged({ isChanged });

  const positionDataSource = new ArrayDataSource({
    items: projectPositions
      ? projectPositions?.items.filter((item) => item.label !== 'Project Owner')
      : [],
    getId: (item) => item.value,
  });

  const addOpportunityMutation = useMutation<
    any,
    any,
    { projectId: number; opportunityData: any }
  >(
    ({ projectId, opportunityData }) =>
      projectManagementService.addOpportunity(projectId, opportunityData),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
        ]);
        setAddOpportunityVisible(false);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const editOpportunityMutation = useMutation<
    any,
    any,
    { opportunityId: number; opportunityData: any }
  >(
    ({ opportunityId, opportunityData }) =>
      opportunityService.editOpportunity(opportunityId, opportunityData),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
        ]);
        setAddOpportunityVisible(false);
        useCloseBlocker(svc);
        successNotification(
          `Your opportunity has been successfully updated and brought up to top in the opportunity list.`
        );
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const handleTechSubmit = (tech: ISkilloTech[]) => {
    return tech.map((item: ISkilloTech) => {
      return {
        skilloId: item.skilloId,
        name: item.name,
      };
    });
  };

  const submitHandler = async () => {
    svc.uuiModals
      .show((props) => <BlockerModal modalProps={props} />, {
        modalId: 'blocker',
      })
      .catch(() => null);
    if (opportunity) {
      return editOpportunityMutation.mutate({
        opportunityId: opportunity.id,
        opportunityData: {
          name: position,
          description: description,
          technologies: handleTechSubmit(technologies),
        },
      });
    } else {
      return addOpportunityMutation.mutate({
        projectId: projectId,
        opportunityData: {
          name: position,
          description: description,
          technologies: technologies,
        },
      });
    }
  };

  const onCancel = () => {
    setAddOpportunityVisible(false);
  };

  return (
    <FlexCell>
      <FlexRow>
        <FlexCell width="100%">
          <FlexRow vPadding="12">
            <FlexCell grow={1}>
              <LabeledInput
                label="Position"
                isRequired={!position}
                cx={'position'}
              >
                <PickerInput
                  dataSource={positionDataSource}
                  value={position}
                  onValueChange={setPosition}
                  selectionMode="single"
                  valueType="id"
                  placeholder="Choose Position"
                  getName={(position) => position.label}
                  entityName="Position"
                  sorting={{ field: 'type', direction: 'asc' }}
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow vPadding="12">
            <FlexCell grow={2}>
              <LabeledInput label="Skills" cx={'technologies'}>
                <PickerInput
                  dataSource={skilloDS}
                  value={technologies}
                  onValueChange={setTechnologies}
                  emptyValue={[]}
                  selectionMode={'multi'}
                  valueType={'entity'}
                  placeholder={'Choose skills or technologies'}
                  sorting={{ field: 'name', direction: 'asc' }}
                  maxItems={3}
                  getRowOptions={(item: ISkilloTech) => ({
                    checkbox: {
                      isVisible: true,
                      isDisabled:
                        technologies?.length >=
                          MAX_OPPORTUNITIES_TECHNOLOGIES &&
                        !technologies.find((tech) => {
                          if (tech && tech.id === item.id) return tech;
                        }),
                    },
                  })}
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow vPadding="12">
            <FlexCell grow={1}>
              <LabeledInput
                label="Position Description"
                isRequired={!description.trim()}
                cx={'description'}
              >
                <TextArea
                  maxLength={255}
                  rows={3}
                  value={description}
                  onValueChange={setDescription}
                  placeholder="Add description of the position, expected involvement, preferred location if any"
                />
              </LabeledInput>
            </FlexCell>
          </FlexRow>
          <FlexRow cx="project-detail-buttons-line">
            <FlexSpacer />
            <Button
              cx="project-detail-button"
              size="30"
              caption="Cancel"
              fill="white"
              color="blue"
              onClick={onCancel}
            />
            <Button
              cx="project-detail-button"
              size="30"
              color="blue"
              caption="Submit"
              onClick={submitHandler}
              isDisabled={
                !position ||
                description.trim().length === 0 ||
                editOpportunityMutation.isLoading ||
                addOpportunityMutation.isLoading
              }
            />
          </FlexRow>
        </FlexCell>
      </FlexRow>
    </FlexCell>
  );
};

export default AddOpportunities;
