import React from 'react';
import {
  FlexCell,
  LabeledInput,
  TextArea,
  TextInput,
  Panel,
} from '@epam/promo';
import { ILens } from '@epam/uui';
import './styles.scss';
import { Counter } from '../counter';
import {
  MAX_PROJECT_NAME_LENGTH,
  MAX_PROJECT_DESCRIPTION_LENGTH,
} from '../../../../../constants/uiConstants';
import { ProjectDetails } from 'modules/project-management/models/interfaces';

function ProjectMeta({
  lens,
}: {
  lens: ILens<ProjectDetails['projectMetadata']>;
}) {
  const projectNameLength = lens.prop('name').toProps().value?.length ?? 0;
  const projectDesriptionLength =
    lens.prop('description').toProps().value?.length ?? 0;
  return (
    <>
      <Panel cx={'metadata-container'}>
        <FlexCell rawProps={{ 'data-auto-scroll': '' }}>
          <LabeledInput
            htmlFor={'project-name-input'}
            label={'Project Name'}
            {...lens.prop('name').toProps()}
          >
            <TextInput
              placeholder={'Enter project name'}
              {...lens.prop('name').toProps()}
              cx={'metadata-container__project-name-input'}
              id={'project-name-input'}
            />
          </LabeledInput>
          <Counter
            currentValue={projectNameLength}
            maxValue={MAX_PROJECT_NAME_LENGTH}
          />
        </FlexCell>
        <FlexCell rawProps={{ 'data-auto-scroll': '' }}>
          <LabeledInput
            label={'Description'}
            {...lens.prop('description').toProps()}
            htmlFor={'project-description-input'}
          >
            <TextArea
              rows={6}
              placeholder="Describe the objective of your project and how you think it can bring value"
              {...lens.prop('description').toProps()}
              cx={'metadata-container__project-description-input'}
              id={'project-description-input'}
            />
          </LabeledInput>
          <Counter
            currentValue={projectDesriptionLength}
            maxValue={MAX_PROJECT_DESCRIPTION_LENGTH}
          />
        </FlexCell>
      </Panel>
    </>
  );
}

export { ProjectMeta };
