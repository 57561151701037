import {
  Button,
  FlexRow,
  FlexSpacer,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  ScrollBars,
  Text,
} from '@epam/promo';
import { IModal, useUuiContext } from '@epam/uui';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'App';
import { BlockerModal } from 'component/BlockerModal';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { IProjectMember } from 'modules/project-list/components/project-card/modal';
import React from 'react';
import memberService from 'services/api/member/memberService';
import { QUERY_KEYS } from '../../../../../constants/queryKeys';

interface MemberDeletionModalProps {
  modalBlockerProps: IModal<string>;
  projectId: string;
  member: IProjectMember;
}

const ProjectMemberDeletionModal: React.FC<MemberDeletionModalProps> = ({
  modalBlockerProps,
  projectId,
  member,
}) => {
  const svc = useUuiContext();

  const deleteProjectMemberMutation = useMutation<
    any,
    any,
    { projectId: string; externalUserId: string }
  >(
    ({ projectId, externalUserId }) =>
      memberService.deleteProjectMember(projectId, externalUserId),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PERMISSIONS]),
        ]);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return (
    <ModalBlocker
      blockerShadow="dark"
      {...modalBlockerProps}
      abort={() => modalBlockerProps.abort()}
    >
      <ModalWindow>
        <Panel background="white">
          <ModalHeader
            title="Remove team member?"
            onClose={() => modalBlockerProps.abort()}
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24" vPadding="12">
              <Text size="36">
                If you remove{' '}
                <b>
                  {member.firstName} {member.lastName}
                </b>{' '}
                from the project, this team member will not be able to
                contribute anymore.
              </Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => modalBlockerProps.abort()}
            />
            <Button
              color="blue"
              caption="Remove team member"
              onClick={async () => {
                modalBlockerProps.success('Success action');
                svc.uuiModals
                  .show((props) => <BlockerModal modalProps={props} />, {
                    modalId: 'blocker',
                  })
                  .catch(() => null);

                deleteProjectMemberMutation.mutate({
                  projectId,
                  externalUserId: member.externalId,
                });
              }}
              isDisabled={deleteProjectMemberMutation.isLoading}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default ProjectMemberDeletionModal;
