import { IModal, useUuiContext } from '@epam/uui';
import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  FlexSpacer,
  Panel,
  Text,
  Button,
} from '@epam/promo';
import { useMutation } from '@tanstack/react-query';
import opportunityService from 'services/api/opportunity/opportunityService';
import { queryClient } from 'App';
import { QUERY_KEYS } from 'constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

interface props {
  modalProps: IModal<boolean>;
  info?: IOpportunity;
  projectId: number;
}
interface IOpportunity {
  id: string | number;
  name: string;
  description: string;
}

export const OnDeleteModel = ({ modalProps, info, projectId }: props) => {
  const svc = useUuiContext();

  const deleteOpportunityMutation = useMutation<
    any,
    any,
    { opportunityId: any }
  >(({ opportunityId }) => opportunityService.delOpportunity(opportunityId), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries([QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST]),
        queryClient.refetchQueries([
          QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
          projectId,
        ]),
      ]);
      useCloseBlocker(svc);
    },
    onError: (error) => {
      useCloseBlocker(svc);
      svc.uuiNotifications
        .show((props) => (
          <DefaultError notificationProps={props} error={error} />
        ))
        .catch(() => null);
    },
  });
  const { id, name } = info ?? {};
  return (
    <ModalBlocker blockerShadow="dark" {...modalProps}>
      <ModalWindow>
        <Panel background="white">
          <ModalHeader
            title="Delete opportunity?"
            onClose={() => modalProps.abort()}
          />
          <FlexRow padding="24">
            <Text size="36">
              You are going to delete position <b>{name}</b> without possibility
              to restore it. Do you want to continue ?
            </Text>
          </FlexRow>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => modalProps.abort()}
            />
            <Button
              color="blue"
              caption="Delete opportunity"
              onClick={async () => {
                modalProps.success(true);
                svc.uuiModals
                  .show((props) => <BlockerModal modalProps={props} />, {
                    modalId: 'blocker',
                  })
                  .catch(() => null);
                deleteOpportunityMutation.mutate({
                  opportunityId: id,
                });
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};
