import { FC } from 'react';
import { IModal } from '@epam/uui';
import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  FlexSpacer,
  Panel,
  ScrollBars,
  Text,
  Button,
} from '@epam/promo';

type AlertModalProps = {
  title: string;
  description: string | JSX.Element;
  closeText?: string;
  cancelBtn?: boolean;
  noBorder?: boolean;
};

export const AlertModal: FC<{
  modalProps: IModal<string>;
  alertContent: AlertModalProps;
}> = ({ modalProps, alertContent }) => {
  const {
    title,
    description,
    closeText = 'CLOSE',
    cancelBtn = false,
    noBorder,
  } = alertContent;

  return (
    <ModalBlocker
      blockerShadow="dark"
      {...modalProps}
      abort={() => modalProps.abort()}
    >
      <ModalWindow>
        <Panel background="white">
          <ModalHeader
            title={title}
            onClose={() => modalProps.abort()}
            borderBottom={!noBorder}
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24" borderBottom={!noBorder}>
              <Text size="36" lineHeight="24">
                {' '}
                {description}{' '}
              </Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            {cancelBtn && (
              <Button
                color="gray50"
                fill="white"
                caption="Cancel"
                onClick={() => modalProps.abort()}
              />
            )}
            <Button
              color="blue"
              caption={closeText}
              onClick={() => modalProps.success('')}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};
