import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import { ReactComponent as teamsIcon } from '@epam/assets/icons/common/microsoft-teams-18.svg';
import { ReactComponent as telescopeIcon } from '@epam/assets/icons/common/communication-telescope-18.svg';
import { IconButton, Text } from '@epam/promo';
import { useUuiContext } from '@epam/uui';
import { useQuery } from '@tanstack/react-query';
import Avatar from 'component/Avatar';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import React from 'react';
import userService from 'services/api/user/userService';
import { IProjectOwner } from '../modal';
import './styles.scss';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

const ProjectOwner = ({
  fullName,
  email,
  userId,
  externalId,
}: IProjectOwner): React.ReactElement => {
  const svc = useUuiContext();

  const { userInfo } = useUserInfo();
  const { data } = useQuery(
    [QUERY_KEYS.USERS.PROFILE_PHOTO, userId],
    async () => {
      return userService.getUserProfilePicture(userId);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return (
    <div className="project-owner">
      <Avatar
        img={data}
        alt="Project Owner avatar"
        cx="project-owner__img-profile"
        size={'36'}
        initials={fullName
          .split(' ')
          .map((str) => str.charAt(0))
          .join('')}
      />
      <div className="project-owner__infor">
        <Text cx="owner-name" font="sans-semibold">
          {fullName}
        </Text>
        <Text cx="owner-title">Project Owner</Text>
        {userId != userInfo?.id && (
          <div className="button-icons">
            <IconButton
              cx="mail-icon"
              color="gray50"
              icon={mailIcon}
              href={`mailto:${email}`}
            />
            <IconButton
              cx="teams-icon"
              color="gray50"
              icon={teamsIcon}
              onClick={() => {
                window.open(
                  `https://teams.microsoft.com/l/chat/0/0?users=${email}`,
                  '_blank'
                );
              }}
            />
            <IconButton
              color="gray50"
              cx="telescope-icon"
              onClick={() => {
                window.open(
                  `https://telescope.epam.com/who/key/upsaId/${externalId}?tab=profile`,
                  '_blank'
                );
              }}
              icon={telescopeIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectOwner;
