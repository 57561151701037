import React, { FC } from 'react';
import './not-found-page.scss';

type Props = {
  text?: string;
};

export const NotFoundPage: FC<Props> = ({ text }) => {
  return (
    <div className="not-found-page">
      <div className="page-container">
        <div className="text">404</div>

        <div className="text">
          {text || 'Oooops! We couldn’t find this page'}
        </div>

        <div className="text-small">Sorry for the inconvenience.</div>
      </div>
    </div>
  );
};
