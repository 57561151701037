import { INotification, useUuiContext } from '@epam/uui';
import { WarningNotification, Text, SuccessNotification } from '@epam/promo';
import { DefaultError } from 'component/notification/DefaultError';

const useNotifications = () => {
  const svc = useUuiContext();

  const showWarning = async (message?: string) => {
    svc.uuiNotifications
      .show(
        (props: INotification) => (
          <WarningNotification {...props}>
            <Text>
              {message ? message : `Something went wrong. Please try again`}
            </Text>
          </WarningNotification>
        ),
        { position: 'bot-left', duration: 5 }
      )
      .catch(() => null);
  };

  const showError = async (error: any) => {
    svc.uuiNotifications
      .show((props) => <DefaultError notificationProps={props} error={error} />)
      .catch(() => null);
  };

  const showSuccess = async (message: string) => {
    svc.uuiNotifications
      .show(
        (props) => (
          <SuccessNotification {...props}>
            <Text>{message}</Text>
          </SuccessNotification>
        ),
        { duration: 5 }
      )
      .catch(() => null);
  };

  return { showWarning, showError, showSuccess };
};

export default useNotifications;
