import { Text } from '@epam/promo';
import './styles.scss';

interface Props {
  description: string;
}

export const ProjectDescription = (props: Props) => (
  <div className="project-detail-left__description">
    <Text
      fontSize={'18'}
      lineHeight={'24'}
      font={'museo-sans'}
      cx="project-detail-left__description-header"
    >
      Description
    </Text>
    {props.description}
  </div>
);
