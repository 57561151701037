import React from 'react';
import {
  FlexRow,
  Text,
  Tooltip,
  PickerInput,
  IconButton,
  Panel,
  FlexSpacer,
} from '@epam/promo';
import { ReactComponent as tableSwapIcon } from '@epam/assets/icons/common/table-swap-18.svg';
import { ReactComponent as teamsIcon } from '@epam/assets/icons/common/microsoft-teams-18.svg';
import { ReactComponent as telescopeIcon } from '@epam/assets/icons/common/communication-telescope-18.svg';
import './styles.scss';
import { cx, IDataSource, useUuiContext } from '@epam/uui';
import { IProjectMember } from 'modules/project-list/components/project-card/modal';
import Avatar from 'component/Avatar';
import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import { useQuery } from '@tanstack/react-query';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import userService from 'services/api/user/userService';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

export interface Person {
  firstName: string;
  lastName: string;
  position: string;
  id: string;
}

export interface OwnerProps {
  member: IProjectMember;
  value: IProjectMember;
  onValueChange: (newValue: IProjectMember) => void;
  dataSource: IDataSource<IProjectMember, any, unknown>;
  getName?: (item: IProjectMember) => string;
  projectId: string;
  hasEditPermission: boolean;
}

// export const OWNER = 'PROJECT_OWNER';
const ProjectOwner: React.FC<OwnerProps> = ({
  member,
  dataSource,
  value,
  onValueChange,
  getName,
  hasEditPermission,
}) => {
  const SESSION_KEY = sessionStorage.key(0) || '';
  const SESSION_STORAGE_STRING = sessionStorage
    .getItem(SESSION_KEY)
    ?.toLowerCase();
  const svc = useUuiContext();
  const { userInfo } = useUserInfo();

  const { data } = useQuery(
    [QUERY_KEYS.USERS.PROFILE_PHOTO, member.id],
    async () => {
      return userService.getUserProfilePicture(member.id);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return (
    <FlexRow
      cx={cx('project-detail-project-owner', hasEditPermission && 'has-action')}
      alignItems="center"
      spacing="12"
      size={'24'}
    >
      <Avatar
        alt="member"
        img={data}
        size={'36'}
        initials={`${member.firstName.charAt(0)}${member.lastName.charAt(0)}`}
      />

      <Panel>
        <FlexRow cx="project-detail-project-owner__name">
          <Text font="sans-semibold">{`${member.firstName} ${member.lastName}`}</Text>
          {!SESSION_STORAGE_STRING?.includes(member.email.toLowerCase()) &&
          member.id != userInfo?.id ? (
            <div className="project-detail-project-owner__icons">
              <IconButton
                cx="mail-icon"
                color="gray50"
                icon={mailIcon}
                href={`mailto:${member.email}`}
              />
              <IconButton
                cx="teams-icon"
                color="gray50"
                icon={teamsIcon}
                onClick={() => {
                  window.open(
                    `https://teams.microsoft.com/l/chat/0/0?users=${member.email}`,
                    '_blank'
                  );
                }}
              />
              <IconButton
                color="gray50"
                cx="telescope-icon"
                onClick={() => {
                  window.open(
                    `https://telescope.epam.com/who/key/upsaId/${member.externalId}?tab=profile`,
                    '_blank'
                  );
                }}
                icon={telescopeIcon}
              />
            </div>
          ) : (
            ''
          )}
        </FlexRow>

        <FlexRow cx="project-detail-project-owner__role">
          <Text size="24">{member.position}</Text>
        </FlexRow>
      </Panel>

      <FlexSpacer />

      {hasEditPermission && (
        <div className="project-detail-project-owner__swap">
          <PickerInput
            renderToggler={(props) => (
              <Tooltip content="Transfer ownership" placement="top">
                <div>
                  <IconButton
                    ref={props.ref}
                    {...props}
                    icon={tableSwapIcon}
                    cx="project-detail-project-owner__ownership"
                    color="blue"
                  />
                </div>
              </Tooltip>
            )}
            dataSource={dataSource}
            value={value}
            onValueChange={onValueChange}
            entityName="person"
            selectionMode="single"
            valueType="entity"
            getName={getName}
            dropdownPlacement={'bottom-end'}
          />
        </div>
      )}
    </FlexRow>
  );
};

export default ProjectOwner;
