import { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button, FlexRow, LinkButton, Tooltip } from '@epam/promo';
import { useUserInfo } from '../../../services/auth-client/user-info-provider';
import { GLOBAL_LOCATION } from '../../../constants/uiConstants';
import { EventStatus } from '../models/interfaces';
import { IEventProject } from '../../project-list/components/project-card/modal';

interface Props {
  editorSwitch: boolean;
  status: EventStatus;
  location: string;
  eventProject: IEventProject;
  canViewProjects: boolean;
  registrationStartDate: Dayjs;
  registrationEndDate: Dayjs;
}

const EventCardButtonPanel: FC<Props> = ({
  editorSwitch,
  status,
  location,
  eventProject,
  canViewProjects,
  registrationStartDate,
  registrationEndDate,
}) => {
  const { hasProjectCreateQuota, userInfo } = useUserInfo();
  const navigate = useNavigate();

  const isGlobalEvent: boolean = userInfo
    ? location === GLOBAL_LOCATION
    : false;

  const isEventLocationSameAsUserLocation: boolean = userInfo
    ? location.includes(userInfo.country)
    : false;

  const currentDate = dayjs();
  const isEventRegistrationOpen =
    currentDate > registrationStartDate && currentDate < registrationEndDate;

  const isDisabled =
    status === 'ENDED' ||
    !hasProjectCreateQuota ||
    (!isGlobalEvent && !isEventLocationSameAsUserLocation) ||
    ((!isGlobalEvent || !isEventLocationSameAsUserLocation) &&
      !isEventRegistrationOpen);

  const button = !editorSwitch && (
    <Tooltip
      placement="bottom"
      content={
        (!hasProjectCreateQuota &&
          'To participate in the event, you need to create a project. You already own 3 projects in initial or active status') ||
        (!isGlobalEvent &&
          !isEventLocationSameAsUserLocation &&
          'Your location cannot participate in this event')
      }
    >
      <Button
        color="blue"
        caption="Create Project"
        isDisabled={isDisabled}
        onClick={() =>
          navigate({
            pathname: `/dashboard/createProject`,
            search: `?eventId=${eventProject.id}`,
          })
        }
      />
    </Tooltip>
  );

  const viewProjectButtonClass = `view-project-btn ${
    editorSwitch ? 'editor-mode' : ''
  } ${!canViewProjects && editorSwitch ? 'isDisabled' : ''}`;

  return (
    <FlexRow cx="event-buttons">
      {button}
      <LinkButton
        cx={viewProjectButtonClass}
        color="blue"
        caption="View Projects"
        size="36"
        isDisabled={!canViewProjects}
        link={{ pathname: `/dashboard?eventId=${String(eventProject.id)}` }}
        target="_blank"
      />
    </FlexRow>
  );
};

export default EventCardButtonPanel;
