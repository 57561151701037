import React, { FC, useEffect, useState } from 'react';
import { IModal, useForm, useUuiContext } from '@epam/uui';
import {
  Button,
  FlexSpacer,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  ScrollBars,
} from '@epam/promo';
import { ActionButton } from '../../../component/Buttons/ActionButton/action-button';
import { eventSchema, VALIDATION_ERRORS } from './event-schema';
import './events-field.scss';
import EventDetails from './components/event-details';
import EventTimeDates from './components/event-time-dates';
import EventWorkflowSettings from './components/event-workflow-settings';
import { queryClient } from '../../../App';
import { QUERY_KEYS } from '../../../constants/queryKeys';
import { buildDate, formatName, prepareFormData } from './utils';
import eventManagementService from '../../../services/api/event-managment/eventManagementService';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import { useLeaveConfirm } from 'hooks/useLeaveConfirm';
import { useTimezones } from '../../../services/timezone';
import { useBlocker } from 'hooks/useBlocker';
import { useLensProps } from 'hooks/useLens';
import { autoScroll as autoScrollEffect } from 'utils/autoscroll';
import { GLOBAL_LOCATION } from 'constants/uiConstants';
import { showErrorMessages } from 'component/errorMessagesRenderer/errorMessagesRenderer';
import { validationErrors } from 'models/errors';
import { formatDateForBE } from 'utils/dateUtils';

type Props = {
  event?: any;
  modalProps: IModal<string>;
};
export const ManageEventsModal: FC<Props> = ({ event, modalProps }) => {
  const { timezones, userTimezone } = useTimezones();

  const [isNewEvent, setIsNewEvent] = useState<boolean>(false);
  const [autoScroll, triggerAutoScroll] = useState<boolean>(false);
  const [duplicateEventName, setDuplicateEventName] = useState(false);
  const [isUrlInvalid, setUrlInvalid] = useState(false);
  const svc = useUuiContext();

  if (!event && !isNewEvent) {
    setIsNewEvent(true);
  }
  const handleNameValidationMessage = (message: string) => {
    if (
      message.includes(
        'This event name already exists. Please try again with a different name.'
      )
    ) {
      return VALIDATION_ERRORS.EVENT_NAME_EXISTS;
    }
    return null;
  };

  const handleUrlValidationMessage = (message: string) => {
    if (
      message.includes(
        'This Home Site URL already exists. Please try again with a different URL.'
      )
    ) {
      return VALIDATION_ERRORS.DUPLICATE_HOME_SITE_LINK;
    }
    return null;
  };
  const { userInfo } = useUserInfo();
  const { lens, save, isChanged } = useForm({
    validationOn: 'change',
    value: {
      id: event?.id ?? 0,
      name: event?.name,
      description: event?.description,
      homeSiteUrl: event?.homeSiteUrl,
      timezone: event ? event.timezone : userTimezone.id,
      location: event?.location,
      global: event?.global ?? false,
      eventStartDate: event?.eventStartDate,
      eventStartTime: event ? event.eventStartTime : null,
      eventEndDate: event?.eventEndDate,
      eventEndTime: event ? event.eventEndTime : null,
      registrationStartDate: event?.registrationStartDate,
      registrationStartTime: event ? event.registrationStartTime : null,
      registrationEndDate: event?.registrationEndDate,
      registrationEndTime: event ? event.registrationEndTime : null,
      deadlineDate: event?.deadlineDate,
      deadlineTime: event ? event.deadlineTime : null,
      owner: userInfo,
      isNewEvent: isNewEvent,
      projectStatusFrozen: event?.projectStatusFrozen ?? false,
    },
    onSave: async (eventFormData) => {
      useBlocker(svc);
      const formTimeZone = timezones.find(
        (timezone) => timezone.id === eventFormData.timezone
      );
      let formDataLocation = eventFormData.location?.trim();
      const formDataGlobal =
        !eventFormData.global && !formDataLocation
          ? true
          : eventFormData.global;
      formDataLocation =
        eventFormData.global || !formDataLocation
          ? GLOBAL_LOCATION
          : formDataLocation;
      const eventData: any = {
        id: eventFormData.id,
        name: formatName(eventFormData.name),
        description: eventFormData.description.trim(),
        homeSite: eventFormData.homeSiteUrl,
        global: formDataGlobal,
        location: formDataLocation,
        timeZone: formTimeZone?.name,
        projectStatusFrozen: eventFormData.projectStatusFrozen,
        plannedStartDate: formatDateForBE(
          buildDate(
            eventFormData.eventStartDate,
            eventFormData.eventStartTime,
            formTimeZone?.name
          )
        ),
        plannedEndDate: formatDateForBE(
          buildDate(
            eventFormData.eventEndDate,
            eventFormData.eventEndTime,
            formTimeZone?.name
          )
        ),
        registrationStartDate: formatDateForBE(
          buildDate(
            eventFormData.registrationStartDate,
            eventFormData.registrationStartTime,
            formTimeZone?.name
          )
        ),
        registrationEndDate: formatDateForBE(
          buildDate(
            eventFormData.registrationEndDate,
            eventFormData.registrationEndTime,
            formTimeZone?.name
          )
        ),
        resultDeadline: formatDateForBE(
          buildDate(
            eventFormData.deadlineDate,
            eventFormData.deadlineTime,
            formTimeZone?.name
          )
        ),
      };
      const formData = prepareFormData(eventData);
      try {
        if (isNewEvent) {
          await eventManagementService.createEvent(formData);
        } else {
          const fetchedData = await eventManagementService.getEvent(event.id);
          if (JSON.stringify(event) !== JSON.stringify(fetchedData)) {
            await eventManagementService.editEvent(
              lens.prop('id').toProps().value,
              formData
            );
          } else {
            return Promise.reject(`The event hasn't changed`);
          }
        }
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.EVENTS.EVENTS_LIST]),
          queryClient.refetchQueries([QUERY_KEYS.EVENTS.EVENT_DETAILS]),
        ]);
        return Promise.resolve({ form: eventFormData });
      } catch (errors: any) {
        const nameValidationMessage = handleNameValidationMessage(errors);
        const urlValidationMessage = handleUrlValidationMessage(errors);
        if (nameValidationMessage) {
          setDuplicateEventName(true);
          return Promise.resolve({
            form: eventFormData,
            validation: {
              isInvalid: true,
              validationProps: {
                isInvalid: true,
                validationProps: {
                  name: {
                    isInvalid: true,
                    nameValidationMessage,
                  },
                },
              },
            },
          });
        }
        if (urlValidationMessage) {
          setUrlInvalid(true);
          return Promise.resolve({
            form: eventFormData,
            validation: {
              isInvalid: true,
              validationProps: {
                isInvalid: true,
                validationProps: {
                  homeSiteUrl: {
                    isInvalid: true,
                    urlValidationMessage,
                  },
                },
              },
            },
          });
        }
        return Promise.reject(errors);
      } finally {
        useCloseBlocker(svc);
      }
    },
    onSuccess: (data) => {
      modalProps.success(data.name);
      useCloseBlocker(svc);
    },
    onError: (errors: validationErrors) => {
      useCloseBlocker(svc);
      showErrorMessages(svc, errors);
    },
    getMetadata: eventSchema,
  });
  const { getPropValue } = useLensProps(lens);
  const showLeaveConfirmation = useLeaveConfirm(modalProps, isChanged);

  useEffect(() => {
    setDuplicateEventName(false);
  }, [getPropValue('name')]);
  useEffect(() => {
    setUrlInvalid(false);
  }, [getPropValue('homeSiteUrl')]);

  useEffect(autoScrollEffect, [autoScroll]);
  return (
    <ModalBlocker
      blockerShadow="dark"
      {...modalProps}
      abort={() => {
        showLeaveConfirmation();
      }}
    >
      <ModalWindow cx="event-edit-form">
        <Panel background="white">
          <ModalHeader
            title={`${isNewEvent ? 'Create' : 'Edit'} Event`}
            onClose={showLeaveConfirmation}
            borderBottom
          />
          <ScrollBars rawProps={{ 'data-scroll-container': '' } as any}>
            <Panel cx="event-form-wrapper">
              <Panel cx="event-form-section">
                <EventDetails
                  lens={lens}
                  duplicateEventName={duplicateEventName}
                  isNewEvent={isNewEvent}
                  isUrlInvalid={isUrlInvalid}
                />
              </Panel>
              <Panel cx="event-form-section">
                <EventTimeDates
                  lens={lens}
                  duplicateEventName={duplicateEventName}
                  isNewEvent={isNewEvent}
                  isUrlInvalid={isUrlInvalid}
                />
              </Panel>
              <Panel cx="event-form-section">
                <EventWorkflowSettings lens={lens} />
              </Panel>
            </Panel>
          </ScrollBars>
          <ModalFooter borderTop>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={showLeaveConfirmation}
            />
            <ActionButton
              color="green"
              caption={`${isNewEvent ? 'Create Event' : 'Save'}`}
              onClick={() => {
                triggerAutoScroll(!autoScroll);
                save();
              }}
              disabled={!isChanged}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};
