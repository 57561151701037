import { ProjectLinkCategory } from 'models/enums';
import {
  ISkilloTech,
  IEventProject,
} from 'modules/project-list/components/project-card/modal';

export interface ProjectLinkType {
  value: string;
  category: ProjectLinkCategory;
  label: string;
  position: number;
}

export interface IProjectLink {
  id: number | string | null;
  link: string;
  linkType: string | null;
  file?: object | undefined;
}

export interface IResponseGetProjectLinkTypes {
  name: string;
  items: ProjectLinkType[];
}

export const enum ProjectStatus {
  ALL = 'ALL',
  INITIAL = 'INITIAL',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
}

export const getProjectLinksModel = (
  links: ProjectLinkType[]
): IProjectLink[] => {
  return links.map((link) => ({
    id: link.value,
    link: link.label,
    linkType: link.value,
  }));
};

export interface IProjectRequiredFields {
  CODE: boolean;
  DOCS_DECKS: boolean;
}

export interface IProjectOptionalFields {
  VIDEO_LINK: boolean;
  HOME_SITE: boolean;
  TEST_ENVIRONMENT: boolean;
}

export interface IProjectFieldsStatus {
  required: IProjectRequiredFields;
  optional: IProjectOptionalFields;
}

export interface ProjectDetails {
  id: number;
  projectMetadata: ProjectMetadata;
  cover: { imgUrl: string; uploadedImage?: string | null };
  keywords: string[];
  technologies: ISkilloTech[];
  event: IEventProject;
  projectTimestamps: { plannedEndDate: string; plannedStartDate: string };
  projectLinks: IProjectLink[];
  status: string;
}

export interface ProjectMetadata {
  name: string;
  description: string;
}
