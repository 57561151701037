import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Button } from '@epam/promo';
import { useUserInfo } from '../../../services/auth-client/user-info-provider';
import { ReactComponent as addProject } from '../../../assets/svg/add.svg';
import { ReactComponent as addProjectDisabled } from '../../../assets/svg/add-disabled.svg';
import { MAX_INITIAL_ACTIVE_PROJECTS } from 'constants/uiConstants';

interface ICreateProjectButtonProps {
  buttonDisabled?: boolean;
  hasIcon?: boolean;
  eventId?: number;
}

export function CreateProjectButton({
  hasIcon,
  buttonDisabled,
  eventId,
}: ICreateProjectButtonProps) {
  const navigate = useNavigate();
  const { hasProjectCreateQuota } = useUserInfo();
  const icon = !hasIcon
    ? undefined
    : hasProjectCreateQuota
    ? addProject
    : addProjectDisabled;

  const handleCreateProject = () => {
    navigate({
      pathname: `/dashboard/createProject`,
      search: eventId ? `?eventId=${eventId}` : '',
    });
  };

  return (
    <Tooltip
      placement={'bottom'}
      content={
        !hasProjectCreateQuota &&
        `You already own ${MAX_INITIAL_ACTIVE_PROJECTS} projects in initial or active status`
      }
    >
      <Button
        color={'green'}
        icon={icon}
        iconPosition={'right'}
        caption={'Create Project'}
        isDisabled={!hasProjectCreateQuota || buttonDisabled}
        onClick={handleCreateProject}
      />
    </Tooltip>
  );
}
