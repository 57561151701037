import { ErrorNotification, Text } from '@epam/promo';
import { INotification } from '@epam/uui';

type Props = {
  notificationProps: INotification;
  error: any;
};

export const DefaultError = (props: Props) => {
  return (
    <ErrorNotification {...props.notificationProps}>
      <Text>
        {props.error instanceof Error
          ? props.error.message
          : props.error.toString()}
      </Text>
    </ErrorNotification>
  );
};
