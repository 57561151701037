export const enum ProjectStatus {
  INITIAL = 'INITIAL',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
  DELETE = 'DELETE',
}

export const enum ProjectShowroomStatus {
  EMPTY = 'EMPTY',
  SHOWROOM_REQUESTED = 'SHOWROOM_REQUESTED',
  IN_SHOWROOM = 'IN_SHOWROOM',
}

export const enum ProjectPosition {
  PO = 'Project Owner',
}

export const enum ProjectRoles {
  PO = 'PROJECT_OWNER',
  SM = 'SCRUM_MASTER',
  BA = 'BUSINESS_ANALYST',
  PM = 'PRODUCT_MANAGER',
  SA = 'SOLUTION_ARCHITECT',
}

export const enum ProjectActions {
  EDIT_PROJECT = 'EDIT_PROJECT',
  EDIT_SHOWROOM = 'EDIT_SHOWROOM',
  EDIT_OPPORTUNITY = 'EDIT_OPPORTUNITY',
  EDIT_MEMBERS = 'EDIT_MEMBERS',
  EDIT_STATUS = 'EDIT_STATUS',
  TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
  OWNER_NOTIFICATION = 'OWNER_NOTIFICATION',
}

export const enum ProjectLinkCategory {
  REPOSITORIES = 'Repositories',
  PROJECT_INFO = 'Project Info',
  ENVIRONMENTS = 'Environments',
  PROMO_ARTIFACTS = 'Promotional Artifacts',
  VIDEOS = 'Videos',
  CONTACT = 'Contact',
}
