import React, { useRef } from 'react';
import './styles.scss';
import { IconButton, Tooltip } from '@epam/promo';
import '@epam/uui/styles.css';
import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import { ReactComponent as telescopeIcon } from '@epam/assets/icons/common/communication-telescope-18.svg';
import { ReactComponent as teamsIcon } from '@epam/assets/icons/common/microsoft-teams-18.svg';
import { ReactComponent as notificationDone } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as navigationClose } from '@epam/assets/icons/common/navigation-close-18.svg';
import { ReactComponent as commentIcon } from '@epam/assets/icons/common/communication-communication-chat_alt-24.svg';
import { useUuiContext, FlexRow, Text } from '@epam/uui';
import { useQuery } from '@tanstack/react-query';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import userService from 'services/api/user/userService';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import Avatar from 'component/Avatar';
import { IUser } from 'modules/list-opportunities/models/model';
import memberService from 'services/api/member/memberService';
import { dateFormatter } from 'utils/dateUtils';

export interface ApplicantProps {
  membershipRequestId: number;
  user: IUser;
  position: string;
  onAccept: () => void;
  onReject: () => void;
  openDetails: boolean;
  onToggleDetails: (open: boolean) => void;
}

const Applicant = ({
  position,
  onAccept,
  onReject,
  user,
  membershipRequestId,
  openDetails,
  onToggleDetails,
}: ApplicantProps) => {
  const svc = useUuiContext();
  const name = useRef(`${user.firstName} ${user.lastName}`);
  const initials = useRef(user.firstName.charAt(0) + user.lastName.charAt(0));

  const { data: image } = useQuery(
    [QUERY_KEYS.USERS.PROFILE_PHOTO, user.id],
    async () => {
      return userService.getUserProfilePicture(user.id);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const { data: membershipRequest } = useQuery(
    [QUERY_KEYS.MEMBERSHIP.MEMBERSHIP_GET, membershipRequestId],
    async () => {
      return memberService.getMembershipRequest(membershipRequestId);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return (
    <div className="applicant-item">
      <div className="applicant-header">
        <div className="applicant-info">
          <Avatar
            cx="applicant-image"
            alt={`${name.current}'s profile`}
            img={image}
            size={'36'}
            initials={initials.current}
          />
          <div className="applicant-details">
            <div className="info-container">
              <span className="applicant-name">{name.current}</span>
              <div className="button-icons">
                <IconButton
                  cx="mail-icon"
                  color="gray50"
                  icon={mailIcon}
                  href={`mailto:${user.email}`}
                />
                <IconButton
                  cx="teams-icon"
                  color="gray50"
                  icon={teamsIcon}
                  onClick={() => {
                    window.open(
                      `https://teams.microsoft.com/l/chat/0/0?users=${user.email}`,
                      '_blank'
                    );
                  }}
                />
                <IconButton
                  color="gray50"
                  cx="telescope-icon"
                  onClick={() => {
                    window.open(
                      `https://telescope.epam.com/who/key/upsaId/${user.externalId}}?tab=profile`,
                      '_blank'
                    );
                  }}
                  icon={telescopeIcon}
                />
              </div>
            </div>
            <Text cx="applicant-position">{position}</Text>
          </div>
        </div>
        <FlexRow cx="applicant-actions">
          <IconButton
            color="blue"
            onClick={() => onToggleDetails(!openDetails)}
            aria-label="Open request details"
            icon={commentIcon}
          />
          <Tooltip content="Accept" placement="top">
            <IconButton
              color="blue"
              onClick={onAccept}
              aria-label="Accept applicant"
              icon={notificationDone}
            />
          </Tooltip>
          <Tooltip content="Reject" placement="top">
            <IconButton
              color="blue"
              onClick={onReject}
              aria-label="Reject applicant"
              icon={navigationClose}
            />
          </Tooltip>
        </FlexRow>
      </div>
      {openDetails && membershipRequest && (
        <div className="applicant-request">
          {!!membershipRequest.comment.trim() && (
            <Text fontSize="12">“{membershipRequest.comment.trim()}”</Text>
          )}
          <Text fontSize="12" cx="applicant-date">
            {dateFormatter(membershipRequest.dateCreated)}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Applicant;
