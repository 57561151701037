import { Text, Button } from '@epam/promo';
import { ReactComponent as linkedEvent } from '../../../../../assets/svg/linked-event.svg';
import { ILinkedEvent } from 'modules/project-list/components/project-card/modal';
import './styles.scss';

const ProjectLinkedEvent = ({
  challengeId,
  challengeLink,
  challengeName,
}: ILinkedEvent) => (
  <div className={'linked-event'} key={challengeId}>
    <Text cx={'title'} fontSize={'16'}>
      Event
    </Text>
    <Button
      icon={linkedEvent}
      fill={'light'}
      cx={'link'}
      target="_blank"
      href={challengeLink}
      caption={challengeName}
    />
  </div>
);

export default ProjectLinkedEvent;
