import './styles.scss';
import { ILens, LazyDataSourceApiRequest, useLazyDataSource } from '@epam/uui';
import { FlexRow, LabeledInput, PickerInput, PickerToggler } from '@epam/promo';
import { useCallback, useEffect, useState } from 'react';
import eventManagementService from '../../../../../services/api/event-managment/eventManagementService';
import { EVENTS_PAGE_SIZE } from '../../../../../constants/uiConstants';
import {
  IEventProject,
  IProjectMember,
} from '../../../../project-list/components/project-card/modal';
import { ProjectDetails } from 'modules/project-management/models/interfaces';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import { EventResponse } from 'modules/events/models/interfaces';
import { useUserWithCommonEditPermissions } from 'hooks/useUserWithCommonEditPermissions';
import { isActiveEventRegistrationPeriod } from 'utils/dayjsHelpers';
import { toTimeZoneBasedDate } from 'utils/dateUtils';
import { isUserWithEditorRole } from 'utils/rolesUtils';

interface Props {
  isNewProject: boolean;
  lens: ILens<ProjectDetails['event']>;
  editorSwitch: boolean;
  projectMembers?: IProjectMember[];
}

const EventsSelector = ({
  isNewProject,
  lens,
  editorSwitch,
  projectMembers,
}: Props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(true);
  const [event, setEvent] = useState<EventResponse | null>(null);
  const { userInfo } = useUserInfo();
  const { isUserWithCommonEditPermissions } = useUserWithCommonEditPermissions(
    userInfo,
    projectMembers ?? []
  );
  const [eventId] = useState(lens.get()?.id);

  const loadEvents = useCallback(
    async (request: LazyDataSourceApiRequest<IEventProject, string>) => {
      const searchPhrase = request.search || '';
      const offset = request.range?.from || 0;
      const amount = request.range?.count || EVENTS_PAGE_SIZE;

      const response = await eventManagementService.getEvents(
        amount,
        offset,
        searchPhrase,
        true
      );
      return { items: response.challengeList };
    },
    []
  );
  const eventsDataSource = useLazyDataSource({ api: loadEvents }, []);

  useEffect(() => {
    if (eventId) {
      eventManagementService
        .getEvent(eventId)
        .then((eventDetails) => setEvent(eventDetails));
    }
  }, []);

  useEffect(() => {
    const isEditor = isUserWithEditorRole(userInfo);
    if ((isNewProject && !eventId) || editorSwitch) {
      setIsDisabled(false);
      setIsSelectDisabled(false);
    } else {
      setIsSelectDisabled(true);
      setIsDisabled(
        !event ||
          isNewProject || // disable events field if user creates project from an event
          (!isEditor && !isUserWithCommonEditPermissions) ||
          !isActiveEventRegistrationPeriod(
            toTimeZoneBasedDate(event.registrationStartDate, event.timeZone),
            toTimeZoneBasedDate(event.registrationEndDate, event.timeZone)
          )
      );
    }
  }, [isUserWithCommonEditPermissions, isNewProject, editorSwitch, event]);

  return (
    <FlexRow vPadding="24" cx={'event-container'}>
      <LabeledInput label="Events" cx={'event-container__label'}>
        <PickerInput
          dataSource={eventsDataSource}
          selectionMode="single"
          {...lens.toProps()}
          getName={(item) => item.name}
          valueType="entity"
          entityName="event"
          isDisabled={isDisabled}
          disableClear={true}
          searchPosition="body"
          renderToggler={(props) => {
            const selectedValue = props.selection && props.selection[0];
            if (selectedValue) {
              selectedValue.onCheck = props.onClear;
            }
            if (isSelectDisabled) {
              props.isOpen = false;
              props.isDropdown = false;
              props.isDisabled ||= !selectedValue;
            }
            return (
              <PickerToggler
                {...props}
                pickerMode="multi"
                cx={'events-toggler'}
              ></PickerToggler>
            );
          }}
        />
      </LabeledInput>
    </FlexRow>
  );
};

export default EventsSelector;
