import { LazyDataSourceApiResponse } from '@epam/uui';
import { ISkilloTech } from '../../../../project-list/components/project-card/modal';
import ProjectListService from '../../../../../services/api/project-list/projectListService';
import { MAX_RESULTS_SKILLO_API } from '../../../../../constants/uiConstants';

export default async function (
  request: any
): Promise<LazyDataSourceApiResponse<ISkilloTech>> {
  let res: Array<ISkilloTech>;
  if (request.search || request.search === '') {
    /*
    if search input is empty (e.g. EU opened dropdown of PickerInput comp and didn't type anything)
    search for 'A' to get skills to be rendered in the dropdown list
    */
    const query = request.search.length ? request.search : 'A';
    const response = await ProjectListService.getSkilloTechsByQuery(
      query,
      MAX_RESULTS_SKILLO_API
    );
    res = response.results;
  } else {
    const parsedQuery = request.ids.join().toString();
    res = await ProjectListService.getSkilloTechsById(parsedQuery);
  }
  const formattedResult = res.map((item) => {
    return { skilloId: item.id, name: item.name, id: item.id };
  });
  return { items: formattedResult };
}
