import { PROJECTS_PAGE_SIZE } from 'constants/uiConstants';
import { UserInfo } from 'services/api/user/userInfo';
import { ProjectStatus } from '../../models/enums';
import {
  getMarkedForDeletion,
  getPreviewURL,
  getProjectInfoModalItem,
  getProjectLinks,
  getProjectLocation,
  getProjectMembers,
  getProjectOpportunities,
  getProjectOwnerModalItem,
  getProjectShowroomModalItem,
  getProjectStatusModalItem,
  getProjectTimeModalItem,
  IProjectCard,
  getProjectLinkedEvent,
} from './components/project-card/modal';
import { FilterModel, PaginationModel, Project } from './models/interfaces';
import dayjs from 'dayjs';
import { toTimeZoneBasedDate } from 'utils/dateUtils';

export const checkDisabledStatus = (
  currentProjectStatus: string,
  statusOption: string
) => {
  let disabledStatus: string[] = [];
  switch (currentProjectStatus) {
    case ProjectStatus.INITIAL:
      disabledStatus = [ProjectStatus.SUSPENDED, ProjectStatus.CLOSED];
      break;
    case ProjectStatus.ACTIVE:
      disabledStatus = [ProjectStatus.INITIAL];
      break;
    case ProjectStatus.SUSPENDED:
      disabledStatus = [ProjectStatus.INITIAL];
      break;
    case ProjectStatus.CLOSED:
      disabledStatus = [ProjectStatus.INITIAL, ProjectStatus.SUSPENDED];
      break;
    default:
      break;
  }
  return disabledStatus.includes(statusOption);
};

export const filterToAPIParams = (
  filter: FilterModel,
  userInfo?: UserInfo
): [
  string | null,
  string | null | undefined,
  PaginationModel | null,
  number | null
] => {
  return [
    filter.activeStatus ?? null,
    filter.searchValue !== '' ? filter.searchValue : null,
    filter.page
      ? {
          startPoint: (filter.page - 1) * PROJECTS_PAGE_SIZE,
          amount: PROJECTS_PAGE_SIZE,
        }
      : null,
    filter.myProjects ? (userInfo ? userInfo.id : null) : null,
  ];
};

export const toProjectCardProps = (projectProps: Project): IProjectCard => {
  return {
    info: getProjectInfoModalItem(projectProps),
    time: getProjectTimeModalItem(projectProps),
    owner: getProjectOwnerModalItem(projectProps),
    status: getProjectStatusModalItem(projectProps),
    showroom: getProjectShowroomModalItem(projectProps),
    opportunities: getProjectOpportunities(projectProps),
    members: getProjectMembers(projectProps),
    location: getProjectLocation(projectProps),
    projectLinks: getProjectLinks(projectProps),
    previewURL: getPreviewURL(projectProps),
    markedForDeletion: getMarkedForDeletion(projectProps),
    challenge: getProjectLinkedEvent(projectProps),
  };
};

export function checkEventLocationMatch(
  isGlobalEvent: boolean,
  location: string,
  userInfo: any
) {
  const isEventLocationSameAsUserLocation = userInfo
    ? location.includes(userInfo.country)
    : false;

  return isEventLocationSameAsUserLocation || isGlobalEvent;
}

export function checkEventRegistrationOpen(
  registrationStartDate: string,
  registrationEndDate: string,
  timeZone: string
) {
  const currentDate = dayjs();

  const registrationStartDateS = toTimeZoneBasedDate(
    registrationStartDate,
    timeZone
  );
  const registrationEndDateS = toTimeZoneBasedDate(
    registrationEndDate,
    timeZone
  );

  return (
    currentDate > registrationStartDateS && currentDate < registrationEndDateS
  );
}
