import { FlexRow, Panel, Tag, Text } from '@epam/promo';
import { ISkilloTech } from 'modules/project-list/components/project-card/modal';
import './styles.scss';

interface Props {
  keywords: string[];
  technologies: ISkilloTech[];
}

export const ProjectTags = (props: Props) => (
  <Panel cx="project-detail-tags">
    <Text
      fontSize={'18'}
      lineHeight={'24'}
      font={'museo-sans'}
      cx="project-tags-header"
    >
      Keywords & Technologies
    </Text>
    {props.keywords?.length > 0 && (
      <FlexRow alignItems="top" spacing="12" cx="project-tags">
        {props.keywords?.map((item) => (
          <Tag key={item} size="18" caption={item} cx="project-detail-key" />
        ))}
      </FlexRow>
    )}
    {props.technologies.length > 0 && (
      <FlexRow alignItems="top" spacing="12" cx="project-tags">
        {props.technologies.map((tech) => (
          <Tag
            key={tech.skilloId}
            size="18"
            caption={tech.name}
            cx="project-detail-tech"
          />
        ))}
      </FlexRow>
    )}
  </Panel>
);
