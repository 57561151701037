import React from 'react';
import { useScreenSize } from '../screen';

const useIsOverflow = (ref: React.RefObject<HTMLDivElement>) => {
  const [isOverflow, setIsOverflow] = React.useState(false);
  const { width, height } = useScreenSize();

  React.useEffect(() => {
    const { current } = ref;

    const findDiff = (...sizes: number[]) => {
      const { max, min } = sizes.reduce(
        (acc, cur) => ({
          max: Math.max(acc.max, cur),
          min: Math.min(acc.min, cur),
        }),
        { max: -Infinity, min: Infinity }
      );

      return max - min;
    };

    const trigger = () => {
      if (current) {
        const heightDiff = findDiff(
          current.scrollHeight,
          current.offsetHeight,
          current.clientHeight
        );
        const widthDiff = findDiff(
          current.scrollWidth,
          current.offsetWidth,
          current.clientWidth
        );
        setIsOverflow(heightDiff > 0 || widthDiff > 0);
      } else {
        setIsOverflow(false);
      }
    };

    trigger();
  }, [ref, height, width]);

  return isOverflow;
};

export default useIsOverflow;
