import { Panel, FlexRow, LabeledInput, TextInput, FlexCell } from '@epam/promo';
import { addLink } from '../helpers/utils';
import { useLensProps } from 'hooks/useLens';
import { ILens } from '@epam/uui';
import { ReactComponent as done } from '@epam/assets/icons/common/notification-done-18.svg';
import { IconContainer, Text } from '@epam/promo';
import { tooltips } from '../constants';
import {
  IProjectLink,
  ProjectLinkType,
} from 'modules/project-management/models/interfaces';
import { LINKS_LIMIT_TOOLTIP_INFO, BUTTON_CAPTION_VARIANT } from '../constants';
import { LINK_TYPES } from '../../project-links/constants';
import UploadFile from '../../../../../../component/Artifacts/UploadFile';
import LinksRenderer from '../../../../../../component/Artifacts/LinksRenderer';
import AddLinkButton from '../../../../../../component/Artifacts/AddLinkButton';
import LinksWithDropdownRenderer from '../../../../../../component/Artifacts/LinksWithDropdownRenderer';
import LabeledInputWithCustomTooltip from 'component/LabeledInputWithCustomTooltip';
import { MAX_PROJECT_LINKS } from 'constants/uiConstants';
import { getGitlabTooltip } from 'modules/project-management/details/utils';

const RequiredArtifactsForm = ({
  lens,
  dictionary,
  projectLinks,
  isReachedLinkLimit,
  className,
  currentLinkCount,
  gitlabUrl,
  isClosedFromActive,
}: {
  lens: ILens<any>;
  dictionary: ProjectLinkType[];
  projectLinks: IProjectLink[];
  isReachedLinkLimit: boolean;
  className?: string;
  currentLinkCount: number;
  gitlabUrl: string;
  isClosedFromActive: boolean;
}) => {
  const { getFullPropObject } = useLensProps(lens);
  const gitlabTooltip = getGitlabTooltip(gitlabUrl);

  return (
    <Panel background="white" cx={className ? `${className}__body` : ''}>
      <Panel cx={className ? `${className}__body__status` : ''}>
        <LabeledInput
          label={
            isClosedFromActive
              ? 'Event Organizers require these artifacts for project submission.'
              : 'Repositories:'
          }
          cx={className ? `${className}__body__status__title` : ''}
          isRequired
        />
        {!isClosedFromActive && (
          <>
            <FlexRow cx="icon-name-container" vPadding="12" alignItems="top">
              <FlexRow cx="icon-name-container">
                <IconContainer icon={done} color="green" size={24} />
                <LabeledInputWithCustomTooltip
                  label="Code on Gitlab"
                  tooltip={gitlabTooltip}
                  className={`showroom-label ${
                    getFullPropObject(LINK_TYPES.GIT_REPO).isInvalid
                      ? 'showroom-label__invalid-field'
                      : ''
                  }`}
                />
              </FlexRow>
              <FlexCell
                cx="showroom-label__input-block"
                style={{ flexBasis: 'auto' }}
              >
                <TextInput
                  placeholder="https://..."
                  id={LINK_TYPES.GIT_REPO}
                  {...getFullPropObject(LINK_TYPES.GIT_REPO)}
                  cx={
                    getFullPropObject(LINK_TYPES.GIT_REPO).isInvalid
                      ? 'showroom-label__invalid-field'
                      : ''
                  }
                />
                <LabeledInput {...getFullPropObject(LINK_TYPES.GIT_REPO)} />
              </FlexCell>
            </FlexRow>
            <LinksRenderer
              lens={lens}
              projectLinks={projectLinks}
              dictionary={dictionary}
              targetLinkTypes={[LINK_TYPES.GIT_OTHER, LINK_TYPES.GIT_GITHUB]}
              className="showroom-label"
              isRemovable
            />
            <LinksWithDropdownRenderer
              lens={lens}
              projectLinks={projectLinks}
              dictionary={dictionary}
              targetLinkTypes={[LINK_TYPES.GIT_GITHUB, LINK_TYPES.GIT_OTHER]}
              caption={BUTTON_CAPTION_VARIANT.ADD_SOURCE}
              isDisabled={isReachedLinkLimit}
              activeDropdownCategory={'Repositories'}
              className="showroom-label_add"
            />
          </>
        )}
      </Panel>
      <Panel cx={className ? `${className}__body__status` : ''}>
        <LabeledInput
          cx={className ? `${className}__body__status__title` : ''}
          isRequired
          label={isClosedFromActive ? 'Required Artifacts' : 'Project Info:'}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.DOCS_DECKS]}
          className="showroom-label"
          tooltip={[tooltips.DOCS_DECKS]}
        />
        <UploadFile
          lens={lens}
          label="Slide Deck"
          projectLinks={projectLinks}
          tooltip={tooltips.SLIDE_DECK}
          className="showroom-label__files"
          targetLinkType={LINK_TYPES.SLIDE_DECK}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.PROJECT_INFO_OTHER]}
          className="showroom-label"
          isRemovable
        />
        {isClosedFromActive && (
          <UploadFile
            lens={lens}
            label="Case Study"
            projectLinks={projectLinks}
            tooltip={tooltips.CASE_STUDY}
            className="showroom-label__files"
            targetLinkType={LINK_TYPES.CASE_STUDY}
          />
        )}
        {!isClosedFromActive && (
          <FlexRow cx="icon-name-container" vPadding="12">
            <AddLinkButton
              fill="white"
              color="blue"
              className="showroom-label_add"
              tooltip={LINKS_LIMIT_TOOLTIP_INFO}
              caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
              isDisabled={isReachedLinkLimit}
              onClick={() =>
                addLink(lens, {
                  id: null,
                  link: '',
                  linkType: LINK_TYPES.PROJECT_INFO_OTHER,
                })
              }
            />
            <Text>
              {currentLinkCount} / {MAX_PROJECT_LINKS}
            </Text>
          </FlexRow>
        )}
      </Panel>
    </Panel>
  );
};

export default RequiredArtifactsForm;
