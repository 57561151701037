import { FC } from 'react';
import ProjectList from 'modules/project-list';
import './showroom.scss';
import { PageHeader } from '../../component/page-header/page-header';
import { HEADER_STYLES } from 'constants/uiConstants';

export interface Props {
  editorSwitch: boolean;
}

const PATH_NAME = '/showroom';

const Showroom: FC<Props> = ({ editorSwitch }) => {
  return (
    <div className="showroom-page">
      <PageHeader
        title="Successful Innovation Stories"
        background={HEADER_STYLES.COMMON.background}
        color={HEADER_STYLES.COMMON.color}
      />
      <ProjectList
        editorSwitch={editorSwitch}
        pathName={PATH_NAME}
        isShowroom={true}
      />
    </div>
  );
};

export default Showroom;
