import React, { useCallback } from 'react';
import { ILens, LazyDataSourceApiRequest, useLazyDataSource } from '@epam/uui';
import { FlexCell, FlexRow, LabeledInput, PickerInput } from '@epam/promo';
import { ISkilloTech } from '../../../../project-list/components/project-card/modal';
import { MAX_TECHNOLOGIES } from '../../../../../constants/uiConstants';
import getSkilloResponse from './utils';
import { Counter } from '../counter';
import './styles.scss';
import { ProjectDetails } from 'modules/project-management/models/interfaces';

function Technologies({
  lens,
}: {
  lens: ILens<ProjectDetails['technologies']>;
}) {
  const loadSkilloSkills = useCallback(
    (request: LazyDataSourceApiRequest<ISkilloTech, string, unknown>) => {
      return getSkilloResponse(request);
    },
    []
  );

  const skilloDS = useLazyDataSource(
    {
      api: loadSkilloSkills,
      selectAll: false,
    },
    []
  );

  const techsAmount = lens.toProps().value.length;
  return (
    <>
      <FlexRow vPadding={'24'}>
        <FlexCell grow={1} rawProps={{ 'data-auto-scroll': '' }}>
          <LabeledInput
            label="Technologies and Tools"
            {...lens.toProps()}
            cx={'technologies'}
          >
            <PickerInput
              dataSource={skilloDS}
              selectionMode={'multi'}
              valueType={'entity'}
              {...lens.toProps()}
              placeholder="Enter technologies and tools, for example “Java”"
              entityName="Technologies and Tool"
              sorting={{ field: 'name', direction: 'asc' }}
              getRowOptions={(item: ISkilloTech) => ({
                checkbox: {
                  isVisible: true,
                  isDisabled:
                    lens.toProps().value?.length >= MAX_TECHNOLOGIES &&
                    !lens.toProps().value?.find((tech) => {
                      if (tech && tech.id === item.id) return tech;
                    }),
                },
              })}
            />
          </LabeledInput>
          <Counter currentValue={techsAmount} maxValue={MAX_TECHNOLOGIES} />
        </FlexCell>
      </FlexRow>
    </>
  );
}

export { Technologies };
