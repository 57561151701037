import React from 'react';
import { Button } from '@epam/promo';
import { useSearchParams } from 'react-router-dom';

export function SeeAllProjectsButton() {
  const [, setSearchParams] = useSearchParams();
  return (
    <Button
      color={'blue'}
      caption={'See all'}
      onClick={() => setSearchParams({ state: 'ALL' })}
    />
  );
}
