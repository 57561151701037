import { useUuiContext } from '@epam/uui';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { ModalFooter, FlexSpacer, Button } from '@epam/promo';
import { EditShowroomFooterProps } from '../add-to-showroom/models/interfaces';
import { LINK_TYPES } from '../project-links/constants';
import { EDIT_SHOWROOM_VALIDATION_ERRORS } from './models/validationSchema';
import {
  getLinkByType,
  getLinksWithProps,
} from '../add-to-showroom/helpers/utils';
import { useLensProps } from 'hooks/useLens';
import {
  requiredArtifactsFormLinksTypes,
  optionalArtifactsFormLinksTypes,
} from '../add-to-showroom/helpers/utils';
import ProjectManagementService from 'services/api/project-management/projectManagementService';

const Footer = ({
  lens,
  validate,
  saveForm,
  setNextStep,
  setActiveStep,
  setPreviousStep,
  projectId,
  projectLinks,
  isFirstStep,
  isNotLastStep,
}: EditShowroomFooterProps) => {
  const { GIT_REPO, GIT_OTHER, DOCS_DECKS, SLIDE_DECK, CASE_STUDY, EMAIL } =
    LINK_TYPES;
  const svc = useUuiContext();
  const { setProp } = useLensProps(lens);
  const requiredArtifactsLinks = getLinksWithProps(
    lens,
    requiredArtifactsFormLinksTypes
  );
  const optionalArtifactsLinks = getLinksWithProps(
    lens,
    optionalArtifactsFormLinksTypes
  );
  const renderNextButton = () => (
    <Button color="blue" caption="Next" onClick={setNextStep} />
  );

  const renderBackButton = () => (
    <Button
      color="gray50"
      fill="white"
      caption="Back"
      onClick={setPreviousStep}
    />
  );

  return (
    <ModalFooter borderTop cx="modal-footer">
      <FlexSpacer />
      {isFirstStep && isNotLastStep ? (
        renderNextButton()
      ) : !isFirstStep && isNotLastStep ? (
        <>
          {renderBackButton()}
          {renderNextButton()}
        </>
      ) : (
        <>
          {renderBackButton()}
          <Button
            color="green"
            caption="Save"
            onClick={async () => {
              const userEpamGitlabLink = getLinkByType(projectLinks, GIT_OTHER);
              svc.uuiModals
                .show((props) => <BlockerModal modalProps={props} />, {
                  modalId: 'blocker',
                })
                .catch(() => null);
              let userHasActiveRepository;
              try {
                userHasActiveRepository =
                  await ProjectManagementService.getFieldsStatusToAddProjectToShowroom(
                    projectId,
                    !!userEpamGitlabLink && userEpamGitlabLink.link
                  );
                setProp('BE_ERROR_MESSAGE', '');
              } catch (err) {
                userHasActiveRepository = false;
                setProp(
                  'BE_ERROR_MESSAGE',
                  EDIT_SHOWROOM_VALIDATION_ERRORS.INVALID_EPAM_GITLAB_LINK // hardcoding error message due to current FE axios limitation of reading all of BE error messages. Actual BE error message should be read from the response once axios error message handling logic is fixed in the ticket https://jira.epam.com/jira/browse/EPMEMRDGD-64
                );
              }
              setProp('userHasActiveRepository', userHasActiveRepository);
              validate();
              useCloseBlocker(svc);
              if (
                lens.prop('projectMetadata').toProps().isInvalid ||
                lens.prop('keywords').toProps().isInvalid ||
                lens.prop('technologies').toProps().isInvalid ||
                lens.prop('projectTimestamps').toProps().isInvalid
              ) {
                setActiveStep(0);
              } else if (
                requiredArtifactsLinks.some(
                  (link: any) => link.toProps().isInvalid
                )
              ) {
                setActiveStep(1);
              } else if (
                requiredArtifactsLinks.some(
                  (link: any) => !link.toProps().isInvalid
                ) &&
                optionalArtifactsLinks.some(
                  (link: any) => !link.toProps().isInvalid
                )
              ) {
                saveForm();
              }
            }}
          />
        </>
      )}
    </ModalFooter>
  );
};

export default Footer;
