import React, { FC } from 'react';
import './page-header.scss';

type Props = {
  title: string;
  background: string;
  color: string;
  actionBtn?: JSX.Element | false;
};

export const PageHeader: FC<Props> = ({
  title,
  background,
  color,
  actionBtn,
}) => {
  return (
    <div className="page-header">
      <div className="page-header__header_background" style={{ background }}>
        <div className="page-header__header">
          <div className="page-header__title" style={{ color }}>
            {title}
          </div>

          {actionBtn && (
            <div className="page-header__action-button">{actionBtn}</div>
          )}
        </div>
      </div>
    </div>
  );
};
