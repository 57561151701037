import React, { FC } from 'react';
import garageUserService from '../../../services/api/garage-user/garageUserService';
import {
  MAX_RESULTS_GARAGE_USERS,
  USER_ROLES,
} from '../../../constants/uiConstants';
import './garage-users-table.scss';
import { Text, WarningAlert } from '@epam/promo';
import { CustomDataTable } from '../../../component/data-table/custom-data-table';
import { DataColumnProps, useUuiContext } from '@epam/uui';
import { RoleType, UserInfo } from '../../../services/api/user/userInfo';

import { ToggleRole } from './toggle-role';
import { useQuery } from '@tanstack/react-query';
import permissionService from '../../../services/api/permission/permissionService';
import { useUserInfo } from '../../../services/auth-client/user-info-provider';
import { QUERY_KEYS } from '../../../constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { UserCard } from './user-card';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

type Props = {};

export const GarageUsersTable: FC<Props> = () => {
  const svc = useUuiContext();
  const { userInfo } = useUserInfo();

  const { data: admins } = useQuery(
    [QUERY_KEYS.USERS.SPECIFIED_USER_ROLE],
    () => permissionService.getUsersWithSpecifiedPermission('ADMIN'),
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const hasRole = (roles: RoleType[], role: RoleType) =>
    !!roles.find((r) => r == role);

  const AM_I_ADMIN = hasRole(userInfo?.roles || [], USER_ROLES.ADMIN);

  const AM_I_SUPER_ADMIN = hasRole(
    userInfo?.roles || [],
    USER_ROLES.SUPER_ADMIN
  );

  const columns: DataColumnProps<UserInfo>[] = [
    {
      key: 'name',
      caption: 'Name',
      render: (item) => <UserCard key={item.id} {...item} />,
      grow: 1,
      width: 250,
    },
    {
      key: 'super_admin',
      caption: 'SUPER ADMIN',
      render: (item) => (
        <ToggleRole
          customDisableTooltipText={
            !hasRole(item.roles, USER_ROLES.ADMIN) && AM_I_SUPER_ADMIN
              ? 'You cannot transfer the role of Super Admin to non-Admins.'
              : AM_I_SUPER_ADMIN
              ? 'You cannot revoke your Admin rights until you transfer your role to another Admin'
              : undefined
          }
          disabled={
            !AM_I_SUPER_ADMIN ||
            hasRole(item.roles, USER_ROLES.SUPER_ADMIN) ||
            !hasRole(item.roles, USER_ROLES.ADMIN)
          }
          roleColumn="SUPER_ADMIN"
          user={item}
          admins={admins}
          minimumRole="Super Admin"
        />
      ),
      width: 150,
      grow: 0,
      shrink: 0,
    },
    {
      key: 'admin',
      caption: 'ADMIN',
      render: (item) => (
        <ToggleRole
          disabled={
            (admins &&
              admins.length <= 2 &&
              AM_I_ADMIN &&
              hasRole(item.roles, USER_ROLES.ADMIN)) ||
            !AM_I_ADMIN ||
            hasRole(item.roles, USER_ROLES.SUPER_ADMIN)
          }
          customDisableTooltipText={
            admins && admins.length <= 2
              ? "It's recommended to have at least 2 Admins. Add new Admins before revoking rights from other Admins"
              : AM_I_SUPER_ADMIN
              ? 'You cannot revoke your Admin rights until you transfer your role to another Admin'
              : hasRole(item.roles, USER_ROLES.SUPER_ADMIN)
              ? 'You cannot assign or revoke this role because you are not a Super Admin'
              : undefined
          }
          roleColumn="ADMIN"
          user={item}
          admins={admins}
          minimumRole="Admin"
        />
      ),
      width: 150,
      grow: 0,
      shrink: 0,
    },
    {
      key: 'editor',
      caption: 'EDITOR',
      render: (item) => (
        <ToggleRole
          disabled={!AM_I_ADMIN}
          roleColumn="EDITOR"
          user={item}
          admins={admins}
          minimumRole="Admin"
        />
      ),
      width: 150,
      grow: 0,
      shrink: 0,
    },
  ];

  return (
    <div className="garage-users-table">
      {admins && admins.length <= 1 && (
        <WarningAlert cx="warning-alert">
          <Text fontSize="14" size="24">
            It&apos;s highly recommended to have more than one Admin in the
            system. Please assign another one.
          </Text>
        </WarningAlert>
      )}

      <CustomDataTable
        key={JSON.stringify(userInfo?.roles || [])}
        fetch={async ({ page, keyword }) => {
          const { userList, totalNumber } =
            await garageUserService.getListUsers({
              amount: MAX_RESULTS_GARAGE_USERS,
              startPoint: (page - 1) * MAX_RESULTS_GARAGE_USERS,
              searchPhrase: encodeURIComponent(keyword || ''),
            });

          return { total: totalNumber, data: userList };
        }}
        queryKey={QUERY_KEYS.USERS.USER_LIST}
        itemPerPage={MAX_RESULTS_GARAGE_USERS}
        filter={{
          amount: MAX_RESULTS_GARAGE_USERS,
        }}
        columns={columns}
        allowSearch
        placeholder="Search by name"
      />
    </div>
  );
};
