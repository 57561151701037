import React from 'react';
import { FlexRow, IconContainer, LinkButton, Panel, Text } from '@epam/promo';
import '../events-route.scss';
import { ReactComponent as LocationIcon } from '@epam/assets/icons/common/action-map_pin-12.svg';
import { ReactComponent as HomeIcon } from '@epam/assets/icons/common/action-language-12.svg';
import EventCardHeader from './EventCardHeader';
import EventCardDateLines from './EventCardDateLines';
import EventCardButtonPanel from './EventCardButtonPanel';
import { EventResponse, EventStatus, IEvent } from '../models/interfaces';
import OverflowTooltip from '../../../utils/OverflowTooltip';
import { GLOBAL_LOCATION } from 'constants/uiConstants';
import { toTimeZoneBasedDate, getCurrentDate } from 'utils/dateUtils';

const EventCard = (
  props: EventResponse & { editorSwitch: boolean }
): React.ReactElement => {
  const currentDate = getCurrentDate();
  const status: EventStatus = toTimeZoneBasedDate(
    props.plannedStartDate,
    props.timeZone
  ).isAfter(currentDate)
    ? 'UPCOMING'
    : toTimeZoneBasedDate(props.plannedEndDate, props.timeZone).isBefore(
        currentDate
      )
    ? 'ENDED'
    : 'IN_PROGRESS';

  const eventProps: IEvent = { ...props, status };

  const canViewProjetcs = () => {
    return toTimeZoneBasedDate(
      props.registrationStartDate,
      props.timeZone
    ).isBefore(currentDate);
  };

  const location = props.global ? GLOBAL_LOCATION : props.location;

  return (
    <Panel background="white" shadow>
      <div className="event-card-container">
        <div className="event-card-main">
          <EventCardHeader
            editorSwitch={props.editorSwitch}
            status={status}
            eventId={eventProps.id}
            event={eventProps}
          />
          <FlexRow>
            <IconContainer color="gray50" icon={LocationIcon} size={16} />
            <OverflowTooltip
              text={location}
              cx="no-wrap event-card-location"
              color="gray80"
              lineHeight="18"
              fontSize="12"
            />
          </FlexRow>
          <FlexRow size={null}>
            <OverflowTooltip
              text={props.name}
              cx="no-padding event-card-title"
              lineHeight="24"
              fontSize="18"
            />
          </FlexRow>
          <FlexRow cx="event-card-homesite">
            <LinkButton
              caption="Home Site"
              href={props.homeSite}
              target="_blank"
              icon={HomeIcon}
              size="24"
            />
          </FlexRow>
          <Text cx="no-padding grow event-card-description">
            {props.description}
          </Text>
        </div>
        <div className="event-card-footer">
          <EventCardDateLines event={eventProps} />
          <EventCardButtonPanel
            editorSwitch={props.editorSwitch}
            status={status}
            location={props.location}
            eventProject={{
              id: eventProps.id,
              name: eventProps.name,
            }}
            canViewProjects={canViewProjetcs()}
            registrationStartDate={toTimeZoneBasedDate(
              props.registrationStartDate,
              props.timeZone
            )}
            registrationEndDate={toTimeZoneBasedDate(
              props.registrationEndDate,
              props.timeZone
            )}
          />
        </div>
      </div>
    </Panel>
  );
};

export default EventCard;
