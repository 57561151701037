import { LazyDataSourceApiResponse } from '@epam/uui';
import {
  IProjectMember,
  ITelescopeMember,
} from 'modules/project-list/components/project-card/modal';
import ProjectListService from 'services/api/project-list/projectListService';

export async function normalizeUsersResponse(
  query: string,
  projectTeam: IProjectMember[]
): Promise<LazyDataSourceApiResponse<ITelescopeMember>> {
  /*
   * Telescope search doesn't support special characters, e.g. %~^().
   * * The following code replaces unwanted characters with double-encoded white space character
   * To avoid HTTP 500 error from the BE and return 'No records found' message
   * */
  const regexp = /[^a-zA-Z0-9_@.\s]/g;
  const replaceStr = encodeURI(encodeURI(' '));
  const formattedQuery = query.replaceAll(regexp, replaceStr);
  const res = await ProjectListService.getTelescopeUsersByQuery(formattedQuery);
  return {
    items: res.filter(
      (a) => !projectTeam.map((b) => b.externalId).includes(a.externalId)
    ),
  };
}

export function remakeTelescopeMemberIntoProjectMember(
  member: ITelescopeMember,
  position: string,
  id: number
): IProjectMember {
  return {
    city: member.city,
    country: member.country,
    email: member.email,
    externalId: member.externalId,
    firstName: member.firstName,
    lastName: member.lastName,
    position,
    id,
    role: 'CONTRIBUTOR',
  };
}
