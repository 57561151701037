import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);
dayjs.extend(isBetween);

export const isActiveEventRegistrationPeriod = (
  startDate: Dayjs,
  endDate: Dayjs
) => {
  const currentDate = dayjs();
  return currentDate.isBetween(startDate, endDate, null, '[]');
};
