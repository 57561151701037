import React from 'react';
import { Text, FlexRow } from '@epam/promo';

const Location = ({ uniqueLocations }: any) => {
  return (
    <div className="location">
      <Text
        cx="section-title location-title"
        fontSize="18"
        lineHeight="24"
        font="museo-sans"
      >
        Locations
      </Text>

      <FlexRow cx="project-detail-right__location">
        <ul className="project-detail-right__location-list">
          {uniqueLocations.map((e: any) => (
            <li
              className="project-detail-right__location-list-item"
              key={e.city}
            >
              <span className="project-detail-right__location-list-item--country">
                {e.country}
              </span>
              <span className="project-detail-right__location-list-item--city">
                , {e.city}
              </span>
            </li>
          ))}
        </ul>
      </FlexRow>
    </div>
  );
};

export default Location;
