import { useUuiContext } from '@epam/uui';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { ModalFooter, FlexSpacer, Button } from '@epam/promo';
import { AddToShowroomFooterProps } from './models/interfaces';
import { LINK_TYPES } from '../project-links/constants';
import { SHOWROOM_VALIDATION_ERRORS } from './models/validationSchema';
import { getLinkByType, getLinksWithProps } from './helpers/utils';
import { useLensProps } from 'hooks/useLens';
import {
  requiredArtifactsFormLinksTypes,
  optionalArtifactsFormLinksTypes,
} from './helpers/utils';
import ProjectManagementService from 'services/api/project-management/projectManagementService';
import { EditorSwitchContext } from 'App';
import { useContext } from 'react';

const Footer = ({
  lens,
  validate,
  saveForm,
  setNextStep,
  setPreviousStep,
  projectId,
  projectLinks,
  isNotLastStep,
  isClosedFromActive,
  onClosedFromActiveSubmitSuccess,
}: AddToShowroomFooterProps) => {
  const { GIT_OTHER } = LINK_TYPES;
  const svc = useUuiContext();
  const { setProp } = useLensProps(lens);
  const requiredArtifactsLinks = getLinksWithProps(
    lens,
    requiredArtifactsFormLinksTypes
  );
  const optionalArtifactsLinks = getLinksWithProps(
    lens,
    optionalArtifactsFormLinksTypes
  );
  const { SLIDE_DECK, CASE_STUDY } = LINK_TYPES;
  const isEditorModeEnabled = useContext(EditorSwitchContext);

  const areRequiredArtifactsPresent = () => {
    const slideDeckLink = projectLinks.find(
      (link) => link.linkType === SLIDE_DECK
    );
    const caseStudyLink = projectLinks.find(
      (link) => link.linkType === CASE_STUDY
    );
    return (
      slideDeckLink && slideDeckLink.file && caseStudyLink && caseStudyLink.file
    );
  };
  const isSubmitDisabled = isClosedFromActive && !areRequiredArtifactsPresent();
  const handleSubmit = async () => {
    const userEpamGitlabLink = getLinkByType(projectLinks, GIT_OTHER);
    try {
      svc.uuiModals.show((props) => <BlockerModal modalProps={props} />, {
        modalId: 'blocker',
      });

      const userHasActiveRepository =
        await ProjectManagementService.getFieldsStatusToAddProjectToShowroom(
          projectId,
          !!userEpamGitlabLink && userEpamGitlabLink.link
        );

      setProp('BE_ERROR_MESSAGE', SHOWROOM_VALIDATION_ERRORS.ATTACH_YOUR_FILE);
      setProp('userHasActiveRepository', userHasActiveRepository);
      validate();

      if (
        requiredArtifactsLinks.some((link: any) => link.toProps().isInvalid) ||
        optionalArtifactsLinks.some((link: any) => !link.toProps().isInvalid)
      ) {
        setPreviousStep();
      } else {
        useCloseBlocker(svc);
        const activeSubmitSuccess = await onClosedFromActiveSubmitSuccess();

        useCloseBlocker(svc);

        const saveFormSuccess = await saveForm();
      }
    } catch (err) {
      setProp(
        'BE_ERROR_MESSAGE',
        SHOWROOM_VALIDATION_ERRORS.INVALID_EPAM_GITLAB_LINK
      );
    }
  };

  return (
    <ModalFooter borderTop cx="modal-footer">
      <FlexSpacer />
      {isNotLastStep ? (
        <Button
          color="blue"
          isDisabled={!isEditorModeEnabled && isSubmitDisabled}
          caption={isClosedFromActive ? 'Submit' : 'Next'}
          onClick={isClosedFromActive ? handleSubmit : setNextStep}
        />
      ) : (
        <>
          <Button
            color="gray50"
            fill="white"
            caption="Back"
            onClick={setPreviousStep}
          />
          <Button
            color="green"
            caption="Add to Showroom"
            onClick={async () => {
              const userEpamGitlabLink = getLinkByType(projectLinks, GIT_OTHER);
              svc.uuiModals
                .show((props) => <BlockerModal modalProps={props} />, {
                  modalId: 'blocker',
                })
                .catch(() => null);
              let userHasActiveRepository;
              try {
                userHasActiveRepository =
                  await ProjectManagementService.getFieldsStatusToAddProjectToShowroom(
                    projectId,
                    !!userEpamGitlabLink && userEpamGitlabLink.link
                  );
                setProp('BE_ERROR_MESSAGE', '');
              } catch (err) {
                userHasActiveRepository = false;
                setProp(
                  'BE_ERROR_MESSAGE',
                  SHOWROOM_VALIDATION_ERRORS.INVALID_EPAM_GITLAB_LINK // hardcoding error message due to current FE axios limitation of reading all of BE error messages. Actual BE error message should be read from the response once axios error message handling logic is fixed in the ticket https://jira.epam.com/jira/browse/EPMEMRDGD-64
                );
              }
              setProp('userHasActiveRepository', userHasActiveRepository);
              validate();
              useCloseBlocker(svc);
              if (
                requiredArtifactsLinks.some(
                  (link: any) => link.toProps().isInvalid
                )
              ) {
                setPreviousStep();
              } else if (
                requiredArtifactsLinks.some(
                  (link: any) => !link.toProps().isInvalid
                ) &&
                optionalArtifactsLinks.some(
                  (link: any) => !link.toProps().isInvalid
                )
              ) {
                saveForm();
              }
            }}
          />
        </>
      )}
    </ModalFooter>
  );
};

export default Footer;
