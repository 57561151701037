import React, { FC } from 'react';
import {
  Badge,
  Button,
  EpamAdditionalColor,
  FlexRow,
  FlexSpacer,
  LinkButton,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  Text,
  Tooltip,
} from '@epam/promo';
import {
  EVENT_STATUS,
  EVENT_STATUS_COLOR,
} from '../../../constants/uiConstants';
import '../events-route.scss';
import { ReactComponent as DeleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
import { ReactComponent as EditIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import { IModal, useUuiContext } from '@epam/uui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import eventManagementService from '../../../services/api/event-managment/eventManagementService';
import { QUERY_KEYS } from '../../../constants/queryKeys';
import { DefaultError } from '../../../component/notification/DefaultError';
import { ManageEventsModal } from '../manage/manage-events-modal';
import { getTimeZone, getTimezoneBasedDateTime } from 'utils/dateUtils';
import { useTimezones } from 'services/timezone';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import { isUserWithEditorRole } from 'utils/rolesUtils';
import useNotificationsModal from 'component/NotifiactionsModal';
import { IEvent } from '../models/interfaces';

interface Props {
  editorSwitch: boolean;
  status: 'UPCOMING' | 'ENDED' | 'IN_PROGRESS';
  eventId: number;
  event: IEvent;
}

const DeleteEventModal = (modalProps: IModal<string>) => {
  return (
    <ModalBlocker disallowClickOutside blockerShadow="dark" {...modalProps}>
      <ModalWindow>
        <Panel>
          <ModalHeader
            title="Delete event?"
            onClose={() => modalProps.abort()}
          />
          <FlexRow padding="24" vPadding="12">
            <Text fontSize="16" lineHeight="24">
              Are you sure you want to delete the event? You will not be able to
              restore it.
            </Text>
          </FlexRow>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => modalProps.abort()}
            />
            <Button
              color="red"
              caption="Delete event"
              onClick={() => modalProps.success('Event has been deleted')}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

const EventCardHeader: FC<Props> = ({
  editorSwitch,
  status,
  eventId,
  event,
}) => {
  const { uuiModals, uuiNotifications } = useUuiContext();
  const { timezones } = useTimezones();
  const queryClient = useQueryClient();
  const { successNotification } = useNotificationsModal();
  const { userInfo } = useUserInfo();
  const isEditor = isUserWithEditorRole(userInfo);

  const eventMutation = useMutation(
    () => eventManagementService.deleteEvent(eventId),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([QUERY_KEYS.EVENTS.EVENTS_LIST]);
        successNotification('Event was successfully deleted');
      },
      onError: (error) => {
        uuiNotifications.show((props) => (
          <DefaultError notificationProps={props} error={error} />
        ));
      },
    }
  );

  const editEventNotification = (message: string) => {
    successNotification(message);
  };

  const deleteButton =
    status === 'ENDED' ? (
      <Tooltip placement="bottom" content="You cannot delete a past event">
        <LinkButton
          cx="link-button"
          icon={DeleteIcon}
          isDisabled
          iconPosition="right"
        />
      </Tooltip>
    ) : (
      <LinkButton
        cx="link-button"
        onClick={() =>
          uuiModals
            .show<string>((props) => <DeleteEventModal {...props} />)
            .then(() => eventMutation.mutate())
            .catch(() => null)
        }
        icon={DeleteIcon}
        iconPosition="right"
      />
    );

  const editButton =
    status === 'ENDED' && !isEditor ? (
      <Tooltip placement="bottom" content="You cannot edit a past event">
        <LinkButton
          cx="link-button"
          icon={EditIcon}
          isDisabled
          iconPosition="right"
        />
      </Tooltip>
    ) : (
      <LinkButton
        cx="link-button"
        onClick={() =>
          uuiModals
            .show<string>((props) => <EditEventModal {...props} />)
            .then((val) =>
              editEventNotification(
                `You have successfully updated the event "${val}".`
              )
            )
            .catch(() => null)
        }
        icon={EditIcon}
        iconPosition="right"
      />
    );

  const EditEventModal = (modalProps: IModal<string>) => {
    const timeZone = getTimeZone(timezones, event.timeZone);
    const eventStartDate = getTimezoneBasedDateTime(
      event.timeZone,
      event.plannedStartDate
    );
    const eventEndDate = getTimezoneBasedDateTime(
      event.timeZone,
      event.plannedEndDate
    );
    const startRegDate = getTimezoneBasedDateTime(
      event.timeZone,
      event.registrationStartDate
    );
    const endRegDate = getTimezoneBasedDateTime(
      event.timeZone,
      event.registrationEndDate
    );
    const deadlineDate = getTimezoneBasedDateTime(
      event.timeZone,
      event.resultDeadline
    );
    const eventProp = {
      id: event.id ?? 0,
      name: event.name,
      description: event.description,
      homeSiteUrl: event.homeSite,
      timezone: timeZone?.id,
      location: event.global ? '' : event.location,
      global: event.global,
      eventStartDate: eventStartDate.date,
      eventStartTime: eventStartDate.time,
      eventEndDate: eventEndDate.date,
      eventEndTime: eventEndDate.time,
      registrationStartDate: startRegDate.date,
      registrationStartTime: startRegDate.time,
      registrationEndDate: endRegDate.date,
      registrationEndTime: endRegDate.time,
      deadlineDate: deadlineDate.date,
      deadlineTime: deadlineDate.time,
      owner: event.owner,
      projectStatusFrozen: event.projectStatusFrozen,
    };
    return <ManageEventsModal modalProps={modalProps} event={eventProp} />;
  };

  return (
    <FlexRow>
      <Badge
        color={
          EVENT_STATUS_COLOR[
            status as keyof typeof EVENT_STATUS_COLOR
          ] as EpamAdditionalColor
        }
        size="18"
        caption={EVENT_STATUS[status as keyof typeof EVENT_STATUS]}
        fill="semitransparent"
        cx="event-card-status"
      />
      <FlexSpacer />
      {editorSwitch && (
        <>
          {deleteButton}
          {editButton}
        </>
      )}
    </FlexRow>
  );
};

export default EventCardHeader;
