import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useLocation, useSearchParams } from 'react-router-dom';
import NotFound from '../../../../assets/svg/not-found.svg';
import NoEntity from '../../../../assets/svg/no-entity-found.svg';
import { Panel, Text } from '@epam/promo';
import { SeeAllProjectsButton } from '../../../../component/Buttons/SeeAllProjectsButton';
import { OpportunitySubscriptionButton } from 'component/Buttons/OpportunitySubscriptionButton';
import { CreateProjectButton } from 'component/Buttons/CreateProjectButton';
import eventManagementService from 'services/api/event-managment/eventManagementService';
import { EventResponse } from 'modules/events/models/interfaces';
import {
  checkEventLocationMatch,
  checkEventRegistrationOpen,
} from 'modules/project-list/utils';
import { useUserInfo } from 'services/auth-client/user-info-provider';

const PROJECT_SUGGESTIONS = [
  'Check your search for any typos',
  'Try a different search',
  'Try using fewer words in your search',
  "The project you're searching for may be in another status",
  'Try looking for your project on the "My Projects" page to see projects that you own',
  'Try looking for the project on the "Dashboard" page to see all projects from all contributors',
];
const OPPORTUNITIES_SUGGESTIONS = [
  'Check your search for any typos',
  'Try using fewer words in your search',
];
const SHOWROOM_SUGGESTIONS = [
  'Check your search for any typos',
  'Try a different search',
  'Try using fewer words in your search',
  'Try looking for your project on the "My Projects" page to see projects that you own',
  'Try looking for the project on the "Dashboard" page to see all projects from all contributors',
];

type header = {
  [index: string]: string;
  style: string;
};

export const content = {
  common: {
    header: {
      message: 'Sorry, we could not find any matching results',
      style: 'suggestions',
    },
    message: {
      missingSearchResults: (suggestions: string[]) => {
        return (
          <>
            <b className={'suggestions__listHeader'}>Suggestions:</b>
            <ul className={'suggestions__list'}>
              {suggestions?.map((suggestion) => (
                <li className={'suggestions__listElement'} key={suggestion}>
                  {suggestion}
                </li>
              ))}
            </ul>
          </>
        );
      },
    },
  },
  dashboard: {
    header: {
      message: 'No projects yet',
      style: 'empty-panel',
    },
    message: {
      createProjectPrompt: 'Create your first project and share it with others',
      bannedByLocation: 'Your location cannot participate in this event',
      bannedByEventClose: 'The registration for the Event is not open',
      anotherStatusPrompt: 'Perhaps the project is in another status',
    },
    button: {
      createProjectButton: (buttonDisabled?: boolean, eventId?: number) => (
        <CreateProjectButton
          buttonDisabled={buttonDisabled}
          eventId={eventId}
        />
      ),
      seeAllProjectsButton: <SeeAllProjectsButton />,
    },
  },
  opportunities: {
    header: {
      message: 'No opportunities yet',
      style: 'empty-panel',
    },
    message:
      'Try contacting project leaders directly or subscribe to get notifications on new opportunities.',
    button: {
      OpportunitySubscriptionButton: <OpportunitySubscriptionButton />,
    },
  },
  showroom: {
    header: { message: 'No projects in Showroom yet', style: 'empty-panel' },
    message:
      'Perhaps some projects still have their Showroom requests pending. Please come back later.',
  },
  events: {
    header: { message: 'No events yet', style: 'empty-panel' },
    message: `There's nothing going on right now! Please come back later.`,
  },
};

const EmptyPanel = () => {
  const page = useLocation().pathname.slice(1).toLowerCase();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('status');
  const searchString = searchParams.get('search')?.length;
  const { userInfo } = useUserInfo();

  const eventId = Number(searchParams.get('eventId'));
  const [eventInfo, setEventInfo] = useState<EventResponse | null>(null);

  useEffect(() => {
    const getEvent = async () => {
      const event = await eventManagementService.getEvent(eventId);
      setEventInfo(event);
    };

    if (eventId) {
      getEvent();
    }
  }, []);

  let header: header = { message: '', style: '' };
  let message: JSX.Element | string = '';
  let actionButton: JSX.Element | null = null;
  let isImgVisible: boolean | null = null;
  let imageSrc: string | undefined = undefined;

  if ((page === 'dashboard' || page === 'my-projects') && !eventId) {
    const isTabAllorInitial =
      (tab === null ||
        tab.toLowerCase() === 'all' ||
        tab.toLowerCase() === 'initial') &&
      true;

    if (searchString) {
      header = content.common.header;
      message =
        content.common.message.missingSearchResults(PROJECT_SUGGESTIONS);
      isImgVisible = false;
      imageSrc = undefined;
    } else {
      header = content.dashboard.header;
      message = isTabAllorInitial
        ? content.dashboard.message.createProjectPrompt
        : content.dashboard.message.anotherStatusPrompt;
      isImgVisible = true;
      imageSrc = NotFound;
      actionButton = isTabAllorInitial
        ? content.dashboard.button.createProjectButton()
        : content.dashboard.button.seeAllProjectsButton;
    }
  }

  if (page === 'opportunities') {
    if (searchString) {
      header = content.common.header;
      message = content.common.message.missingSearchResults(
        OPPORTUNITIES_SUGGESTIONS
      );
      isImgVisible = false;
      imageSrc = undefined;
    } else {
      header = content.opportunities.header;
      message = content.opportunities.message;
      isImgVisible = true;
      imageSrc = NoEntity;
      actionButton = content.opportunities.button.OpportunitySubscriptionButton;
    }
  }

  if (page === 'showroom') {
    if (searchString) {
      header = content.common.header;
      message =
        content.common.message.missingSearchResults(SHOWROOM_SUGGESTIONS);
      isImgVisible = false;
      imageSrc = undefined;
    }
    if (header.message === '' && header.style === '') {
      header = content.showroom.header;
      message = content.showroom.message;
      isImgVisible = true;
      imageSrc = NoEntity;
    }
  }

  if (page === 'events') {
    header = content.events.header;
    message = content.events.message;
    isImgVisible = true;
    imageSrc = NoEntity;
  }

  if (page === 'dashboard' && eventId && eventInfo) {
    const isTabAllorInitial =
      (tab === null ||
        tab.toLowerCase() === 'all' ||
        tab.toLowerCase() === 'initial') &&
      true;
    const {
      registrationStartDate,
      registrationEndDate,
      timeZone,
      global,
      location,
    } = eventInfo;
    const bannedByEventClose = !checkEventRegistrationOpen(
      registrationStartDate,
      registrationEndDate,
      timeZone
    );
    const bannedByLocation = !checkEventLocationMatch(
      global,
      location,
      userInfo
    );
    const eventDisabled = bannedByEventClose || bannedByLocation;

    header = content.dashboard.header;
    isImgVisible = true;
    imageSrc = NotFound;
    message = bannedByEventClose
      ? content.dashboard.message.bannedByEventClose
      : bannedByLocation
      ? content.dashboard.message.bannedByLocation
      : isTabAllorInitial
      ? content.dashboard.message.createProjectPrompt
      : content.dashboard.message.anotherStatusPrompt;
    actionButton = isTabAllorInitial
      ? content.dashboard.button.createProjectButton(eventDisabled, eventId)
      : content.dashboard.button.seeAllProjectsButton;
  }

  return (
    <Panel style={{ alignItems: `${isImgVisible && 'center'}` }}>
      {isImgVisible && (
        <img
          src={imageSrc}
          alt={header.message}
          className={'empty-panel__img'}
        />
      )}
      <h2 className={`${header.style}__header`}>{header.message}</h2>
      <Text fontSize={'16'} cx={'empty-panel__message'}>
        {message}
      </Text>
      {actionButton}
    </Panel>
  );
};

export default EmptyPanel;
