import React from 'react';
import { IModal } from '@epam/uui';

import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  FlexSpacer,
  Panel,
  ScrollBars,
  Text,
  Button,
} from '@epam/promo';

interface Props {
  modalProps: IModal<boolean>;
  callback: () => void;
  title?: string;
}

const ConfirmLeavingModal = ({ modalProps, callback }: Props) => {
  return (
    <ModalBlocker blockerShadow="dark" {...modalProps}>
      <ModalWindow>
        <Panel background="white">
          <ModalHeader title={'Discard changes?'} onClose={modalProps.abort} />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24">
              <Text size="36">
                You have unsaved changes. If you leave, all changes will be
                lost.
              </Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => modalProps.abort()}
            />
            <Button
              color="blue"
              caption={'Discard changes'}
              onClick={() => {
                modalProps.abort();
                callback();
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};
export default ConfirmLeavingModal;
