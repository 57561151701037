import React, { useState } from 'react';
import { FlexCell, FlexRow, LabeledInput, Text } from '@epam/promo';
import TagInput from './custom-tag-input';
import {
  MAX_TECHNOLOGIES,
  MAX_KEYWORD_LENGTH,
} from '../../../../../constants/uiConstants';
import { ILens } from '@epam/uui';
import { Counter } from '../counter';
import './styles.scss';
import { ProjectDetails } from 'modules/project-management/models/interfaces';

function Keywords({ lens }: { lens: ILens<ProjectDetails['keywords']> }) {
  const [counter, setCounter] = useState<string>('');
  const [invalidKeyword, setInvalidKeyword] = useState<boolean>(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState<string>('');
  return (
    <>
      <FlexRow vPadding={'24'}>
        <FlexCell
          grow={1}
          rawProps={{ 'data-auto-scroll': '' }}
          cx={'keywords-container'}
        >
          <LabeledInput
            htmlFor={'project-keywords-input'}
            {...lens.toProps()}
            label="Project Keywords"
          >
            <Text
              fontSize={'12'}
              lineHeight={'18'}
              cx={'keywords-container__hint'}
            >
              {'Type a keyword and press "Enter" to add it to the list'}
            </Text>
            <TagInput
              {...lens.toProps()}
              placeholder='Enter keywords, for example "Healthcare", "Networking", "Innovation"'
              keyDownAgree="Enter"
              isRemovedOnBackSpace={true}
              invalidKeyword={invalidKeyword}
              setInvalidKeyword={setInvalidKeyword}
              maxTagAllowed={MAX_TECHNOLOGIES}
              maxTextLengthEachTag={MAX_KEYWORD_LENGTH}
              borderColor={lens.toProps().isInvalid}
              setInfo={setValidationErrorMsg}
              setCount={setCounter}
              id={'project-keywords-input'}
            />
          </LabeledInput>
          {!lens.toProps().isInvalid && (
            <Text
              fontSize={'12'}
              lineHeight={'18'}
              cx={'keywords-container__validation-error'}
            >
              {validationErrorMsg}
            </Text>
          )}
          <Counter
            currentValue={parseInt(counter.split('/')[0])}
            maxValue={parseInt(counter.split('/')[1])}
          />
        </FlexCell>
      </FlexRow>
    </>
  );
}

export { Keywords };
