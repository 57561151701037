import { ScrollBars } from '@epam/promo';
import { FC } from 'react';

interface Props {
  paddingTop?: number;
}

const Scrollbar: FC<Props> = ({ paddingTop = 60, children }) => {
  return (
    <ScrollBars
      style={{ height: `calc(100vh - ${paddingTop}px)` }}
      onLoad={() => window.dispatchEvent(new Event('resize'))}
    >
      {children}
    </ScrollBars>
  );
};

export default Scrollbar;
