import { ReactComponent as GitIcon } from '../../../../../assets/svg/git.svg';
import { ReactComponent as HomeSiteIcon } from '@epam/assets/icons/common/action-language-18.svg';
import { ReactComponent as ActionDragIcon } from '@epam/assets/icons/common/action-drag_indicator-18.svg';
import { ReactComponent as DocumentIcon } from '../../../../../assets/svg/link-document.svg';
import { ReactComponent as RocketIcon } from '../../../../../assets/svg/rocket-link.svg';
import { ReactComponent as TeamsIcon } from '../../../../../assets/svg/link-team.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/svg/mail-link.svg';
import { ReactComponent as ShowcaseIcon } from '../../../../../assets/svg/showcase-link.svg';
import { ReactComponent as VideoIcon } from '@epam/assets/icons/common/media-play-outline-18.svg';
import { ProjectLinkCategory } from 'models/enums';

export const LINK_TYPES = {
  GIT_REPO: 'GIT_REPO',
  GIT_OTHER: 'GIT_OTHER',
  GIT_GITHUB: 'GIT_GITHUB',
  DOCS_DECKS: 'DOCS_DECKS',
  SLIDE_DECK: 'SLIDE_DECK',
  CASE_STUDY: 'CASE_STUDY',
  PROJECT_INFO_OTHER: 'PROJECT_INFO_OTHER',
  HOME_SITE: 'HOME_SITE',
  TEST_ENVIRONMENT: 'TEST_ENVIRONMENT',
  ENVIRONMENT_OTHER: 'ENVIRONMENT_OTHER',
  PROMO_SOLUTION_HUB: 'PROMO_SOLUTION_HUB',
  PROMO_PRESALES_PORTAL: 'PROMO_PRESALES_PORTAL',
  SHOWCASE: 'SHOWCASE',
  CHALLENGE_SOLVED: 'CHALLENGE_SOLVED',
  VIDEO_LINK: 'VIDEO_LINK',
  VIDEO_PROMO: 'VIDEO_PROMO',
  VIDEO_WALKTHROUGH: 'VIDEO_WALKTHROUGH',
  VIDEO_OTHER: 'VIDEO_OTHER',
  TEAMS_CHANNEL: 'TEAMS_CHANNEL',
  EMAIL: 'EMAIL',
};

export const LINK_COLUMN_CATEGORIES: Record<string, ProjectLinkCategory[]> = {
  Understand: [
    ProjectLinkCategory.REPOSITORIES,
    ProjectLinkCategory.PROJECT_INFO,
  ],
  Experience: [ProjectLinkCategory.ENVIRONMENTS, ProjectLinkCategory.VIDEOS],
  Connect: [ProjectLinkCategory.PROMO_ARTIFACTS, ProjectLinkCategory.CONTACT],
};

export const LINK_TYPE_ICON_MAP = {
  [LINK_TYPES.GIT_REPO]: GitIcon,
  [LINK_TYPES.GIT_OTHER]: GitIcon,
  [LINK_TYPES.GIT_GITHUB]: GitIcon,
  [LINK_TYPES.DOCS_DECKS]: DocumentIcon,
  [LINK_TYPES.SLIDE_DECK]: DocumentIcon,
  [LINK_TYPES.CASE_STUDY]: DocumentIcon,
  [LINK_TYPES.PROJECT_INFO_OTHER]: DocumentIcon,
  [LINK_TYPES.HOME_SITE]: HomeSiteIcon,
  [LINK_TYPES.TEST_ENVIRONMENT]: HomeSiteIcon,
  [LINK_TYPES.ENVIRONMENT_OTHER]: HomeSiteIcon,
  [LINK_TYPES.PROMO_SOLUTION_HUB]: ActionDragIcon,
  [LINK_TYPES.PROMO_PRESALES_PORTAL]: RocketIcon,
  [LINK_TYPES.SHOWCASE]: ShowcaseIcon,
  [LINK_TYPES.CHALLENGE_SOLVED]: ActionDragIcon,
  [LINK_TYPES.VIDEO_LINK]: VideoIcon,
  [LINK_TYPES.VIDEO_PROMO]: VideoIcon,
  [LINK_TYPES.VIDEO_WALKTHROUGH]: VideoIcon,
  [LINK_TYPES.VIDEO_OTHER]: VideoIcon,
  [LINK_TYPES.TEAMS_CHANNEL]: TeamsIcon,
  [LINK_TYPES.EMAIL]: EmailIcon,
};

export const UPLOAD_TYPE_LINKS = [LINK_TYPES.SLIDE_DECK, LINK_TYPES.CASE_STUDY];

export const OTHER_LINK_TYPES = {
  PROJECT_INFO_OTHER: 'PROJECT_INFO_OTHER',
  ENVIRONMENT_OTHER: 'ENVIRONMENT_OTHER',
  CHALLENGE_SOLVED: 'CHALLENGE_SOLVED',
  VIDEO_OTHER: 'VIDEO_OTHER',
};
