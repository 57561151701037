import { useEffect, useState } from 'react';
import { IProjectMember } from 'modules/project-list/components/project-card/modal';
import { getProjectRole } from 'modules/project-management/details/utils';
import { projectRoles } from 'modules/project-management/details/utils';
import { UserInfo } from 'services/api/user/userInfo';

export const useUserWithCommonEditPermissions = (
  userInfo: UserInfo | any,
  projectMembers: IProjectMember[]
) => {
  const [isUserWithCommonEditPermissions, setIsUserWithCommonEditPermissions] =
    useState(false);

  useEffect(() => {
    const userRoleInProject =
      getProjectRole(
        projectMembers.find(
          (member: IProjectMember) => member.externalId === userInfo?.externalId
        )?.position
      ) ?? '';
    setIsUserWithCommonEditPermissions(
      projectRoles.includes(userRoleInProject)
    );
  }, []);

  return { isUserWithCommonEditPermissions };
};
