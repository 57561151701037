import ProjectList from 'modules/project-list';
import React, { FC } from 'react';

interface Props {
  editorSwitch: boolean;
}

const PATH_NAME = '/dashboard';

const Dashboard: FC<Props> = ({ editorSwitch }: Props): React.ReactElement => {
  return <ProjectList editorSwitch={editorSwitch} pathName={PATH_NAME} />;
};

export default Dashboard;
