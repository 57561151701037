import { ProjectManagementAPI } from 'constants/apiConstants';
import { BaseService } from '../baseService';
import { RequestHeader } from '../apiDefinitions';
import {
  IProjectFieldsStatus,
  IResponseGetProjectLinkTypes,
} from 'modules/project-management/models/interfaces';
import { ReportParams } from 'modules/project-list/models/interfaces';

class ProjectManagementService extends BaseService {
  private static instance: ProjectManagementService;

  public static getInstance(): ProjectManagementService {
    if (!ProjectManagementService.instance) {
      ProjectManagementService.instance = new ProjectManagementService();
    }
    return ProjectManagementService.instance;
  }

  public getProjectLinks() {
    return ProjectManagementService.instance.get<IResponseGetProjectLinkTypes>(
      ProjectManagementAPI.ProjectLinks
    );
  }

  public createProject(projectData: any): any {
    const requestHeaders = {
      [RequestHeader.CONTENT_TYPE]: 'multipart/form-data',
    };
    return ProjectManagementService.instance.post(
      ProjectManagementAPI.CreateProject,
      projectData,
      requestHeaders
    );
  }

  public updateProject(projectId: number, projectData: any) {
    return ProjectManagementService.instance.put(
      ProjectManagementAPI.UpdateProject(projectId),
      projectData
    );
  }

  public addOpportunity(projectId: number, opportunityData: any) {
    return ProjectManagementService.instance.post(
      ProjectManagementAPI.AddOpportunity(projectId),
      opportunityData
    );
  }

  public acceptShowroomRequest(projectId: number) {
    return ProjectManagementService.instance.get(
      ProjectManagementAPI.AcceptShowroomRequest(projectId)
    );
  }

  public declineShowroomRequest(projectId: number) {
    return ProjectManagementService.instance.get(
      ProjectManagementAPI.DeclineShowroomRequest(projectId)
    );
  }

  public revokeShowroomRequest(projectId: number) {
    return ProjectManagementService.instance.get(
      ProjectManagementAPI.RevokeShowroomRequest(projectId)
    );
  }

  public getFieldsStatusToAddProjectToShowroom(
    projectId: number,
    link?: string | undefined
  ) {
    return ProjectManagementService.instance.get<IProjectFieldsStatus>(
      ProjectManagementAPI.GetFieldsStatusToAddProjectToShowroom(
        projectId,
        link
      )
    );
  }

  public addShowroomRequest(projectId: number, addToShowroomData: any) {
    return ProjectManagementService.instance.post(
      ProjectManagementAPI.AddShowroomRequest(projectId),
      addToShowroomData
    );
  }

  public removeShowroomRequest(projectId: number) {
    return ProjectManagementService.instance.delete(
      ProjectManagementAPI.RemoveShowroomRequest(projectId)
    );
  }

  public getContributionReport(
    excelVersion: string | any,
    since: string,
    until: string,
    data?: ReportParams
  ) {
    return ProjectManagementService.instance.post(
      ProjectManagementAPI.GetContributionReport(excelVersion, since, until),
      data
    );
  }

  public getProjectReport(excelVersion: string | any, data?: ReportParams) {
    return ProjectManagementService.instance.post(
      ProjectManagementAPI.GetProjectReport(excelVersion),
      data
    );
  }

  public transferProjectOwnership(
    projectId: string | number,
    externalUserId: string
  ) {
    return ProjectManagementService.instance.post(
      ProjectManagementAPI.TransferOwnership(projectId),
      {
        externalUserId,
      }
    );
  }

  public markProjectForDeletion(projectId: number) {
    return ProjectManagementService.instance.delete(
      ProjectManagementAPI.DeleteProject(projectId)
    );
  }
  public updateProjectStatus(projectId: number, status: string) {
    return ProjectManagementService.instance.post(
      ProjectManagementAPI.UpdateProjectStatus(projectId, status)
    );
  }
}

export default ProjectManagementService.getInstance();
