import { Canceler } from 'axios';
import AxiosInstance, { CancelToken } from '../axios';
import { Dictionary } from 'models/dictionary';

interface ICancellable {
  cancelRequest(message: string): void;
}

export abstract class BaseService implements ICancellable {
  protected axiosInstance: typeof AxiosInstance;

  protected cancel?: Canceler;

  constructor() {
    this.axiosInstance = AxiosInstance;
  }

  public cancelRequest(message: string): void {
    this.cancel?.(message);
  }

  protected get<T>(url: string, data = {}, headers?: Dictionary<string>) {
    return this.axiosInstance.get(url, {
      headers: headers,
      ...data,
      cancelToken: new CancelToken((c) => {
        this.cancel = c;
      }),
    }) as Promise<T>;
  }

  protected post<T>(url: string, data?: any, headers?: Dictionary<string>) {
    return this.axiosInstance.post(url, data, {
      headers: headers,
      cancelToken: new CancelToken((c) => {
        this.cancel = c;
      }),
    }) as Promise<T>;
  }

  protected put<T>(url: string, data?: any, headers?: Dictionary<string>) {
    return this.axiosInstance.put(url, data, {
      headers: headers,
      cancelToken: new CancelToken((c) => {
        this.cancel = c;
      }),
    }) as Promise<T>;
  }

  protected delete<T>(url: string, data?: any, headers?: Dictionary<string>) {
    return this.axiosInstance.delete(url, {
      headers: headers,
      cancelToken: new CancelToken((c) => {
        this.cancel = c;
      }),
    }) as Promise<T>;
  }

  protected download<T>(url: string, data?: any, headers?: Dictionary<string>) {
    return this.axiosInstance.get(url, {
      headers: headers,
      timeout: 300000,
      ...data,
      responseType: 'blob',
      cancelToken: new CancelToken((c) => {
        this.cancel = c;
      }),
    }) as Promise<T>;
  }
}
