import { FlexRow } from '@epam/promo';
import { useEffect, useState } from 'react';
import { ILens } from '@epam/uui';
import {
  IProjectLink,
  ProjectLinkType,
} from 'modules/project-management/models/interfaces';
import { LINKS_LIMIT_TOOLTIP_INFO } from '../../modules/project-management/details/components/add-to-showroom/constants';
import AddLinkButton from './AddLinkButton';
import LinksDropdown from 'modules/project-management/details/components/add-to-showroom/Dropdown';

const LinksWithDropdownRenderer = ({
  lens,
  projectLinks,
  dictionary,
  caption,
  className,
  targetLinkTypes,
  activeDropdownCategory,
  multiOtherLinkType,
  isDisabled,
  tooltip,
  reachTypesLimitTooltip,
}: {
  lens: ILens<any>;
  projectLinks: IProjectLink[];
  dictionary: ProjectLinkType[];
  caption: string;
  className: string;
  targetLinkTypes: string[];
  activeDropdownCategory: string;
  multiOtherLinkType?: string;
  isDisabled: boolean;
  tooltip?: string;
  reachTypesLimitTooltip?: string;
}) => {
  const [activeDropdown, setActiveDropdown] = useState<string>('');
  const [isTypesLimitReached, setIsTypesLimitReached] = useState(false);
  const isOpen = activeDropdown === activeDropdownCategory;

  useEffect(() => {
    const targetLinks = !multiOtherLinkType // multiOtherLinkType is used to check if multiple others can be included in the dropdown
      ? projectLinks.filter((link: any) =>
          targetLinkTypes.includes(link.linkType)
        )
      : projectLinks.filter(
          (link: any) =>
            targetLinkTypes.includes(link.linkType) &&
            link.linkType !== multiOtherLinkType
        );
    setIsTypesLimitReached(targetLinks.length >= targetLinkTypes.length);
  }, [projectLinks, multiOtherLinkType, targetLinkTypes]);

  return (
    <>
      {!isTypesLimitReached && (
        <FlexRow cx="icon-name-container" vPadding="12" alignItems="top">
          {isOpen && (
            <LinksDropdown
              lens={lens}
              setActiveDropdown={setActiveDropdown}
              options={dictionary.filter((link: ProjectLinkType) =>
                targetLinkTypes.includes(link.value)
              )}
              multiOtherLinkType={multiOtherLinkType}
            />
          )}
          {!activeDropdown && (
            <AddLinkButton
              fill="white"
              color="blue"
              className={className}
              tooltip={tooltip || LINKS_LIMIT_TOOLTIP_INFO}
              caption={caption}
              isDisabled={isDisabled}
              onClick={(e: Event) => {
                e.stopPropagation();
                if (isOpen) {
                  setActiveDropdown('');
                } else {
                  setActiveDropdown(activeDropdownCategory);
                }
              }}
            />
          )}
        </FlexRow>
      )}
      {isTypesLimitReached && !!reachTypesLimitTooltip && (
        <AddLinkButton
          fill="white"
          color="blue"
          className={className}
          tooltip={reachTypesLimitTooltip}
          caption={caption}
          isDisabled={true}
          onClick={() => null}
        />
      )}
    </>
  );
};

export default LinksWithDropdownRenderer;
