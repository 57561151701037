import React, { FC, useState } from 'react';
import {
  DropdownContainer,
  FlexRow,
  LinkButton,
  Panel,
  Text,
} from '@epam/promo';
import { IEvent } from '../models/interfaces';
import { Timezone, useTimezones } from '../../../services/timezone';
import { Dropdown } from '@epam/uui-components';
import '../events-route.scss';
import { DropdownBodyProps } from '@epam/uui-core';
import { convertTimeZone } from 'utils/dateUtils';

interface LineProps {
  label: string;
  currentTimezone?: Timezone;
  baseTimezoneName?: string;
  start?: string;
  end?: string;
  closed?: boolean;
}

const Line: FC<LineProps> = ({
  label,
  baseTimezoneName,
  currentTimezone,
  start,
  end,
  closed,
}) => {
  const formatDate = (date: string | undefined, showYear: boolean) => {
    if (date && currentTimezone && baseTimezoneName) {
      const convertedDate = convertTimeZone(
        date,
        baseTimezoneName,
        currentTimezone.name
      );
      return convertedDate.format(`DD MMM${showYear ? ', YYYY' : ''}, HH:mm`);
    } else {
      return '';
    }
  };

  if (closed) {
    return (
      <FlexRow cx="event-card-date-line">
        <Text cx="event-card-frame" fontSize="12">
          <span className="date-line__title">{`${label}: `}</span>
          <span className="date-line">Closed</span>
        </Text>
      </FlexRow>
    );
  }

  const startDate = formatDate(start, true);
  const endDate = `${start && end ? ' - ' : ''}${formatDate(end, true)}`;

  return (
    <FlexRow cx="event-card-date-line">
      <Text cx="event-card-frame" fontSize="12" lineHeight="18">
        <span className="date-line__title">{`${label}: `}</span>
        <span className="date-line">
          {startDate}
          {endDate}
        </span>
      </Text>
    </FlexRow>
  );
};

interface Props {
  event: IEvent;
}

const removeOffset = /\s*\((UTC )?[+-]\d{1,2}:\d{1,2}\) /; // should be removed after https://git.garage.lab.epam.com/garage/garage-backend/-/issues/589

const EventCardDateLines: FC<Props> = ({ event }) => {
  const { userTimezone, findTimezone } = useTimezones();
  const eventTimezone = findTimezone(event.timeZone.replace(removeOffset, ''));

  const [currentTimezone, setCurrentTimezone] =
    useState<Timezone>(eventTimezone);

  const timezonePanel = (timezone: Timezone, label: string) => (
    <FlexRow
      cx="timezone-panel"
      key={timezone.id}
      onClick={() => setCurrentTimezone(timezone)}
    >
      <Panel>
        <Text
          cx={
            currentTimezone.id === timezone.id
              ? 'active-timezone'
              : 'primary-text'
          }
          fontSize="12"
          lineHeight="12"
        >
          {timezone.value}&nbsp;
        </Text>
      </Panel>
      <Panel>
        <Text cx="gray-text" fontSize="12" lineHeight="12">
          {label}
        </Text>
      </Panel>
    </FlexRow>
  );

  const renderDropdownBody = (props: DropdownBodyProps) => (
    <DropdownContainer {...props} width="auto">
      <Panel>
        {timezonePanel(userTimezone, 'your time zone')}
        <div className="divider"></div>
        {timezonePanel(eventTimezone, 'event time zone')}
      </Panel>
    </DropdownContainer>
  );

  return (
    <>
      <Line
        label="Duration"
        start={event.plannedStartDate}
        end={event.plannedEndDate}
        currentTimezone={currentTimezone}
        baseTimezoneName={event.timeZone}
      />
      {event.status === 'ENDED' ? (
        <Line label="Project registration" closed />
      ) : (
        <Line
          label="Project registration"
          start={event.registrationStartDate}
          end={event.registrationEndDate}
          currentTimezone={currentTimezone}
          baseTimezoneName={event.timeZone}
        />
      )}

      {event.status === 'ENDED' ? (
        <Line label="Results submission" closed />
      ) : (
        <Line
          label="Results submission"
          currentTimezone={currentTimezone}
          start={event.resultDeadline}
          baseTimezoneName={event.timeZone}
        />
      )}
      <FlexRow cx="event-timezones">
        <Panel>
          <Text cx="gray-text event-card-schedule" fontSize="12">
            Schedule is based on the time zone:&nbsp;
          </Text>
        </Panel>
        <Panel>
          {userTimezone.id !== eventTimezone.id && (
            <Dropdown
              renderBody={renderDropdownBody}
              renderTarget={(props) => (
                <LinkButton
                  captionCX="gray-text event-card-schedule dashed-text"
                  caption={currentTimezone.value}
                  size="24"
                  {...props}
                />
              )}
              openOnHover={true}
              closeOnMouseLeave="boundary"
            />
          )}
          <Text cx="gray-text event-card-schedule" fontSize="12">
            {userTimezone.id === eventTimezone.id && eventTimezone.value}
          </Text>
        </Panel>
      </FlexRow>
    </>
  );
};

export default EventCardDateLines;
