export const QUERY_KEYS = {
  DICTIONARY: {
    PROJECT_POSITIONS: 'project_positions',
    LINK_NAMES: 'link_names',
  },
  TIMEZONES: {
    TIMEZONES_LIST: 'timezones_list',
  },
  PROJECTS: {
    PROJECT_LIST: 'project_list',
    PROJECT_DETAILS: 'project_details',
    PROJECT_COVER: 'project_cover',
    PERMISSIONS: 'permissions',
  },
  OPPORTUNITIES: {
    OPPORTUNITY_LIST: 'opportunities_list',
    SUBSCRIPTION_LIST: 'subscription_list',
  },
  MEMBERSHIP: {
    MEMBERSHIP_GET: 'membership_get',
  },
  USERS: {
    USER_LIST: 'users',
    SPECIFIED_USER_ROLE: 'specified_users_role',
    ME: 'me',
    PROJECT_COUNT: 'project_count',
    PROFILE_PHOTO: 'profile_photo',
  },
  EVENTS: {
    EVENTS_LIST: 'events_list',
    EVENT_DETAILS: 'event_details',
  },
};
