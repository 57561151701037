import { useUuiContext } from '@epam/uui';
import ProjectList from 'modules/project-list';
import { ProjectDetailsWrapper } from 'modules/project-management/details/components/project-details-wrapper';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '@epam/promo';
import { NotFoundPage } from '../../../not-found-page/not-found-page';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../../../constants/queryKeys';
import projectListService from '../../../../services/api/project-list/projectListService';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

interface Props {
  editorSwitch: boolean;
}

const PATH_NAME = '/dashboard';

const DashboardProject = ({ editorSwitch }: Props): React.ReactElement => {
  const svc = useUuiContext();
  const { projectId } = useParams();

  if (!projectId) {
    return <ProjectList editorSwitch={editorSwitch} pathName={PATH_NAME} />;
  }

  const { data: currentProject } = useQuery(
    [QUERY_KEYS.PROJECTS.PROJECT_DETAILS, projectId],
    () => projectListService.getProjectById(projectId),
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  useEffect(() => {
    if (currentProject && !currentProject.markedForDeletion) {
      svc.uuiModals
        .show((modalProps: any) => {
          const detailProps = {
            modalProps,
            editorSwitch: editorSwitch,
            projectID: parseInt(projectId),
          };
          return <ProjectDetailsWrapper {...detailProps} />;
        })
        .catch(() => {
          window.history.replaceState(null, 'Dashboard', PATH_NAME);
        });
    }
  }, [currentProject]);

  if (!currentProject) {
    return (
      <div style={{ marginTop: '40px' }}>
        <Spinner />
      </div>
    );
  }

  if (currentProject.markedForDeletion) {
    return (
      <>
        <NotFoundPage />
      </>
    );
  } else {
    return <ProjectList editorSwitch={editorSwitch} pathName={PATH_NAME} />;
  }
};

export default DashboardProject;
