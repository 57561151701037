import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './badges-list.scss';
import { Tooltip, Badge, FlexRow } from '@epam/promo';
import useWindowSize from '../hooks/use-window-size';

type Props = {
  items: string[];
  color: 'green' | 'blue';
  className?: string;
};

const BADGE_MARGIN_RIGHT = 8;

/*
 * Estimated length of an +X badge
 * */
const COUNTER_BADGE_LENGTH = 30;

const getDisplayedTechIndex = (
  containerWidth: number,
  badgesLengthList: number[]
) => {
  let sumOfBadgesLength = 0;

  for (let i = 0; i < badgesLengthList.length; i++) {
    const margin = i === badgesLengthList.length - 1 ? 0 : BADGE_MARGIN_RIGHT;
    const width = badgesLengthList[i] + margin;

    if (sumOfBadgesLength + width > containerWidth) {
      return i;
    } else {
      sumOfBadgesLength += width;
    }
  }

  return badgesLengthList.length;
};

export const BadgesList: FC<Props> = ({ className = '', items, color }) => {
  const { width } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const badgesLengthList = useRef<number[]>([]);

  const [displayedIndex, setIndex] = useState<number>(items.length);

  useEffect(() => {
    const containerWidth = ref.current?.getBoundingClientRect().width;
    if (containerWidth && badgesLengthList.current.length > 0) {
      const idx = getDisplayedTechIndex(
        containerWidth - COUNTER_BADGE_LENGTH,
        badgesLengthList.current
      );
      setIndex(idx);
    }
  }, [width]);

  useLayoutEffect(() => {
    const badges = ref.current?.querySelectorAll('.badge');

    if (badges && badgesLengthList.current.length === 0) {
      badges.forEach((badge) =>
        badgesLengthList.current.push(badge.getBoundingClientRect().width)
      );
    }
  }, []);

  return (
    <FlexRow cx={className} ref={ref}>
      {items.slice(0, displayedIndex).map((item) => (
        <Badge
          key={item}
          color={color}
          fill={'semitransparent'}
          size="18"
          caption={item}
          cx={'badge'}
          captionCX={'badge__caption'}
        />
      ))}

      {displayedIndex <= items.length - 1 && (
        <Tooltip content={items.slice(displayedIndex).join(', ')}>
          <Badge
            color={color}
            fill={'semitransparent'}
            size="18"
            caption={`+${items.length - displayedIndex}`}
            cx={'badge'}
            captionCX={'badge__caption'}
          />
        </Tooltip>
      )}
    </FlexRow>
  );
};
