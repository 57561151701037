import { useContext } from 'react';
import { ILens } from '@epam/uui';
import { EventIF } from 'modules/events/models/interfaces';
import { useLensProps } from 'hooks/useLens';
import { Panel, Checkbox, Text } from '@epam/promo';
import {
  EditorSwitchContext,
  EventOrganizerSwitchContext,
} from '../../../../../App';

interface EventWorkflowProps {
  lens: ILens<EventIF>;
}

const EventWorkflowSettings = (props: EventWorkflowProps) => {
  const { getFullPropObject } = useLensProps(props.lens);

  const eventOrganizerSwitch = useContext(EventOrganizerSwitchContext);
  const editorSwitch = useContext(EditorSwitchContext);

  const showWorkflowSettings = eventOrganizerSwitch || editorSwitch;
  return showWorkflowSettings ? (
    <>
      <Panel cx="has-margin-top has-margin-bottom">
        <Text fontSize="14" lineHeight="24" font="museo-sans">
          Workflow Settings
        </Text>
        <Checkbox
          label="Freeze project status between submission and event end date"
          {...getFullPropObject('projectStatusFrozen')}
        />
      </Panel>
    </>
  ) : null;
};

export default EventWorkflowSettings;
