import {
  Checkbox,
  FlexRow,
  LabeledInput,
  Panel,
  TextArea,
  TextInput,
  Text,
} from '@epam/promo';
import { ILens } from '@epam/uui';
import { EventIF } from 'modules/events/models/interfaces';
import { useEffect } from 'react';
import { VALIDATION_ERRORS } from '../../event-schema';
import { useLensProps } from 'hooks/useLens';
import '../../../manage/events-field.scss';

interface EventDetailsProps {
  lens: ILens<EventIF>;
  isNewEvent: boolean;
  duplicateEventName: boolean;
  isUrlInvalid: boolean;
}

const EventDetails = (props: EventDetailsProps) => {
  const { getFullPropObject, setProp, getPropValue } = useLensProps(props.lens);
  const isGlobal = getPropValue('global');
  const location = getPropValue('location');

  useEffect(() => {
    if (isGlobal && location) {
      setProp('global', false);
    }
  }, [getPropValue('location')]);

  return (
    <>
      <Panel>
        <FlexRow vPadding="24">
          <LabeledInput
            label="Event Name"
            {...getFullPropObject('name')}
            isRequired
          >
            <TextInput
              placeholder="Enter event name"
              maxLength={60}
              {...getFullPropObject('name')}
              {...props.lens.prop('name').toProps()}
            />
          </LabeledInput>
        </FlexRow>
        {props.duplicateEventName && (
          <FlexRow cx="footnotes" alignItems="top">
            <Text fontSize="12" lineHeight="18" size="24" cx="invalid">
              {VALIDATION_ERRORS.EVENT_NAME_EXISTS}
            </Text>
          </FlexRow>
        )}
      </Panel>
      <Panel>
        <FlexRow vPadding="24">
          <LabeledInput
            label="Description"
            {...getFullPropObject('description')}
            isRequired
          >
            <TextArea
              rows={6}
              placeholder="Describe the objective of the event and how you think it can bring value"
              {...getFullPropObject('description')}
            />
          </LabeledInput>
        </FlexRow>
      </Panel>
      <Panel cx="has-margin-bottom">
        <FlexRow vPadding="24">
          <LabeledInput
            label="Home Site URL"
            {...getFullPropObject('homeSiteUrl')}
            isRequired
          >
            <TextInput
              placeholder="https://..."
              {...getFullPropObject('homeSiteUrl')}
            />
          </LabeledInput>
        </FlexRow>
        {props.isUrlInvalid && (
          <FlexRow cx="footnotes" alignItems="top">
            <Text fontSize="12" lineHeight="18" size="24" cx="invalid">
              {VALIDATION_ERRORS.DUPLICATE_HOME_SITE_LINK}
            </Text>
          </FlexRow>
        )}
      </Panel>
      <Panel cx="has-margin-bottom has-margin-top">
        <FlexRow vPadding="24">
          <LabeledInput label="Location" {...getFullPropObject('location')}>
            <TextInput
              cx={isGlobal ? 'uui-pale' : ''}
              placeholder="Enter a capitalized country name (e.g. Poland)"
              maxLength={500}
              {...getFullPropObject('location')}
              isDisabled={isGlobal}
            />
          </LabeledInput>
        </FlexRow>
        <FlexRow>
          <Checkbox label="Global" {...getFullPropObject('global')} />
        </FlexRow>
      </Panel>
    </>
  );
};

export default EventDetails;
