import { IOpportunity } from '../../../list-opportunities/models/model';

export interface IBackendProjectInfoModel {
  id: string;
  name: string;
  projectCoverImgUrl: string;
  description: string;
  keywords: Array<string>;
  technologies: Array<ISkilloTech>;
  labUrl: string;
}

export interface IShowroom {
  status: string;
  lastDateUpdateShowroomStatus: string;
}

export interface IProjectTime {
  plannedStartDate: string;
  plannedEndDate: string;
  lastUpdate: string;
}

export interface IProjectOwner {
  userId: string | number;
  fullName: string;
  imgProfileUrl: string;
  email: string;
  externalId: string;
}

export interface IProjectMember {
  id: number | string;
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
  role?: string;
  position?: string;
  city: string;
  country: string;
}

export interface ITelescopeMember {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  externalId: string;
  roles: string[] | null;
  city: string;
  country: string;
  photoUri: string;
  role?: string;
  position?: string;
}

export interface ILinkedEvent {
  challengeId: number;
  challengeLink: string;
  challengeName: string;
}

export interface IProjectCard {
  info: IBackendProjectInfoModel;
  time: IProjectTime;
  owner: IProjectOwner;
  status: string;
  showroom: IShowroom;
  members: IProjectMember[];
  previewURL: string;
  markedForDeletion: boolean;
  opportunities: IOpportunity[];
  location: [
    {
      city: string;
      country: string;
    }
  ];
  projectLinks: [
    {
      id: string;
      link: string;
      linkType: string;
    }
  ];
  challenge: ILinkedEvent;
}

export const getProjectOwnerModalItem = (data: any) => ({
  userId: data.owner.id,
  fullName: `${data.owner.firstName} ${data.owner.lastName}`,
  imgProfileUrl: data.owner.projectOwnerProfileImg,
  email: data.owner.email,
  externalId: data.owner.externalId,
});

export const getProjectTimeModalItem = (data: any) => ({
  plannedStartDate: data.plannedStartDate,
  plannedEndDate: data.plannedEndDate,
  lastUpdate: data.vcsLastActivityDate,
});

export interface ISkilloTech {
  id?: string;
  name: string;
  skilloId?: string;
}

export interface IEventProject {
  id: number;
  name: string;
}

/*
 * BE returns list of technologies as {skilloId: string, name: string}[]
 * UUI PickerInput component requires 'id: string | number' property to work correctly
 * */
const parseTechnologiesArray = (
  techArr: Array<ISkilloTech>
): Array<ISkilloTech> => {
  /*
   * Filtering out technologies of old format string[] for compatibility purposes
   * */
  const filteredTechArr = techArr.filter(
    (tech: ISkilloTech) => tech.skilloId !== null
  );
  return filteredTechArr.length
    ? filteredTechArr.map((tech: ISkilloTech) => {
        return {
          id: tech.skilloId,
          name: tech.name,
          skilloId: tech.skilloId,
        };
      })
    : [];
};

export const getProjectInfoModalItem = (data: any) => {
  return {
    id: data.id,
    name: data.name,
    projectCoverImgUrl: data.previewURL,
    description: data.description,
    keywords: data.keywords,
    technologies: parseTechnologiesArray(data.technologies),
    labUrl: data.labURL,
  };
};

export const getProjectStatusModalItem = (data: any) => data.status;

export const getProjectShowroomModalItem = (data: any) => ({
  status: data.statusShowroom,
  lastDateUpdateShowroomStatus: data.lastDateUpdateShowroomStatus,
});

export const getProjectOpportunities = (data: any) => data.opportunities;

export const getProjectMembers = (data: any) => data.members;

export const getProjectLinks = (data: any) => data.projectLinks;

export const getPreviewURL = (data: any) => data.previewURL;

export const getMarkedForDeletion = (data: any) => data.markedForDeletion;

export const getProjectLocation = (data: any) => data.members;

export const getProjectLinkedEvent = (data: any) => data.challenge;
