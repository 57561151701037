import { KEYWORDS_API } from 'constants/apiConstants';
import { BaseService } from '../baseService';

class KeywordsService extends BaseService {
  private static instance: KeywordsService;

  public static getInstance(): KeywordsService {
    if (!KeywordsService.instance) {
      KeywordsService.instance = new KeywordsService();
    }
    return KeywordsService.instance;
  }

  public getKeywords(amount: number, searchPhrase: string): Promise<string[]> {
    return KeywordsService.instance.get(
      KEYWORDS_API.GetKeywords(amount, searchPhrase)
    );
  }
}

export default KeywordsService.getInstance();
