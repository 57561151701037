import { LINK_TYPES } from '../../project-links/constants';

export const STEPS_ADD_TO_SHOWROOM = Object.freeze({
  REQUIRED_ARTIFACTS: 'Required Artifacts',
  OPTIONAL_ARTIFACTS: 'Optional Artifacts',
});

export const DROP_SPOT = Object.freeze({
  INFO_TEXT: 'PPT file. Limit for 1 file is 50Mb',
  FILE_SIZE_EXCEEDED_TEXT:
    'File size is too big, please upload a file less or equal 50Mb',
  WRONG_FILE_TYPE_TEXT: `File type isn't supported, please upload a .pptx file`,
});

export const tooltips = Object.freeze({
  DOCS_DECKS:
    'Use public space in EPAM KB. You can create a space for your project at <a href="https://epa.ms/GRGKB" target="_blank"><span style="text-decoration: underline;">EPAM Garage KB</span></a>',
  SLIDE_DECK:
    'See <a href="https://epa.ms/GRGSlideDeck" target="_blank"><span style="text-decoration: underline;">recommended Slide Deck structure</span></a>. The document will be uploaded to a special "Project Info Materials" subproject in your gitlab group',
  CASE_STUDY:
    'See <a href="https://epa.ms/GRGCaseStudy" target="_blank"><span style="text-decoration: underline;">recommended Case Study structure</span></a>. The document will be uploaded to a special "Project Info Materials" subproject in your gitlab group',
  HOME_SITE: 'Solution home site for users',
  TEST_ENVIRONMENT: 'Solution version to explore and test',
  PROMO_SOLUTION_HUB:
    'Submit your solution at <a href="https://epa.ms/GRGSolutionsHubAsset" target="_blank"><span style="text-decoration: underline;">Solutions Hub</span></a>',
  PROMO_PRESALES_PORTAL:
    'Submit your case study at <a href="https://epa.ms/GRGPresalesSubmit" target="_blank"><span style="text-decoration: underline;">Presales Portal</span></a>',
  SHOWCASE:
    'Add your case study to <a href="https://showcase.epam.com" target="_blank"><span style="text-decoration: underline;">Showcase Portal</span></a>',
  VIDEO_LINK:
    'Upload demo video to <a href="https://videoportal.epam.com/video/create" target="_blank"><span style="text-decoration: underline;">https://videoportal.epam.com/video/create</span></a>. Duration should be 3-5 minutes',
  VIDEO_PROMO:
    'Upload promo video to <a href="https://videoportal.epam.com/video/create" target="_blank"><span style="text-decoration: underline;">https://videoportal.epam.com/video/create</span></a>',
  VIDEO_WALKTHROUGH:
    'Upload solution walkthrough video to <a href="https://videoportal.epam.com/video/create" target="_blank"><span style="text-decoration: underline;">https://videoportal.epam.com/video/create</span></a>',
  TEAMS_CHANNEL: 'Add MS Teams Channel or Group',
  EMAIL: 'Email address of a dedicated DL',
});

export const PPT = 'application/vnd.ms-powerpoint';
export const PPTX =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const PDF = 'application/pdf';

export const ALLOWED_FILE_TYPES = [PPT, PPTX, PDF];

export const CONTACT_LINKS_LIMIT_TOOTIP_INFO =
  'You already added 2 links to contact';

export const LINKS_LIMIT_TOOLTIP_INFO =
  'You have already reached limit of 30 links';

export const BUTTON_CAPTION_VARIANT = {
  ADD_LINK: 'Add Link',
  ADD_SOURCE: 'Add Source',
};

export const addToShowroomDefaultLinkTypes = [
  LINK_TYPES.DOCS_DECKS,
  LINK_TYPES.SLIDE_DECK,
  LINK_TYPES.CASE_STUDY,
  LINK_TYPES.HOME_SITE,
  LINK_TYPES.TEST_ENVIRONMENT,
  LINK_TYPES.PROMO_SOLUTION_HUB,
  LINK_TYPES.PROMO_PRESALES_PORTAL,
  LINK_TYPES.SHOWCASE,
  LINK_TYPES.CHALLENGE_SOLVED,
  LINK_TYPES.VIDEO_LINK,
  LINK_TYPES.VIDEO_PROMO,
  LINK_TYPES.VIDEO_WALKTHROUGH,
  LINK_TYPES.TEAMS_CHANNEL,
  LINK_TYPES.EMAIL,
];
