import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  FlexRow,
  FlexSpacer,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  ScrollBars,
  SuccessNotification,
  Text,
  useForm,
} from '@epam/promo';
import { IModal, INotification, useUuiContext } from '@epam/uui';
import './styles.scss';
import { projectDetailsSchema, VALIDATION_ERRORS } from './validationSchema';
import { useUserInfo } from '../../../services/auth-client/user-info-provider';
import { createProjectDataObject, prepareFormData } from './utils';
import {
  ProjectDetails,
  ProjectLinkType,
  ProjectStatus,
} from '../models/interfaces';
import projectManagementService from '../../../services/api/project-management/projectManagementService';
import { EventOrganizerSwitchContext, queryClient } from '../../../App';
import { QUERY_KEYS } from '../../../constants/queryKeys';

import { ProjectMeta } from './components/project-meta';
import { Cover } from './components/cover';
import { Keywords } from './components/keywords';
import { Technologies } from './components/technologies';
import { EndDate } from './components/end-date';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from '../../../hooks/useCloseBlocker';
import { STEPS_PROJECT_FORM } from './constants';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { LinkDictionary } from '../details/components/add-to-showroom/models/interfaces';
import { MAX_PROJECT_LINKS } from 'constants/uiConstants';
import { checkUploadLinks } from '../details/components/add-to-showroom/helpers/utils';
import { showErrorMessages } from 'component/errorMessagesRenderer/errorMessagesRenderer';
import { validationErrors } from 'models/errors';
import dayjs from 'dayjs';
import Artifacts from './components/artifacts';
import useNotificationsModal from 'component/NotifiactionsModal';
import ConfirmLeavingModal from './components/confirm-leaving';
import projectListService from '../../../services/api/project-list/projectListService';
import EventsSelector from './components/event/EventsSelector';
import StepperController from 'component/Stepper';
import { Project } from 'modules/project-list/models/interfaces';
import { IEvent } from 'modules/events/models/interfaces';
import { formatDateForBE } from 'utils/dateUtils';
import { ISkilloTech } from 'modules/project-list/components/project-card/modal';

interface Props {
  modalProps: any;
  project: Partial<Project> | null;
  editorSwitch: boolean;
}

function UpdateProject({ modalProps, project, editorSwitch }: Props) {
  const svc = useUuiContext();
  const [isNewProject, setIsNewProject] = useState<boolean>(false);
  const [autoScroll, triggerAutoScroll] = useState<boolean>(false);
  const [formSaveInProgress, setFormSaveInProgress] = useState<boolean>(false);
  const { userInfo } = useUserInfo();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const steps = [
    STEPS_PROJECT_FORM.ABOUT_PROJECT,
    STEPS_PROJECT_FORM.ARTIFACTS,
    '',
  ];
  const [dictionary, setDictionary] = useState<ProjectLinkType[]>([]);
  const [currentLinkCount, setCurrentLinkCount] = useState(0);
  const isReachedLinkLimit = currentLinkCount === MAX_PROJECT_LINKS;
  const defaultKeywords = useRef<string[]>([]);
  const defaultTechnologies = useRef<ISkilloTech[]>([]);
  const defaultEndDate = useRef(
    project?.plannedEndDate ?? formatDateForBE(dayjs().add(3, 'month'))
  );
  const gitlabUrl = project?.labURL || '';
  const completeLinks = checkUploadLinks(project?.projectLinks ?? []);
  const { errorNotification } = useNotificationsModal();
  const eventOrganizerSwitch = useContext(EventOrganizerSwitchContext);

  const callbackModal = () => {
    modalProps.abort();
  };

  const showLeaveConfirmation = () => {
    isChanged
      ? svc.uuiModals
          .show((confirmModalProps: IModal<boolean>) => (
            <ConfirmLeavingModal
              modalProps={confirmModalProps}
              callback={callbackModal}
            />
          ))
          .catch(() => null)
      : callbackModal();
  };

  const isOtherModalActive = useMemo(() => {
    return svc.uuiModals?.getOperations()?.length > (isNewProject ? 1 : 2);
  }, [modalProps]);

  if (!project?.id && !isNewProject) {
    setIsNewProject(true);
  }

  const handleValidationMessage = (message: string, project: any) => {
    if (message.includes('This project name already exists.')) {
      return VALIDATION_ERRORS.PROJECT_NAME_EXISTS;
    } else if (message.includes('is a reserved name')) {
      return VALIDATION_ERRORS.PROJECT_NAME_RESERVED(project.name);
    }

    return null;
  };

  const { lens, save, isChanged, validate } = useForm<ProjectDetails>({
    validationOn: 'change',
    value: {
      id: project?.id ?? 0,
      status: project?.status ?? ProjectStatus.INITIAL,
      projectMetadata: {
        name: project?.name ?? '',
        description: project?.description ?? '',
      },
      cover: {
        imgUrl: project?.previewURL ?? '',
        uploadedImage: null,
      },
      projectTimestamps: {
        plannedStartDate: project?.plannedStartDate ?? '',
        plannedEndDate: project?.plannedEndDate ?? defaultEndDate.current,
      },
      keywords: project?.keywords ?? defaultKeywords.current,
      technologies:
        project?.technologies?.map(
          (tech: { skilloId: string; name: string }) => {
            return {
              skilloId: tech.skilloId,
              id: tech.skilloId,
              name: tech.name,
            };
          }
        ) ?? defaultTechnologies.current,
      event: (project &&
        project.challenge && {
          id: project.challenge.challengeId,
          name: project.challenge.challengeName,
        }) as IEvent,
      projectLinks: completeLinks,
    },
    onSave: async (projectFormData) => {
      setFormSaveInProgress(true);
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);

      const projectData: any = await createProjectDataObject(
        projectFormData,
        isNewProject,
        userInfo
      );

      const formData = prepareFormData(projectData);

      try {
        if (isNewProject) {
          await projectManagementService.createProject(formData);
        } else if (project?.id) {
          const fetchedData = await projectListService.getProjectById(
            project.id
          );
          /*
           * Project's dateLastUpdated field is not updated on the BE if
           * project's keywords, technologies or links have been changed.
           * Therefore, comparing two JSONs to check if the project has been changed.
           * */
          if (
            JSON.stringify(project.dateLastUpdated) !==
            JSON.stringify(fetchedData.dateLastUpdated)
          ) {
            return Promise.reject('The project has changed');
          }
          if (
            fetchedData.pictureURL &&
            !lens.prop('cover').toProps().value.imgUrl
          ) {
            await projectListService.deleteProjectCoverImg(project.id);
          }
          await projectManagementService.updateProject(
            lens.prop('id').toProps().value,
            formData
          );
        }
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([QUERY_KEYS.USERS.PROJECT_COUNT]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            lens.prop('id').toProps().value,
          ]),
        ]);
        return Promise.resolve({ form: projectFormData });
      } catch (error: any) {
        const validationMessage = handleValidationMessage(error, projectData);

        if (validationMessage) {
          return Promise.resolve({
            form: projectFormData,
            validation: {
              isInvalid: true,
              validationProps: {
                projectMetadata: {
                  isInvalid: true,
                  validationProps: {
                    name: {
                      isInvalid: true,
                      validationMessage,
                    },
                  },
                },
              },
            },
          });
        }
        return Promise.reject(error);
      } finally {
        useCloseBlocker(svc);
        setFormSaveInProgress(false);
        triggerAutoScroll((prevState) => !prevState);
      }
    },
    onSuccess: (project) => {
      modalProps.success(true);
      svc.uuiNotifications
        .show(
          (props: INotification) => (
            <SuccessNotification {...props}>
              <Text>
                {`You have successfully ${
                  isNewProject ? 'created' : 'updated'
                } the project "${project.projectMetadata.name}".`}
              </Text>
            </SuccessNotification>
          ),
          { position: 'bot-left', duration: 5 }
        )
        .catch(() => null);
    },
    onError: (errors: validationErrors) => {
      useCloseBlocker(svc);
      setFormSaveInProgress(false);
      showErrorMessages(svc, errors);
    },
    getMetadata: projectDetailsSchema,
  });

  const projectLinks = lens.prop('projectLinks').default([]).get();

  useEffect(() => {
    const ERROR_OFFSET = 20;
    const CONTAINER_OFFSET = 15;

    const validationErrorsList = document.querySelectorAll(
      '.uui-invalid-message'
    );
    if (!validationErrorsList.length) return;

    const containerToScroll = document.querySelector(
      'div[data-scroll-container]'
    )?.firstElementChild;

    if (!containerToScroll) return;

    const containerBoundaries = containerToScroll.getBoundingClientRect();

    const errorBoundaries = Array.from(validationErrorsList)[0]
      .closest('div[data-auto-scroll]')
      ?.getBoundingClientRect();
    if (!errorBoundaries) return;

    let scrollDistance: number;

    if (errorBoundaries.bottom >= containerBoundaries.bottom) {
      scrollDistance =
        errorBoundaries.bottom -
        containerBoundaries.bottom +
        ERROR_OFFSET +
        CONTAINER_OFFSET;
    } else if (errorBoundaries.top <= containerBoundaries.top) {
      scrollDistance = errorBoundaries.top - containerBoundaries.top;
    } else {
      scrollDistance = 0;
    }

    containerToScroll.scrollBy({
      top: scrollDistance,
      left: 0,
      behavior: 'smooth',
    });
  }, [autoScroll]);

  const stepContent: { [key: number]: ReactJSXElement } = {
    1: (
      <Panel margin={'24'}>
        <FlexRow spacing={'18'} alignItems={'top'}>
          <ProjectMeta lens={lens.prop('projectMetadata')} />
          <Cover lens={lens.prop('cover')} />
        </FlexRow>
        <Keywords lens={lens.prop('keywords').default([])} />
        <Technologies lens={lens.prop('technologies').default([])} />
        <EventsSelector
          lens={lens.prop('event')}
          editorSwitch={editorSwitch || eventOrganizerSwitch}
          projectMembers={project?.members}
          isNewProject={isNewProject}
        />
        <EndDate
          lens={lens.prop('projectTimestamps')}
          isNewProject={isNewProject}
        />
      </Panel>
    ),
    2: (
      <Panel margin={'24'} cx="artifacts-panel">
        <Text fontSize={'16'} lineHeight={'24'} cx="artifact-caption">
          You can provide optional links and documents related to your project.
        </Text>
        <Artifacts
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          isReachedLinkLimit={isReachedLinkLimit}
          gitlabUrl={gitlabUrl}
        ></Artifacts>
        <Text lineHeight="24" cx="link-Counter-text">
          {currentLinkCount} / {MAX_PROJECT_LINKS}
        </Text>
      </Panel>
    ),
  };

  const totalSteps = () => steps.length;

  const isLastStep = (n = 1) => activeStep === totalSteps() - n;

  const handleNext = () => {
    completeStep();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((currentAciveStep) => currentAciveStep - 1);
  };

  const completeStep = () => {
    const newCompleted = completed;
    newCompleted[activeStep + 1] = true;
    setCompleted(newCompleted);
  };

  useEffect(() => {
    (async () => {
      const linksDictionary: LinkDictionary =
        await projectManagementService.getProjectLinks();
      setDictionary(linksDictionary.items);
    })();
  }, []);

  useEffect(() => {
    setCurrentLinkCount(projectLinks.length + 1); // add + 1 because 'Code in Gitlab' link is not part of the user's projectLinks but is the part of users info
  }, [projectLinks]);

  return (
    <ModalBlocker
      {...modalProps}
      abort={() =>
        isOtherModalActive ? modalProps.abort : showLeaveConfirmation()
      }
    >
      <ModalWindow cx="modal-container">
        <ModalHeader
          title={`${isNewProject ? 'Create' : 'Edit'} project`}
          borderBottom={true}
          onClose={showLeaveConfirmation}
          cx={'modal-container__header'}
        />

        <StepperController
          activeStep={activeStep}
          steps={steps}
          stepClick={setActiveStep}
          stepsCompleted={completed}
          enableAllSteps
        />

        <ScrollBars rawProps={{ 'data-scroll-container': '' } as any}>
          {stepContent[activeStep + 1]}
        </ScrollBars>
        <ModalFooter borderTop>
          <FlexSpacer />
          {isLastStep(2) && (
            <Button
              caption={'Back'}
              fill="none"
              color="gray50"
              onClick={handlePrevious}
            />
          )}
          {isLastStep(2) ? (
            <Button
              caption={`${isNewProject ? 'Create project' : 'Save'}`}
              color="green"
              isDisabled={formSaveInProgress}
              onClick={() => {
                validate();
                if (lens.toProps().isInvalid) {
                  errorNotification('Validation Errors');
                  if (lens.prop('projectLinks').toProps().isInvalid) {
                    setActiveStep(1);
                  } else {
                    setActiveStep(0);
                  }
                  triggerAutoScroll((prevState) => !prevState);
                } else {
                  save();
                }
              }}
            />
          ) : (
            <Button
              caption={`Next`}
              color="blue"
              onClick={() => {
                triggerAutoScroll((prevState) => !prevState);
                handleNext();
              }}
            />
          )}
        </ModalFooter>
      </ModalWindow>
    </ModalBlocker>
  );
}

export { UpdateProject };
