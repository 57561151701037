import {
  ErrorNotification,
  SuccessNotification,
  Text,
  WarningNotification,
} from '@epam/promo';
import { useUuiContext } from '@epam/uui';

const useNotificationsModal = () => {
  const { uuiModals, uuiNotifications } = useUuiContext();

  const successNotification = (message: string) => {
    uuiNotifications
      .show((props) => (
        <SuccessNotification {...props}>
          <Text>{message}</Text>
        </SuccessNotification>
      ))
      .catch(() => null);
  };

  const errorNotification = (message: string) => {
    uuiNotifications
      .show((props) => (
        <ErrorNotification {...props}>
          <Text>{message}</Text>
        </ErrorNotification>
      ))
      .catch(() => null);
  };

  const warningNotification = (message: string) => {
    uuiNotifications
      .show((props) => (
        <WarningNotification {...props}>
          <Text>{message}</Text>
        </WarningNotification>
      ))
      .catch(() => null);
  };

  return { successNotification, errorNotification, warningNotification };
};

export default useNotificationsModal;
