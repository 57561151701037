import { USER_ROLES } from '../constants/uiConstants';
import { UserInfo, RoleType } from '../services/api/user/userInfo';

export const isCanAccessPermissionsPage = (roles: RoleType[]) =>
  roles.includes(USER_ROLES.ADMIN) ||
  roles.includes(USER_ROLES.SUPER_ADMIN) ||
  roles.includes(USER_ROLES.EDITOR);

export const isUserWithEditorRole = (userInfo: UserInfo | any) =>
  userInfo?.roles.includes(USER_ROLES.EDITOR);

export const isUserWithEventOrganizerRole = (userInfo: UserInfo | any) =>
  userInfo?.roles.includes(USER_ROLES.EVENT_ORGANIZER);
