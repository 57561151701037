import React, { FC, useContext, useState } from 'react';
import { PageHeader } from '../../component/page-header/page-header';
import { Button, Paginator, Spinner } from '@epam/promo';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-12.svg';
import { useUserInfo } from '../../services/auth-client/user-info-provider';
import { EVENTS_PAGE_SIZE, HEADER_STYLES } from '../../constants/uiConstants';
import EmptyPanel from '../project-list/components/empty-panel';
import './events-route.scss';
import { IModal, useUuiContext } from '@epam/uui';
import { ManageEventsModal } from './manage/manage-events-modal';
import { EventList } from './models/interfaces';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { useCloseBlocker } from '../../hooks/useCloseBlocker';
import { DefaultError } from '../../component/notification/DefaultError';
import EventCard from './components/EventCard';
import eventManagementService from '../../services/api/event-managment/eventManagementService';
import useNotificationsModal from 'component/NotifiactionsModal';

import { EditorSwitchContext, EventOrganizerSwitchContext } from 'App';

const EventsRoute: FC = () => {
  const { userInfo } = useUserInfo();
  const svc = useUuiContext();
  const { successNotification } = useNotificationsModal();
  const eventOrganizerSwitch = useContext(EventOrganizerSwitchContext);
  const editorSwitch = useContext(EditorSwitchContext);

  const [page, setPage] = useState(1);

  const { data } = useQuery<EventList>(
    [QUERY_KEYS.EVENTS.EVENTS_LIST, page],
    () =>
      eventManagementService.getEvents(
        EVENTS_PAGE_SIZE,
        (page - 1) * EVENTS_PAGE_SIZE,
        ''
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!userInfo,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const events = data?.challengeList;

  return (
    <div className="events-page">
      <PageHeader
        title="Explore engineering initiatives"
        background={HEADER_STYLES.COMMON.background}
        color={HEADER_STYLES.COMMON.color}
        actionBtn={
          (eventOrganizerSwitch || editorSwitch) && (
            <Button
              size="36"
              color="blue"
              icon={addIcon}
              caption="Add Event"
              cx="add-event-button"
              fill="white"
              onClick={() => {
                svc.uuiModals
                  .show((confirmModalProps: IModal<string>) => (
                    <ManageEventsModal modalProps={confirmModalProps} />
                  ))
                  .then((val) =>
                    successNotification(
                      `You have successfully created the event "${val}".`
                    )
                  )
                  .catch(() => null);
              }}
            />
          )
        }
      />

      <div className="event-container">
        {!events ? (
          <div className="event-spinner">
            <Spinner color="blue" />
          </div>
        ) : events.length > 0 ? (
          <div className="event-div">
            <div className="event-div__list">
              <div className="event-list">
                {events.map((eventProps) => (
                  <EventCard
                    key={eventProps.id}
                    editorSwitch={editorSwitch || eventOrganizerSwitch}
                    {...eventProps}
                  />
                ))}
              </div>
              <div className="paginator-container">
                <Paginator
                  size="30"
                  totalPages={
                    data ? Math.ceil(data.totalNumber / EVENTS_PAGE_SIZE) : 1
                  }
                  value={page}
                  onValueChange={(value) => setPage(value)}
                />
              </div>
            </div>
          </div>
        ) : (
          <EmptyPanel />
        )}
      </div>
    </div>
  );
};

export default EventsRoute;
