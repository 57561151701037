import {
  validProjectName,
  emailRegExp,
  validURLRegExp,
} from 'constants/regexpConstants';
import { MAX_TECHNOLOGIES } from 'constants/uiConstants';
import { IProjectLink } from 'modules/project-management/models/interfaces';
import { LINK_TYPES } from '../../project-links/constants';
import { getLinkByType } from '../../add-to-showroom/helpers/utils';

const { GIT_OTHER, GIT_GITHUB, EMAIL, CASE_STUDY, SLIDE_DECK, DOCS_DECKS } =
  LINK_TYPES;

const requiredLinksTypes = [DOCS_DECKS];

export const EDIT_SHOWROOM_VALIDATION_ERRORS = Object.freeze({
  FIELD_IS_REQUIRED: 'This field is required.',
  CHARACTER_LIMIT: 'The character limit is exceeded.',
  PROJECT_NAME_INVALID:
    'Project name can contain only letters (from English alphabet), digits, dot, dash, space. The last character must be a letter or a digit.',
  TECHNOLOGIES_LIMIT: (limit: number) =>
    `You can select up to ${limit} technologies.`,
  ATTACH_YOUR_FILE: 'Please attach your file',
  INVALID_EPAM_GITLAB_LINK:
    'Showroom request contains invalid EPAM GitLab link.',
  NO_ACTIVITY_IN_THE_REPOSITORY:
    'No activity in the repository. Please add another source.',
  WRONG_EMAIL_FORMAT:
    'Email should correspond to the format i.e. sample@domain.com.',
  LINK_FORMAT_INVALID:
    'Link does not have a proper URL pattern i.e. http://example.com',
});

export const editShowroomSchema = (info: any): any => {
  const githubLink = getLinkByType(info.projectLinks, GIT_GITHUB)?.link;

  return {
    props: {
      projectMetadata: {
        props: {
          name: {
            isRequired: true,
            validators: [
              (val: any) => [
                !val && EDIT_SHOWROOM_VALIDATION_ERRORS.FIELD_IS_REQUIRED,
                val?.length > 40 &&
                  EDIT_SHOWROOM_VALIDATION_ERRORS.CHARACTER_LIMIT,
                !validProjectName.test(val) &&
                  EDIT_SHOWROOM_VALIDATION_ERRORS.PROJECT_NAME_INVALID,
              ],
            ],
          },
          description: {
            isRequired: true,
            validators: [
              (val: any) => [
                !val && EDIT_SHOWROOM_VALIDATION_ERRORS.FIELD_IS_REQUIRED,
                val?.length > 512 &&
                  EDIT_SHOWROOM_VALIDATION_ERRORS.CHARACTER_LIMIT,
              ],
            ],
          },
        },
      },
      keywords: {
        isRequired: true,
        validators: [
          (val: any) => [
            !val?.length && EDIT_SHOWROOM_VALIDATION_ERRORS.FIELD_IS_REQUIRED,
          ],
        ],
      },
      technologies: {
        validators: [
          (val: any) => [
            val?.length > MAX_TECHNOLOGIES &&
              EDIT_SHOWROOM_VALIDATION_ERRORS.TECHNOLOGIES_LIMIT(
                MAX_TECHNOLOGIES
              ),
          ],
        ],
      },
      projectTimestamps: {
        props: {
          plannedEndDate: {
            isRequired: true,
            validators: [
              (val: any) => [
                !val && EDIT_SHOWROOM_VALIDATION_ERRORS.FIELD_IS_REQUIRED,
              ],
            ],
          },
        },
      },
      GIT_REPO: {
        isRequired: true,
        isDisabled: true,
        validators: [
          () => {
            if (!info.userHasActiveRepository && !githubLink) {
              return [
                EDIT_SHOWROOM_VALIDATION_ERRORS.NO_ACTIVITY_IN_THE_REPOSITORY,
              ];
            }
            return [false];
          },
        ],
      },
      projectLinks: {
        all: {
          props: {
            link: {
              validators: [
                (value: string, parentValues: IProjectLink) => {
                  if (
                    !value &&
                    requiredLinksTypes.includes(parentValues.linkType!)
                  ) {
                    return [EDIT_SHOWROOM_VALIDATION_ERRORS.FIELD_IS_REQUIRED];
                  }
                  if (
                    value &&
                    parentValues.linkType !== EMAIL &&
                    parentValues.linkType !== SLIDE_DECK &&
                    parentValues.linkType !== CASE_STUDY &&
                    !validURLRegExp.test(value)
                  ) {
                    return [
                      EDIT_SHOWROOM_VALIDATION_ERRORS.LINK_FORMAT_INVALID,
                    ];
                  }
                  if (
                    value &&
                    parentValues.linkType === EMAIL &&
                    !emailRegExp.test(value)
                  ) {
                    return [EDIT_SHOWROOM_VALIDATION_ERRORS.WRONG_EMAIL_FORMAT];
                  }
                  if (
                    !parentValues.file &&
                    parentValues?.linkType === SLIDE_DECK
                  ) {
                    return [EDIT_SHOWROOM_VALIDATION_ERRORS.ATTACH_YOUR_FILE];
                  }
                  if (parentValues?.linkType === GIT_OTHER) {
                    if (info.BE_ERROR_MESSAGE && !githubLink) {
                      return [
                        EDIT_SHOWROOM_VALIDATION_ERRORS.INVALID_EPAM_GITLAB_LINK,
                      ];
                    }
                    if (!info.userHasActiveRepository && !githubLink) {
                      return [
                        EDIT_SHOWROOM_VALIDATION_ERRORS.NO_ACTIVITY_IN_THE_REPOSITORY,
                      ];
                    }
                  }
                  return [false];
                },
              ],
            },
          },
        },
      },
    },
  };
};
