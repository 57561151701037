import React from 'react';
import useIsOverflow from '../services/overflow';
import { Text, TextMods, Tooltip } from '@epam/promo';
import { IHasCX } from '@epam/uui';

interface Props extends TextMods, IHasCX {
  text: string;
}

const OverflowTooltip = ({ text, ...props }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(ref);

  const textElem = (
    <Text {...props} ref={ref}>
      {text}
    </Text>
  );

  return (
    <>
      {(isOverflow && (
        <Tooltip placement="bottom" content={text}>
          {textElem}
        </Tooltip>
      )) ||
        textElem}
    </>
  );
};

export default OverflowTooltip;
