import React, { useState } from 'react';
import Applicant from '../applicant';
import './styles.scss';
import { IApplicant } from 'modules/list-opportunities/models/model';
import MemberService from 'services/api/member/memberService';
import ApplicantCustomDialog from '../applicant-custom-dialog';

interface ApplicantsListProps {
  applicants: IApplicant[];
  opportunityName: string;
  projectName: string;
  onAccept: (requestId: number, closeOpportunity: boolean) => void;
  onReject: (requestId: number) => void;
}

const ApplicantsList = ({
  applicants,
  opportunityName,
  projectName,
  onAccept,
  onReject,
}: ApplicantsListProps) => {
  const [openedApplicantId, setOpenedApplicantId] = useState<number | null>(
    null
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<IApplicant | null>(
    null
  );
  const [actionType, setActionType] = useState<'accept' | 'reject'>('accept');

  const openDialog = (
    applicant: IApplicant,
    actionType: 'accept' | 'reject'
  ) => {
    setSelectedApplicant(applicant);
    setActionType(actionType);
    setIsDialogOpen(true);

    if (applicant.membershipRequestId === openedApplicantId) {
      setOpenedApplicantId(null);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedApplicant(null);
    setActionType('accept');
  };

  const acceptApplicant = async (
    requestId: number,
    comment: string,
    closeOpportunity: boolean
  ) => {
    try {
      await MemberService.acceptMembershipRequest(requestId, comment);

      if (closeOpportunity) {
        for (const currApplicant of applicants) {
          if (currApplicant.membershipRequestId === requestId) {
            continue;
          }

          try {
            await MemberService.declineMembershipRequest(
              currApplicant.membershipRequestId,
              'OPPORTUNITY_CLOSED'
            );
          } catch (error) {
            console.error('Error rejecting applicant:', error);
          }
        }
      }

      onAccept(requestId, closeOpportunity);
    } catch (error) {
      console.error(error);
    }
  };

  const rejectApplicant = async (requestId: number, comment: string) => {
    try {
      await MemberService.declineMembershipRequest(requestId, comment);
      onReject(requestId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="applicants-list-container">
      <span className="applicant-title">Applicants</span>
      {applicants
        .sort((a, b) => b.membershipRequestId - a.membershipRequestId)
        .map((applicant) => (
          <Applicant
            key={applicant.membershipRequestId}
            membershipRequestId={applicant.membershipRequestId}
            user={applicant.user}
            position={opportunityName}
            onAccept={() => openDialog(applicant, 'accept')}
            onReject={() => openDialog(applicant, 'reject')}
            openDetails={openedApplicantId === applicant.membershipRequestId}
            onToggleDetails={(open) =>
              setOpenedApplicantId(open ? applicant.membershipRequestId : null)
            }
          />
        ))}
      {isDialogOpen && selectedApplicant && (
        <ApplicantCustomDialog
          isOpen={isDialogOpen}
          onClose={closeDialog}
          applicant={selectedApplicant}
          onAccept={acceptApplicant}
          onReject={rejectApplicant}
          actionType={actionType}
          position={opportunityName}
          projectName={projectName}
        />
      )}
    </div>
  );
};

export default ApplicantsList;
