import { MEMBER_API } from 'constants/apiConstants';
import { IMembershipRequestPayload } from 'modules/project-list/models/interfaces';
import { BaseService } from '../baseService';

class MemberService extends BaseService {
  private static instance: MemberService;

  public static getInstance(): MemberService {
    if (!MemberService.instance) {
      MemberService.instance = new MemberService();
    }
    return MemberService.instance;
  }

  public getMembershipRequest(requestID: number) {
    return MemberService.instance.get<IMembershipRequestPayload>(
      MEMBER_API.GetMembershipRequest(requestID)
    );
  }

  public acceptMembershipRequest(requestID: number, comment?: string) {
    return MemberService.instance.put(
      MEMBER_API.AcceptMembershipRequest(requestID, comment)
    );
  }

  public declineMembershipRequest(requestID: number, comment?: string) {
    return MemberService.instance.put(
      MEMBER_API.DeclineMembershipRequest(requestID, comment)
    );
  }

  public revokeMembershipRequest(requestID: number) {
    return MemberService.instance.get(
      MEMBER_API.RevokeMembershipRequest(requestID)
    );
  }

  public addProjectMember(
    projectId: string | number,
    externalUserId: string,
    position: string
  ) {
    return MemberService.instance.post(MEMBER_API.AddProjectMember(projectId), {
      externalUserId,
      position,
      role: 'CONTRIBUTOR',
    });
  }

  public editProjectMember(
    projectId: string | number,
    externalUserId: string,
    position: string
  ) {
    return MemberService.instance.put(
      MEMBER_API.EditProjectMember(projectId, externalUserId),
      {
        position,
      }
    );
  }

  public deleteProjectMember(
    projectId: string | number,
    externalUserId: string
  ) {
    return MemberService.instance.delete(
      MEMBER_API.DeleteProjectMember(projectId, externalUserId)
    );
  }
}

export default MemberService.getInstance();
