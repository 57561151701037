import React, { FC, useRef, useState } from 'react';
import { DataColumnProps, useArrayDataSource, useUuiContext } from '@epam/uui';
import noDataInTable from 'assets/svg/no-data-in-table.svg';
import {
  DataTable,
  FlexRow,
  Paginator,
  Panel,
  TextInput,
  TextPlaceholder,
} from '@epam/promo';
import { useQuery } from '@tanstack/react-query';
import './custom-data-table.scss';
import { ReactComponent as searchIcon } from '@epam/assets/icons/common/action-search-12.svg';
import useDebounce from '../hooks/use-debounce';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

interface Props<TItem> {
  queryKey: string;
  fetch: (data: {
    page: number;
    keyword?: string;
  }) => Promise<{ total: number; data: TItem[] }>;
  columns: DataColumnProps<TItem>[];
  filter?: any;
  itemPerPage: number;
  allowSearch?: boolean;
  placeholder?: string;
}

export const CustomDataTable = <TItem,>({
  queryKey,
  fetch,
  columns,
  filter = {},
  itemPerPage,
  allowSearch,
  placeholder,
}: Props<TItem>) => {
  const svc = useUuiContext();

  const [value, onValueChange] = useState({});
  const [keyword, setKeyword] = useState<string | undefined>('');
  const [localFilter, setLocalFilter] = useState<{
    page: number;
    keyword: string | undefined;
  }>({ page: 1, keyword: '' });

  useDebounce<string | undefined>(keyword, 400, (newValue) => {
    setLocalFilter({ page: 1, keyword: newValue });
  });

  const { data } = useQuery(
    [queryKey, { ...filter, ...localFilter }],
    () => fetch(localFilter),
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const totalItemRef = useRef(0);
  if (data) {
    totalItemRef.current = data.total;
  }

  const dataSource = useArrayDataSource<
    TItem | { loading: true },
    number,
    unknown
  >(
    {
      items: data?.data || [],
    },
    [data]
  );

  const view = dataSource.useView(value, onValueChange, {});

  return (
    <div className="custom-data-table">
      {allowSearch && (
        <TextInput
          cx="search-input"
          icon={searchIcon}
          value={keyword}
          onValueChange={(newValue) => setKeyword(newValue)}
          placeholder={placeholder}
        />
      )}

      <Panel shadow>
        {!data ? (
          <DataTablePlaceholder itemPerPage={itemPerPage} columns={columns} />
        ) : (
          <>
            <DataTable
              {...view.getListProps()}
              getRows={view.getVisibleRows}
              value={value}
              onValueChange={onValueChange}
              columns={columns}
              headerTextCase="upper"
              renderNoResultsBlock={() => <></>}
            />
            {data.total == 0 && (
              <Panel cx="no-data-panel" background="white">
                <img
                  src={noDataInTable}
                  alt={'no data to display'}
                  className={'img'}
                />
                <h2 className={'header'}>No results found</h2>
                <div className="no-result-message">
                  We can’t find any item matching your request
                </div>
              </Panel>
            )}
          </>
        )}

        {data && data.total > 0 && (
          <FlexRow size="36" padding="12" cx="pagination" background="white">
            <Paginator
              value={localFilter.page}
              onValueChange={(newPage) => {
                setLocalFilter({ ...localFilter, page: newPage });
              }}
              totalPages={Math.ceil(totalItemRef.current / itemPerPage)}
              size="24"
            />
          </FlexRow>
        )}
      </Panel>
    </div>
  );
};

const DataTablePlaceholder: FC<{
  itemPerPage: number;
  columns: DataColumnProps<any>[];
}> = ({ itemPerPage, columns }) => {
  const [value, onValueChange] = useState({});
  const dataSource = useArrayDataSource<{ loading: true }, number, unknown>(
    {
      items: Array.from({ length: itemPerPage }, (_, i) => ({
        id: i,
        loading: true,
      })),
    },
    []
  );

  const view = dataSource.useView(value, onValueChange, {});

  return (
    <DataTable
      {...view.getListProps()}
      getRows={view.getVisibleRows}
      value={value}
      onValueChange={onValueChange}
      columns={columns.map((column) => ({
        ...column,
        key: `id-${Math.random()}`,
        render: () => (
          <div className="column">
            <TextPlaceholder color="gray40" wordsCount={1} />
          </div>
        ),
      }))}
      headerTextCase="upper"
    />
  );
};
