import { FlexRow, LabeledInput, IconButton, Tooltip } from '@epam/promo';
import { useRef, useEffect, useState } from 'react';
import { Icon } from '@epam/uui';
import './index.scss';
import { ReactComponent as infoIcon } from '@epam/assets/icons/common/notification-info-outline-18.svg';

const LabeledInputWithCustomTooltip = ({
  icon,
  label,
  tooltip,
  className,
}: {
  icon?: Icon;
  label?: string;
  tooltip?: string;
  className?: string;
}) => {
  const [iconOffset, setIconOffset] = useState<number>(0);
  const labelRef = useRef<HTMLElement>();

  useEffect(() => {
    if (labelRef?.current) {
      const labelTextEl = labelRef.current?.querySelector('.uui-label-top');
      const labelTextElWidth = parseInt(getComputedStyle(labelTextEl!).width);
      const labelPaddingLeftOffset = parseInt(
        getComputedStyle(labelRef.current).paddingLeft
      );
      setIconOffset(labelTextElWidth + labelPaddingLeftOffset);
    }
  }, [label]);

  return (
    <FlexRow cx={'label-container'}>
      <LabeledInput label={label} cx={className} ref={labelRef} />
      {tooltip && (
        <Tooltip
          closeOnMouseLeave={'boundary'}
          renderContent={() => (
            <div
              className={'label-tooltip__text'}
              dangerouslySetInnerHTML={{ __html: tooltip }}
            />
          )}
        >
          <IconButton
            icon={icon ? icon : infoIcon}
            cx={'label-tooltip__icon'}
            rawProps={{
              style: {
                left: `${iconOffset}px`,
              },
            }}
          />
        </Tooltip>
      )}
    </FlexRow>
  );
};

export default LabeledInputWithCustomTooltip;
