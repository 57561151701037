import { DICT_API } from 'constants/apiConstants';
import {
  DictionaryModel,
  IProjectMemberPosition,
} from 'modules/project-list/models/interfaces';
import { BaseService } from '../baseService';

class DictionaryService extends BaseService {
  private static instance: DictionaryService;

  public static getInstance(): DictionaryService {
    if (!DictionaryService.instance) {
      DictionaryService.instance = new DictionaryService();
    }
    return DictionaryService.instance;
  }

  public getDictionary(dictionary: string) {
    return DictionaryService.instance.get<
      DictionaryModel<IProjectMemberPosition>
    >(DICT_API.GetDictionary(dictionary));
  }
}

export default DictionaryService.getInstance();
