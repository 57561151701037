import {
  FlexRow,
  FlexCell,
  IconContainer,
  LabeledInput,
  FileCard,
  DropSpot,
  IconButton,
  Panel,
} from '@epam/promo';
import {
  getLinkByType,
  removeLink,
  addLink,
  getLinkProps,
} from '../../modules/project-management/details/components/add-to-showroom/helpers/utils';
import { ILens } from '@epam/uui';
import {
  DROP_SPOT,
  ALLOWED_FILE_TYPES,
} from '../../modules/project-management/details/components/add-to-showroom/constants';
import { IProjectLink } from 'modules/project-management/models/interfaces';
import { ReactComponent as done } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as invalid } from '@epam/assets/icons/common/navigation-close-18.svg';
import { ReactComponent as ClearIcon } from '@epam/assets/icons/common/navigation-close-24.svg';
import useNotifications from 'hooks/useNotifications';
import LabeledInputWithCustomTooltip from 'component/LabeledInputWithCustomTooltip';

const UploadFile = ({
  lens,
  label,
  tooltip,
  className,
  projectLinks,
  targetLinkType,
  isRemovable,
  hideIconContainer,
}: {
  lens: ILens<any>;
  label: string;
  tooltip?: string;
  className: string;
  projectLinks: IProjectLink[];
  targetLinkType: string;
  isRemovable?: boolean;
  hideIconContainer?: boolean;
}) => {
  const { showWarning } = useNotifications();
  const link = getLinkByType(lens, targetLinkType);
  const index = projectLinks.findIndex(
    (projectLink) => projectLink.linkType === targetLinkType
  );
  const crossIcon = isRemovable && (
    <FlexCell alignSelf="center" minWidth={24}>
      <IconButton
        icon={ClearIcon}
        onClick={() => removeLink(lens, index)}
        cx={!!className && `${className}__remove-button`}
      />
    </FlexCell>
  );

  return link ? (
    <Panel rawProps={{ 'data-auto-scroll': '' }}>
      <FlexRow cx="icon-name-container" vPadding="12" alignItems="top">
        {!hideIconContainer && (
          <FlexCell alignSelf="center" minWidth={24}>
            <IconContainer
              icon={link.link ? done : invalid}
              color={link.link ? 'green' : 'red'}
              size={24}
            />
          </FlexCell>
        )}
        <FlexCell cx="icon-name-container" alignSelf="center" minWidth={150}>
          <LabeledInputWithCustomTooltip
            label={label}
            tooltip={tooltip}
            className={`${className}
            ${
              getLinkProps(lens, index).isInvalid
                ? 'showroom-label__invalid-field'
                : ''
            }
          `}
          />
        </FlexCell>
        <FlexCell
          cx="showroom-label__input-block"
          style={{ flexBasis: 'auto' }}
          grow={1}
          shrink={1}
        >
          {link.file ? (
            <FileCard
              file={link.file}
              cx={`uploaded-file ${link.file.size ? '' : 'uploaded-file__old'}`}
              onClick={() => {
                removeLink(lens, projectLinks.indexOf(link));
                addLink(lens, {
                  link: null,
                  linkType: targetLinkType,
                  file: null,
                });
              }}
            />
          ) : (
            <DropSpot
              cx={
                getLinkProps(lens, index).isInvalid
                  ? 'showroom-drop-spot__invalid-field uui-invalid'
                  : ''
              }
              onUploadFiles={(files) => {
                if (files[0].size / 1024 / 1024 > 50) {
                  showWarning(DROP_SPOT.FILE_SIZE_EXCEEDED_TEXT);
                } else if (ALLOWED_FILE_TYPES.includes(files[0].type)) {
                  removeLink(lens, projectLinks.indexOf(link));
                  addLink(lens, {
                    link: files[0],
                    linkType: targetLinkType,
                    file: files[0],
                  });
                } else {
                  showWarning(DROP_SPOT.WRONG_FILE_TYPE_TEXT);
                }
              }}
              infoText={DROP_SPOT.INFO_TEXT}
            />
          )}
        </FlexCell>
        {crossIcon}
      </FlexRow>
      <FlexRow cx="error-msg-section">
        <LabeledInput {...getLinkProps(lens, index)} />
      </FlexRow>
    </Panel>
  ) : (
    <></>
  );
};

export default UploadFile;
