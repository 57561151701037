import { addToShowroomDefaultLinkTypes } from '../constants';
import { UPLOAD_TYPE_LINKS } from '../../project-links/constants';
import { IProjectLink } from 'modules/project-management/models/interfaces';
import { ILens } from '@epam/uui';
import { LINK_TYPES } from '../../project-links/constants';
export const PROJECT_LINKS = 'projectLinks';
const LINK = 'link';
const {
  GIT_REPO,
  GIT_OTHER,
  GIT_GITHUB,
  DOCS_DECKS,
  SLIDE_DECK,
  CASE_STUDY,
  PROJECT_INFO_OTHER,
  HOME_SITE,
  TEST_ENVIRONMENT,
  ENVIRONMENT_OTHER,
  PROMO_SOLUTION_HUB,
  PROMO_PRESALES_PORTAL,
  SHOWCASE,
  CHALLENGE_SOLVED,
  VIDEO_LINK,
  VIDEO_PROMO,
  VIDEO_WALKTHROUGH,
  VIDEO_OTHER,
  TEAMS_CHANNEL,
  EMAIL,
} = LINK_TYPES;

export const requiredArtifactsFormLinksTypes = [
  GIT_REPO,
  GIT_OTHER,
  GIT_GITHUB,
  DOCS_DECKS,
  SLIDE_DECK,
  CASE_STUDY,
  PROJECT_INFO_OTHER,
];

export const optionalArtifactsFormLinksTypes = [
  GIT_REPO,
  HOME_SITE,
  TEST_ENVIRONMENT,
  ENVIRONMENT_OTHER,
  PROMO_SOLUTION_HUB,
  PROMO_PRESALES_PORTAL,
  SHOWCASE,
  CHALLENGE_SOLVED,
  VIDEO_LINK,
  VIDEO_PROMO,
  VIDEO_WALKTHROUGH,
  VIDEO_OTHER,
  TEAMS_CHANNEL,
  EMAIL,
];

export const getFileNameFromProjectLink = (link: string): string => {
  const chunksAfterSplit = link.split('___');
  const lastChunksWithFileName = chunksAfterSplit[chunksAfterSplit.length - 1];
  const splitFileNameFromQueryParameter = lastChunksWithFileName.split('?');
  const fileName = splitFileNameFromQueryParameter[0];
  return fileName;
};

export const joinUsersLinksWithMissingDefaultLinksFromTheForm = (
  links: IProjectLink[]
) => {
  const missingLinks: object[] = [];
  addToShowroomDefaultLinkTypes.map((linkType: string) => {
    const isLinkUserAlreadyHas = links.find(
      (link: any) => link.linkType === linkType
    );
    if (isLinkUserAlreadyHas) {
      return;
    }
    missingLinks.push({
      link: '',
      linkType: linkType,
    });
  });
  const defaultFormLinks = [...links, ...missingLinks].map((link: any) => {
    if (UPLOAD_TYPE_LINKS.includes(link.linkType) && !!link.link) {
      link.file = { name: getFileNameFromProjectLink(link.link) };
    }
    if (link.linkType === EMAIL) {
      const emailPart = link.link?.split(':')[1];
      link = { id: link.id, link: emailPart, linkType: link.linkType };
    }
    return link;
  });
  return defaultFormLinks as IProjectLink[];
};

export const getLinkByType = (
  lens: ILens<any> | IProjectLink[],
  targetType: string
) => {
  if (!Array.isArray(lens)) {
    return lens
      .prop(PROJECT_LINKS)
      .get()
      .find((link: IProjectLink) => link.linkType === targetType);
  }

  return lens.find((link: IProjectLink) => link.linkType === targetType);
};

export const getAllLinksByType = (lens: ILens<any>, targetType: string) =>
  lens
    .prop(PROJECT_LINKS)
    .get()
    .filter((link: IProjectLink) => link.linkType === targetType);

export const getLinksByTypes = (lens: ILens<any>, targetTypes: string[]) =>
  lens
    .prop(PROJECT_LINKS)
    .get()
    .filter(
      (link: IProjectLink) =>
        link.linkType && targetTypes.includes(link.linkType)
    );

export const getLinksWithProps = (lens: any, targetTypes: string[]) => {
  const result: object[] = [];
  lens.prop(PROJECT_LINKS).cache.forEach((link: ILens<any>) => {
    if (targetTypes.includes(link.get()?.linkType)) {
      result.push(link);
    }
  });
  return result;
};

export const getLinkProps = (lens: ILens<any> | any, index: number) =>
  lens.prop(PROJECT_LINKS).index(index).prop(LINK).toProps();

export const addLink = (lens: ILens<any>, item: IProjectLink | any) =>
  lens.prop(PROJECT_LINKS).set(lens.prop(PROJECT_LINKS).get().concat(item));

export const removeLink = (lens: ILens<any>, targetIndex: number) =>
  lens.prop(PROJECT_LINKS).set(
    lens
      .prop(PROJECT_LINKS)
      .get()
      .filter((_: IProjectLink, index: number) => index !== targetIndex)
  );

export const checkUploadLinks = (links: IProjectLink[]) => {
  const formattedLinks = links.map((link: IProjectLink) => {
    if (
      link.linkType !== null &&
      UPLOAD_TYPE_LINKS.includes(link.linkType) &&
      !!link.link
    ) {
      link.file = { name: getFileNameFromProjectLink(link.link) };
    }
    if (link.linkType === EMAIL) {
      const emailPart = link.link?.split(':')[1];
      link = { id: link.id, link: emailPart, linkType: link.linkType };
    }
    return link;
  });
  return formattedLinks;
};
