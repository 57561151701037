import { IProjectCard } from '../../../../project-list/components/project-card/modal';
import { ProjectStatus as EnumProjectStatus } from '../../../../../models/enums';
import { Button, FlexRow, Text } from '@epam/promo';
import AddOpportunities from './index';
import CurrentOpportunities from '../current-opportunities';
import React, { useState } from 'react';

export function ProjectDetailsOpportunities({
  projectCard,
  userHasEditPermission,
  canModifyApplicants,
}: {
  projectCard: IProjectCard;
  userHasEditPermission: boolean;
  canModifyApplicants: boolean;
}) {
  const {
    status: projectStatus,
    opportunities: projectOpportunities,
    info,
  } = projectCard;

  const opportunityVisible =
    projectStatus !== EnumProjectStatus.SUSPENDED &&
    projectStatus !== EnumProjectStatus.CLOSED;

  const [addOpportunityVisible, setAddOpportunityVisible] = useState(false);

  return (
    <>
      {opportunityVisible && (
        <FlexRow
          cx={
            projectOpportunities.length > 0 || userHasEditPermission
              ? 'project-detail-right__category'
              : 'project-detail-right__hide-category'
          }
        >
          <Text
            cx={'section-title'}
            fontSize="18"
            lineHeight="24"
            font="museo-sans"
          >
            Opportunities
          </Text>
          {userHasEditPermission && !addOpportunityVisible && (
            <Button
              fill="white"
              color="blue"
              caption="+ Add"
              size={'24'}
              onClick={() => {
                setAddOpportunityVisible(true);
              }}
              cx="project-detail-add-button"
            />
          )}
        </FlexRow>
      )}
      {addOpportunityVisible && opportunityVisible && (
        <AddOpportunities
          projectId={parseInt(info.id)}
          setAddOpportunityVisible={setAddOpportunityVisible}
        />
      )}
      <CurrentOpportunities
        opportunities={projectOpportunities}
        canModifyApplicants={canModifyApplicants}
        projectId={parseInt(info.id)}
        userHasEditPermission={userHasEditPermission}
        projectName={info.name}
      />
    </>
  );
}
