import Avatar from '../../../../../component/Avatar';
import { FlexCell, FlexRow, IconButton, Text, Tooltip } from '@epam/promo';
import ProjectStatus from './project-status';
import {
  ProjectShowroomStatus,
  ProjectStatus as EnumProjectStatus,
} from '../../../../../models/enums';
import { IProjectCard } from '../../../../project-list/components/project-card/modal';
import ProjectLinks from '../project-links';
import { ReactComponent as showroomIcon } from 'assets/svg/show-room.svg';
import projectListService from 'services/api/project-list/projectListService';
import { dateFormatter } from '../../../../../utils/dateUtils';
import { ProjectTags } from '../project-tags';
import { ProjectDescription } from '../project-description';
import OverflowTooltip from '../../../../../utils/OverflowTooltip';
import ProjectLinkedEvent from '../project-linked-event';
import { AddToShowroomProps } from '../../../details/components/add-to-showroom/models/interfaces';

const STATUSES = [
  EnumProjectStatus.INITIAL,
  EnumProjectStatus.ACTIVE,
  EnumProjectStatus.CLOSED,
  EnumProjectStatus.SUSPENDED,
  EnumProjectStatus.DELETE,
];

function ProjectDetailsLeftInfo({
  info,
  links,
  project,
  userHasEditPermission,
  projectHasActiveShowroomRequest,
  onCloseParent,
  canBeMarkedForDeletion,
  isShowroom,
}: {
  project: IProjectCard;
  userHasEditPermission: boolean;
  projectHasActiveShowroomRequest: boolean;
  onCloseParent: () => void;
  canBeMarkedForDeletion: boolean;
  info: AddToShowroomProps['info'];
  links: AddToShowroomProps['links'];
  isShowroom: undefined | boolean;
  projectStatus: string;
}) {
  const {
    previewURL,
    status: projectStatus,
    showroom: statusShowroom,
    time,
    projectLinks,
    challenge,
  } = project;

  const usefulLinks = [
    { id: -1, link: info.labUrl, linkType: 'GIT_REPO' },
    ...projectLinks,
  ];

  return (
    <>
      <div className="project-detail-left__info">
        <Avatar
          img={projectListService.getProfileProjectPicture(previewURL)}
          alt="Project Cover"
          cx="project-detail-left__info__image"
          size={'144'}
          initials={info.name.charAt(0)}
        />
        <div className="project-detail-left__info__body">
          <FlexRow>
            <OverflowTooltip
              text={info.name}
              cx="project-detail__name"
              font="museo-sans"
            />
          </FlexRow>
          <div>
            <FlexRow cx="project-detail-statuses-container">
              <ProjectStatus
                project={project}
                projectStatus={projectStatus}
                options={STATUSES}
                id={info.id}
                userHasEditPermissions={userHasEditPermission}
                isProjectHasActiveShowroomRequest={
                  projectHasActiveShowroomRequest
                }
                closeParentModal={onCloseParent}
                canBeMarkedForDeletion={canBeMarkedForDeletion}
                isShowroom={isShowroom}
                info={info}
                links={links}
              />
              {statusShowroom.status == ProjectShowroomStatus.IN_SHOWROOM && (
                <Tooltip
                  content={`In Showroom since ${dateFormatter(
                    statusShowroom?.lastDateUpdateShowroomStatus
                  )}`}
                >
                  <FlexRow cx="project-detail-showroom-icon-container">
                    <IconButton icon={showroomIcon} color="amber" />
                    <Text
                      cx="project-detail-showroom-text"
                      lineHeight="18"
                      font="sans"
                      fontSize="14"
                    >
                      in Showroom
                    </Text>
                  </FlexRow>
                </Tooltip>
              )}
            </FlexRow>
            <FlexCell width="auto" cx="project-time">
              <FlexRow cx="project-time__item">
                <Text cx="project-time__title time-text">Last update:</Text>

                <Text cx="project-time__value time-text">
                  {dateFormatter(time.lastUpdate)}
                </Text>
              </FlexRow>
              <FlexRow cx="project-time__item">
                <Text cx="project-time__title time-text">Duration:</Text>

                <Text cx="project-time__value time-text">
                  {`${dateFormatter(time.plannedStartDate)} - ${dateFormatter(
                    time.plannedEndDate
                  )}`}
                </Text>
              </FlexRow>
            </FlexCell>
          </div>
        </div>
      </div>
      <ProjectTags keywords={info.keywords} technologies={info.technologies} />
      {info.description && info.description.length > 0 && (
        <ProjectDescription description={info.description} />
      )}
      {usefulLinks.length > 0 && <ProjectLinks links={usefulLinks} />}
      {!!challenge && (
        <ProjectLinkedEvent
          challengeId={challenge.challengeId}
          challengeLink={challenge.challengeLink}
          challengeName={challenge.challengeName}
        />
      )}
    </>
  );
}

export default ProjectDetailsLeftInfo;
