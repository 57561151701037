import { Checkbox, Tooltip } from '@epam/promo';
import { FC } from 'react';
import { RoleType, UserInfo } from '../../../services/api/user/userInfo';
import './garage-users-table.scss';
import { cx, useUuiContext } from '@epam/uui';
import { anOrA, upperCaseFirstLetter } from '../../../utils/stringUtils';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import permissionService from '../../../services/api/permission/permissionService';
import { AlertModal } from '../../../component/AlertModal';
import { useUserInfo } from '../../../services/auth-client/user-info-provider';
import { QUERY_KEYS } from 'constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

type Props = {
  admins?: UserInfo[];
  user: UserInfo;
  roleColumn: RoleType;
  disabled: boolean;
  minimumRole: string;
  customDisableTooltipText?: string;
};

export const ToggleRole: FC<Props> = ({
  admins,
  user,
  roleColumn,
  disabled,
  minimumRole,
  customDisableTooltipText,
}) => {
  const svc = useUuiContext();
  const checked = user.roles.indexOf(roleColumn) > -1;
  const { userInfo, refetch } = useUserInfo();

  const roleName = roleColumn
    .split('_')
    .map((text) => upperCaseFirstLetter(text))
    .join(' ');

  const addRoleMutation = useMutation<any, any, { role: RoleType }>(
    ({ role }) => permissionService.addPermissionToUser(user.id, role),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.USERS.USER_LIST]),
          roleColumn == 'ADMIN'
            ? queryClient.refetchQueries([QUERY_KEYS.USERS.SPECIFIED_USER_ROLE])
            : Promise.resolve(),
          userInfo?.id == user.id ? refetch() : Promise.resolve(),
        ]);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const transferRoleMutation = useMutation<any, any, any>(
    () => permissionService.transferSuperAdminToUser(user.id),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.USERS.USER_LIST]),
          roleColumn == 'ADMIN'
            ? queryClient.refetchQueries([QUERY_KEYS.USERS.SPECIFIED_USER_ROLE])
            : Promise.resolve(),
          refetch(),
        ]);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const removeRoleMutation = useMutation<any, any, { role: RoleType }>(
    ({ role }) => permissionService.removePermissionToUser(user.id, role),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.USERS.USER_LIST]),
          roleColumn == 'ADMIN'
            ? queryClient.refetchQueries([QUERY_KEYS.USERS.SPECIFIED_USER_ROLE])
            : Promise.resolve(),
          userInfo?.id == user.id ? refetch() : Promise.resolve(),
        ]);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const isUpdating =
    addRoleMutation.isLoading ||
    removeRoleMutation.isLoading ||
    transferRoleMutation.isLoading;

  const isDisabledBecauseAdminLessThanTwo =
    admins && admins.length <= 2 && roleColumn == 'ADMIN' && checked;

  const getToolTipText = () => {
    if (isDisabledBecauseAdminLessThanTwo) {
      return "It's recommended to have at least 2 Admins. Add new Admins before revoking rights from other Admins";
    }

    if (disabled) {
      return (
        customDisableTooltipText ??
        `You cannot assign or revoke this role because you are not ${anOrA(
          minimumRole
        )} ${minimumRole}`
      );
    }

    if (isUpdating) {
      return 'Updating...';
    }

    return `${checked ? 'Revoke' : 'Assign'} ${anOrA(roleName)} ${roleName}`;
  };

  return (
    <div className={cx('toggle-role-wrapper', disabled && 'disabled')}>
      <Tooltip content={getToolTipText()}>
        <Checkbox
          isDisabled={
            disabled || isUpdating || isDisabledBecauseAdminLessThanTwo
          }
          cx="checkbox-role"
          value={checked}
          onValueChange={(v) => {
            svc.uuiModals
              .show((modalProps) => {
                return (
                  <AlertModal
                    modalProps={modalProps}
                    alertContent={{
                      title: v
                        ? `Assign ${roleName}?`
                        : `Revoke ${roleName} Role?`,
                      description: v ? (
                        <span>
                          Are you sure you want to assign{' '}
                          <b>
                            {user.firstName} {user.lastName}
                          </b>{' '}
                          as {anOrA(roleName)} <b>{roleName}</b>?{' '}
                          {roleColumn == 'SUPER_ADMIN' && (
                            <span>
                              Your own Super Admin rights will be revoked.
                            </span>
                          )}
                        </span>
                      ) : (
                        <span>
                          Are you sure you want to revoke{' '}
                          <b>
                            {user.firstName} {user.lastName}’s {roleName} Role
                          </b>
                          ?
                        </span>
                      ),
                      closeText: v ? 'Assign' : 'Revoke',
                      cancelBtn: true,
                      noBorder: true,
                    }}
                  />
                );
              })
              .then(async () => {
                if (v) {
                  if (roleColumn == 'SUPER_ADMIN') {
                    svc.uuiModals
                      .show((props) => <BlockerModal modalProps={props} />, {
                        modalId: 'blocker',
                      })
                      .catch(() => null);
                    transferRoleMutation.mutate({});
                  } else {
                    svc.uuiModals
                      .show((props) => <BlockerModal modalProps={props} />, {
                        modalId: 'blocker',
                      })
                      .catch(() => null);
                    addRoleMutation.mutate({ role: roleColumn });
                  }
                } else {
                  svc.uuiModals
                    .show((props) => <BlockerModal modalProps={props} />, {
                      modalId: 'blocker',
                    })
                    .catch(() => null);
                  removeRoleMutation.mutate({ role: roleColumn });
                }
              });
          }}
        />
      </Tooltip>
    </div>
  );
};
