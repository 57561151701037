import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { FilterModel, Project } from './models/interfaces';
import './styles.scss';
import {
  FlexCell,
  FlexRow,
  Button,
  Paginator,
  SearchInput,
  Spinner,
  TabButton,
  IconButton,
} from '@epam/promo';
import {
  PROJECT_STATUS,
  PROJECTS_PAGE_SIZE,
} from '../../constants/uiConstants';
import { FlexSpacer } from '@epam/uui-components';
import GenerateReport from './components/generate-report';
import ProjectCard from './components/project-card';
import EmptyPanel from './components/empty-panel';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ProjectStatus } from '../project-management/models/interfaces';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants/queryKeys';
import projectListService from '../../services/api/project-list/projectListService';
import { useUuiContext } from '@epam/uui';
import useDebounce from '../../component/hooks/use-debounce';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import { ProjectShowroomStatus } from 'models/enums';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { ReactComponent as Cross } from '@epam/assets/icons/common/navigation-close-18.svg';
import eventManagementService from 'services/api/event-managment/eventManagementService';
import { NotFoundPage } from 'modules/not-found-page/not-found-page';
import { EventResponse } from 'modules/events/models/interfaces';
import { UpdateProject } from 'modules/project-management/update';
import { toTimeZoneBasedDate } from 'utils/dateUtils';

export interface Props {
  editorSwitch: boolean;
  pathName: string;
  initialFilter?: FilterModel;
  hideFilters?: boolean;
  isShowroom?: boolean;
}

const FILTER_STATUSES = [
  ProjectStatus.ALL,
  ProjectStatus.INITIAL,
  ProjectStatus.ACTIVE,
  ProjectStatus.CLOSED,
  ProjectStatus.SUSPENDED,
];

const ProjectList: FC<Props> = ({
  editorSwitch,
  pathName,
  initialFilter,
  hideFilters,
  isShowroom = false,
}) => {
  const svc = useUuiContext();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const filter = {
    searchValue: searchParams.get('search') || initialFilter?.searchValue || '',
    activeStatus:
      searchParams.get('status') ||
      initialFilter?.activeStatus ||
      ProjectStatus.ALL,
    showroomStatus: ProjectShowroomStatus.IN_SHOWROOM,
    page: page,
    myProjects: initialFilter?.myProjects,
    eventId: searchParams.get('eventId') || null,
  };

  const [searchValue, setSearchValue] = useState<string>(filter.searchValue);
  const [prevSearchValue, setPrevSearchValue] = useState<string>('');
  const [event, setEvent] = useState<EventResponse | null>(null);
  const [pageError, setPageError] = useState<string>('');

  const setNavigationParams = (value: string) => {
    const params = isShowroom
      ? { search: value }
      : {
          search: value,
          status: filter.activeStatus,
        };
    handleNavigate(params);
    setPage(1);
  };

  useDebounce(searchValue, 400, (value) => {
    if (value.length >= 2 || (prevSearchValue && value === '')) {
      setPrevSearchValue(value);
      setNavigationParams(value);
    }
  });

  const handleNavigate = (params: {
    eventId?: string;
    search: string;
    status?: string;
  }) => {
    navigate({
      pathname: pathName,
      search: `?${createSearchParams(params)}`,
    });
  };

  const removeEventFilter = () => {
    navigate({
      pathname: '/dashboard',
    });
  };

  useEffect(() => {
    if (Number(filter.eventId)) {
      eventManagementService
        .getEvent(Number(filter.eventId))
        .then((eventDetails) => {
          setEvent(eventDetails);
        })
        .catch(() => {
          setEvent(null);
          setPageError('Such Event does not exist');
        });
    }
    setEvent(null);
    setPageError('');
  }, [filter.eventId]);

  useEffect(() => {
    if (filter.eventId && Number(filter.eventId) !== event?.id) {
      return;
    }

    const isCreateProjectPage = location.pathname.endsWith('/createProject');
    if (isCreateProjectPage) {
      let project: Partial<Project> | null = null;

      svc.uuiModals.closeAll();

      if (filter.eventId && event) {
        const currentDate = dayjs();
        if (
          currentDate <
            toTimeZoneBasedDate(event.registrationStartDate, event.timeZone) ||
          currentDate >
            toTimeZoneBasedDate(event.registrationEndDate, event.timeZone)
        ) {
          setPageError('The registration for the Event is not open');
          return;
        }
        project = {
          challenge: {
            challengeId: event.id,
            challengeName: event.name,
            challengeLink: '',
          },
        };
      }

      svc.uuiModals
        .show((modalProps: any) => (
          <UpdateProject
            modalProps={modalProps}
            project={project}
            editorSwitch={editorSwitch}
          />
        ))
        .finally(() => {
          navigate({
            pathname: pathName,
            search: `?${searchParams}`,
          });
        });
    }
  }, [location, event]);

  const { data, dataUpdatedAt } = useQuery(
    [QUERY_KEYS.PROJECTS.PROJECT_LIST, filter, isShowroom],
    () =>
      projectListService.searchProjects(
        isShowroom || filter.activeStatus == ProjectStatus.ALL
          ? null
          : filter.activeStatus,
        filter.searchValue,
        {
          startPoint: (filter.page - 1) * PROJECTS_PAGE_SIZE,
          amount: PROJECTS_PAGE_SIZE,
        },
        isShowroom
          ? null
          : filter.myProjects
          ? userInfo
            ? userInfo.id
            : null
          : null,
        isShowroom ? filter.showroomStatus : null,
        //This becomes the challenge Id in the API call
        filter.eventId
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!userInfo,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const projects = data?.requestedProjectList;
  const mainClass = !isShowroom ? 'dashboard' : 'showroom';
  if (pageError) {
    return <NotFoundPage text={pageError} />;
  }

  return (
    <div className={`${mainClass}-container`}>
      <div className={mainClass}>
        {!hideFilters && (
          <>
            <FlexCell width="auto" cx={`${mainClass}__search_div`}>
              {!filter.eventId && (
                <SearchInput
                  value={searchValue ?? ''}
                  onValueChange={(value) => setSearchValue(value || '')}
                  disableDebounce={true}
                  placeholder="Search for projects by keyword, technology or location"
                  cx={`${mainClass}__search`}
                />
              )}
              {filter.eventId && (
                <div className="eventName">
                  {event?.name}
                  <IconButton
                    icon={Cross}
                    onClick={() => removeEventFilter()}
                  />
                </div>
              )}
            </FlexCell>
            {!isShowroom && (
              <FlexCell width="auto" cx="dashboard__main-tabs">
                <FlexRow alignItems="center" spacing="12">
                  {FILTER_STATUSES.map((item) => (
                    <TabButton
                      caption={PROJECT_STATUS[item]}
                      isLinkActive={filter.activeStatus === item}
                      onClick={() => {
                        filter.eventId
                          ? handleNavigate({
                              eventId: filter.eventId,
                              search: searchValue,
                              status: item,
                            })
                          : handleNavigate({
                              search: searchValue,
                              status: item,
                            });
                        setPage(1);
                      }}
                      size="36"
                      key={item}
                    />
                  ))}
                  <FlexSpacer />
                  {editorSwitch && (
                    <Button
                      isDisabled={!data?.totalNumberOfFoundedProject}
                      caption="Generate Report"
                      onClick={() =>
                        svc.uuiModals.show((modalProps: any) => (
                          <GenerateReport
                            modalProps={modalProps}
                            reportParams={{
                              challengeId: filter.eventId,
                              markedforDeletion: false,
                              numberOfProjects:
                                data?.totalNumberOfFoundedProject ??
                                projects?.length,
                              offset: {
                                startPoint:
                                  (filter.page - 1) * PROJECTS_PAGE_SIZE,
                                amount: PROJECTS_PAGE_SIZE,
                              },
                              projectOwnerId: filter.myProjects
                                ? userInfo?.id
                                : null,
                              status:
                                filter.activeStatus === ProjectStatus.ALL
                                  ? null
                                  : filter.activeStatus,
                              searchPhrase: filter.searchValue,
                              statusShowroom: null,
                            }}
                          />
                        ))
                      }
                      size="30"
                      fill="none"
                    />
                  )}
                </FlexRow>
              </FlexCell>
            )}
          </>
        )}
        {!projects ? (
          <Spinner color="blue" />
        ) : projects.length > 0 ? (
          <>
            {projects.map((projectProps) => (
              <ProjectCard
                key={projectProps.id}
                editorSwitch={editorSwitch}
                dataUpdatedAt={dataUpdatedAt}
                isShowroom={isShowroom}
                {...projectProps}
              />
            ))}
            <div className="paginator-container">
              <Paginator
                size="30"
                totalPages={Math.ceil(
                  (data?.totalNumberOfFoundedProject ?? 0) / PROJECTS_PAGE_SIZE
                )}
                value={page}
                onValueChange={(value) => setPage(value)}
              />
            </div>
          </>
        ) : (
          <EmptyPanel />
        )}
      </div>
    </div>
  );
};

export default ProjectList;
