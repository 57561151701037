import {
  Button,
  FlexRow,
  LabeledInput,
  PickerInput,
  PickerToggler,
} from '@epam/promo';
import {
  useAsyncDataSource,
  useLazyDataSource,
  LazyDataSourceApiRequest,
  useUuiContext,
  IModal,
} from '@epam/uui';
import { FlexSpacer } from '@epam/uui-components';
import { PickerTogglerProps } from '@epam/uui-components';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'App';
import usePreventReloadOnFormChanged from 'component/hooks/use-prevent-reload';
import {
  IProjectMember,
  ITelescopeMember,
} from 'modules/project-list/components/project-card/modal';
import React, { useCallback, useRef, useState } from 'react';
import memberService from 'services/api/member/memberService';
import ProjectListService from 'services/api/project-list/projectListService';
import cls from './styles.module.scss';
import {
  normalizeUsersResponse,
  remakeTelescopeMemberIntoProjectMember,
} from './utils';
import { QUERY_KEYS } from '../../../../../constants/queryKeys';
import AutoOpportunityDeletionModal from '../auto-delete-opportunity';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

interface AddTeamMemberProps {
  setAddTeamMemberVisible: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
  membersLength: number;
  selectedTeamMemberForEdit?: IProjectMember;
  onCancel: () => void;
  projectTeam: IProjectMember[];
  opportunities: {
    name: string;
    description: string;
    id?: number;
  }[];
}

const AddTeamMember: React.FC<AddTeamMemberProps> = ({
  setAddTeamMemberVisible,
  projectId,
  membersLength,
  selectedTeamMemberForEdit,
  onCancel,
  projectTeam,
  opportunities,
}) => {
  const [employee, setEmployee] = useState<any>(null);
  const [employeePosition, setEmployeePosition] = useState<any>(null);
  const initEmployee = useRef<any>(null);
  const initPosition = useRef<any>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [employeeInput, setEmployeeInput] = useState<string>('');
  const svc = useUuiContext();

  const addProjectMemberMutation = useMutation<
    any,
    any,
    { projectId: string; externalUserId: string; position: string }
  >(
    ({ projectId, externalUserId, position }) =>
      memberService.addProjectMember(projectId, externalUserId, position),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PERMISSIONS]),
        ]);
        setAddTeamMemberVisible(false);
        useCloseBlocker(svc);
        onCancel?.();
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const editProjectMemberMutation = useMutation<
    any,
    any,
    { projectId: string | number; externalUserId: string; position: string }
  >(
    ({ projectId, externalUserId, position }) =>
      memberService.editProjectMember(projectId, externalUserId, position),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
        ]);
        setAddTeamMemberVisible(false);
        useCloseBlocker(svc);
        onCancel?.();
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  React.useEffect(() => {
    if (selectedTeamMemberForEdit?.externalId) {
      const getPosition = async () => {
        const remotePosition = positionsDataSource.tree
          .getRootItems()
          .find(
            (node) => node.label === selectedTeamMemberForEdit.position
          )?.value;
        initPosition.current = remotePosition;
        initEmployee.current = { ...selectedTeamMemberForEdit };
        setEmployeePosition(remotePosition);
        setEmployee({ ...selectedTeamMemberForEdit });
      };
      getPosition();
    }
  }, []);

  React.useEffect(() => {
    if (!selectedTeamMemberForEdit?.externalId) {
      return setIsChanged(!!employee || !!employeePosition);
    }
    if (initEmployee.current || initPosition.current) {
      setIsChanged(
        employee?.externalId !== initEmployee.current.externalId ||
          employeePosition !== initPosition.current
      );
    }
  }, [employee, employeePosition]);

  usePreventReloadOnFormChanged({ isChanged });

  const submitHandler = () => {
    svc.uuiModals
      .show((props) => <BlockerModal modalProps={props} />, {
        modalId: 'blocker',
      })
      .catch(() => null);
    const member: IProjectMember = remakeTelescopeMemberIntoProjectMember(
      employee as ITelescopeMember,
      employeePosition,
      membersLength + 2
    );
    return selectedTeamMemberForEdit?.externalId
      ? editProjectMemberMutation.mutate({
          projectId,
          externalUserId: member.externalId,
          position: employeePosition,
        })
      : opportunities.some(
          (item) =>
            item.name ===
            positionsDataSource.tree
              .getRootItems()
              .find((node) => node.value === employeePosition)?.label
        )
      ? svc.uuiModals
          .show((confirmModalProps: IModal<string>) => (
            <AutoOpportunityDeletionModal
              modalBlockerProps={confirmModalProps}
              projectId={projectId}
              opportunityId={
                opportunities.find(
                  (item) =>
                    item.name ===
                    positionsDataSource.tree
                      .getRootItems()
                      .find((node) => node.value === employeePosition)?.label
                )?.id
              }
            />
          ))
          .catch(() => null)
          .finally(() =>
            addProjectMemberMutation.mutate({
              projectId,
              externalUserId: member.externalId,
              position: employeePosition,
            })
          )
      : addProjectMemberMutation.mutate({
          projectId,
          externalUserId: member.externalId,
          position: employeePosition,
        });
  };

  const loadTelescopeUsers = useCallback(
    (request: LazyDataSourceApiRequest<ITelescopeMember, ITelescopeMember>) => {
      return normalizeUsersResponse(request.search || '', projectTeam);
    },
    []
  );

  const membersDataSource = useLazyDataSource(
    {
      api: loadTelescopeUsers,
      getId: (member) => member,
    },
    []
  );

  const positionsDataSource = useAsyncDataSource(
    {
      api: async () => {
        const res = await ProjectListService.getProjectMemberPositions();
        return res.items.filter((item) => item.label !== 'Project Owner');
      },
      getId: (item) => item.value,
    },
    []
  );
  return (
    <div className={cls.AddTeamMember}>
      <LabeledInput
        label="Employee Name"
        cx={cls.MemberInput}
        isRequired={!employee}
      >
        <PickerInput
          isRequired={true}
          emptyValue={null}
          disableClear
          selectionMode="single"
          dataSource={membersDataSource}
          placeholder="Add Name or Email"
          size="36"
          value={employee}
          valueType={'entity'}
          onValueChange={(newValue) => {
            setEmployee(newValue);
            setEmployeeInput(newValue.displayName);
          }}
          getName={(member) =>
            member.displayName || `${member.firstName} ${member.lastName}`
          }
          isDisabled={!!selectedTeamMemberForEdit?.externalId}
          minCharsToSearch={2}
          renderToggler={(props: PickerTogglerProps) => (
            <PickerToggler
              {...props}
              value={employeeInput}
              isDropdown={false}
              onValueChange={(newValue) => {
                const trimmedValue = newValue.trimStart();
                setEmployeeInput(newValue);
                if (props.onValueChange) props.onValueChange(trimmedValue);
              }}
            />
          )}
        />
      </LabeledInput>

      <LabeledInput
        cx={cls.PositionInput}
        label="Position"
        isRequired={!employeePosition}
      >
        <PickerInput
          isRequired={true}
          placeholder="Choose position"
          emptyValue={null}
          disableClear
          selectionMode="single"
          dataSource={positionsDataSource}
          size="36"
          value={employeePosition}
          onValueChange={setEmployeePosition}
          getName={(position) => position.label}
          dropdownHeight={360}
        />
      </LabeledInput>

      <FlexRow>
        <FlexSpacer />
        <Button
          onClick={() => {
            setAddTeamMemberVisible(false);
            onCancel?.();
          }}
          cx={cls.Button}
          size="30"
          fill="none"
          caption="Cancel"
        />
        <Button
          isDisabled={
            !employee ||
            !employeePosition ||
            (!!selectedTeamMemberForEdit?.externalId &&
              selectedTeamMemberForEdit.position === employeePosition) ||
            addProjectMemberMutation.isLoading ||
            editProjectMemberMutation.isLoading
          }
          onClick={submitHandler}
          cx={cls.Button}
          size="30"
          caption="Submit"
        />
      </FlexRow>
    </div>
  );
};

export default AddTeamMember;
