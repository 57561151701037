import { Button, Panel, Text } from '@epam/promo';
import { IProjectLink } from 'modules/project-management/models/interfaces';
import { LINK_COLUMN_CATEGORIES, LINK_TYPE_ICON_MAP } from './constants';
import { MAX_LINKS_COLUMNS } from 'constants/uiConstants';
import './styles.scss';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../../../../constants/queryKeys';
import ProjectManagementService from '../../../../../services/api/project-management/projectManagementService';
import { Fragment, useEffect, useRef, useState } from 'react';
import useWindowSize from 'component/hooks/use-window-size';
import { ProjectLinkCategory } from 'models/enums';
import { sortByPosition } from 'modules/project-management/utils';

export type Props = {
  links: IProjectLink[];
};

export interface Link {
  id: number | string;
  value: string;
  category: string;
  label: string;
  position: number;
  icon: any;
  link: string;
}

export interface CategoryInfo {
  category: string;
  links: Link[];
}

export type ColumnsInfo = {
  [key: string]: CategoryInfo[];
};

const buildColumns = (columnsInfo: ColumnsInfo, descriptionWidth: string) => {
  const hasLinks = (column: CategoryInfo[]) =>
    column.reduce((acc, categoryInfo) => categoryInfo.links.length + acc, 0) >
    0;

  const buildColumn = (column: string, categories: CategoryInfo[]) => {
    const buildButtons = (categories: CategoryInfo[]) => {
      const buildButton = (link: Link) => {
        return (
          <Fragment key={link.id}>
            <Button
              icon={link.icon}
              key={link.id}
              iconPosition="left"
              caption={link.label}
              fill="light"
              href={link.link}
              cx="item"
              captionCX="item-caption"
              target="_blank"
            />
          </Fragment>
        );
      };

      return categories.map((value) => {
        return (
          value.links.length !== 0 && (
            <Fragment key={value.category}>
              <Text cx="category-head" fontSize="14">
                {value.category}
              </Text>
              {value.links.map(buildButton)}
            </Fragment>
          )
        );
      });
    };

    return (
      <div
        className="link-column"
        key={column}
        style={{
          width: `${parseInt(descriptionWidth) / MAX_LINKS_COLUMNS}px`,
        }}
      >
        <div className="link-container">{buildButtons(categories)}</div>
      </div>
    );
  };

  return Object.entries(columnsInfo).map(([column, categories]) => {
    return hasLinks(categories) && buildColumn(column, categories);
  });
};

function ProjectLinks({ links }: Props) {
  // TODO grigorii_berezin: move that mapping to the project get
  const { data: groupedLinksByColumn, isLoading } = useQuery(
    [QUERY_KEYS.DICTIONARY.LINK_NAMES, links],
    async () => {
      const linksDictionary = await ProjectManagementService.getProjectLinks();
      const groupedLinks: { [key: string]: Link[] } = links.reduce(
        (acc, link) => {
          const linkType = linksDictionary.items.find(
            (type) => type.value == link.linkType
          ) ?? {
            value: 'GIT_REPO',
            category: ProjectLinkCategory.REPOSITORIES,
            label: 'GitLab',
            position: 0,
          };
          const newLink: Link = {
            id: link.id ?? -1,
            icon: LINK_TYPE_ICON_MAP[linkType.value],
            ...linkType,
            link: link.link,
          };

          if (!acc[linkType.category]) {
            acc[linkType.category] = [];
          }
          acc[linkType.category].push(newLink);
          return acc;
        },
        {} as { [key: string]: Link[] }
      );

      const groupedLinksByColumn: ColumnsInfo = {};

      Object.entries(LINK_COLUMN_CATEGORIES).forEach(([column, categories]) => {
        groupedLinksByColumn[column] = categories.map((category) => ({
          category,
          links: sortByPosition(groupedLinks[category]) || [],
        }));
      });

      return groupedLinksByColumn;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const [descriptionWidth, setDescriptionWidth] = useState<string>('0px');
  const linkRef: React.MutableRefObject<any> = useRef();
  const { width } = useWindowSize();

  useEffect(() => {
    if (linkRef?.current) {
      const descriptionEl = linkRef.current.parentNode.querySelector(
        '.project-detail-left__description'
      );
      if (descriptionEl) {
        const descriptionElWidth = getComputedStyle(descriptionEl).width;
        setDescriptionWidth(descriptionElWidth);
      }
    }
  }, [descriptionWidth, width]);

  return (
    <Panel cx="useful-links" ref={linkRef} style={{ width: descriptionWidth }}>
      {!isLoading &&
        groupedLinksByColumn &&
        buildColumns(groupedLinksByColumn, descriptionWidth)}
    </Panel>
  );
}

export default ProjectLinks;
