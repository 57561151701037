import {
  FlexRow,
  FlexCell,
  IconContainer,
  LabeledInput,
  TextInput,
  IconButton,
} from '@epam/promo';
import { ILens } from '@epam/uui';
import {
  getLinkProps,
  removeLink,
} from '../../modules/project-management/details/components/add-to-showroom/helpers/utils';
import {
  IProjectLink,
  ProjectLinkType,
} from 'modules/project-management/models/interfaces';
import { ReactComponent as done } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as invalid } from '@epam/assets/icons/common/navigation-close-18.svg';
import { ReactComponent as ClearIcon } from '@epam/assets/icons/common/navigation-close-24.svg';
import LabeledInputWithCustomTooltip from 'component/LabeledInputWithCustomTooltip';

const LinksRenderer = ({
  lens,
  projectLinks,
  targetLinkTypes,
  dictionary,
  tooltip = [],
  className,
  placeholder,
  isRemovable,
  hideIconContainer,
}: {
  lens: ILens<any>;
  projectLinks: IProjectLink[];
  targetLinkTypes: string[];
  dictionary: ProjectLinkType[];
  tooltip?: string[];
  className?: string;
  placeholder?: string;
  isRemovable?: boolean;
  hideIconContainer?: boolean;
}) => (
  <>
    {projectLinks.map(
      (link: IProjectLink, index: number) =>
        targetLinkTypes.includes(link.linkType ?? '') && (
          <FlexRow
            cx="icon-name-container"
            vPadding="12"
            alignItems="top"
            key={index}
            rawProps={{ 'data-auto-scroll': '' }}
          >
            {!hideIconContainer && (
              <FlexCell style={{ marginTop: '6px' }} minWidth={24}>
                {' '}
                <IconContainer
                  icon={link.link ? done : invalid}
                  color={link.link ? 'green' : 'red'}
                  size={24}
                />
              </FlexCell>
            )}
            <FlexCell cx="icon-name-container" minWidth={150}>
              <LabeledInputWithCustomTooltip
                tooltip={tooltip[targetLinkTypes.indexOf(link.linkType ?? '')]}
                label={
                  dictionary.find((item: any) => item.value === link.linkType)
                    ?.label
                }
                className={`${className}
                  ${
                    getLinkProps(lens, index).isInvalid
                      ? 'showroom-label__invalid-field'
                      : ''
                  }`}
              />
            </FlexCell>
            <FlexCell
              cx="showroom-label__input-block"
              style={{ flexBasis: 'auto' }}
              grow={1}
              shrink={1}
            >
              <LabeledInput
                htmlFor={`${link.linkType}_${index}`}
                {...getLinkProps(lens, index)}
              >
                <TextInput
                  placeholder={placeholder || 'https://...'}
                  {...getLinkProps(lens, index)}
                  id={`${link.linkType}_${index}`}
                />
              </LabeledInput>
            </FlexCell>
            {isRemovable && (
              <FlexCell minWidth={24} style={{ marginTop: '6px' }}>
                <IconButton
                  icon={ClearIcon}
                  onClick={() => removeLink(lens, index)}
                  cx={!!className && `${className}__remove-button`}
                />
              </FlexCell>
            )}
          </FlexRow>
        )
    )}
  </>
);

export default LinksRenderer;
