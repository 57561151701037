import { IModal } from '@epam/uui';
import { ModalBlocker } from '@epam/promo';
import loader from 'assets/svg/circle_loader.svg';
import './blocker.scss';

interface Props {
  modalProps: IModal<string>;
}

export const BlockerModal = ({ modalProps }: Props) => {
  return (
    <ModalBlocker
      blockerShadow="dark"
      {...modalProps}
      disallowClickOutside
      abort={() => undefined}
    >
      <img
        src={loader}
        style={{ height: '40px', transform: 'scaleX(-1)' }}
        alt="loader"
      />
    </ModalBlocker>
  );
};
