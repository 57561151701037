import React from 'react';
import { LabeledInput, DatePicker, FlexCell, FlexRow } from '@epam/promo';
import { ILens } from '@epam/uui';
import dayjs, { Dayjs } from 'dayjs';
import './styles.scss';
import { ProjectDetails } from 'modules/project-management/models/interfaces';
import { dateFormatter } from 'utils/dateUtils';

const DATE_FORMAT = 'YYYY-MM-DD';

function EndDate({
  lens,
  isNewProject,
}: {
  lens: ILens<ProjectDetails['projectTimestamps']>;
  isNewProject: boolean;
}) {
  const minSelectableDate = dayjs(
    isNewProject ? dayjs() : dayjs(lens.toProps().value.plannedStartDate)
  )
    .subtract(1, 'day')
    .valueOf();

  const plannedEndDateProps = lens.prop('plannedEndDate').toProps();

  return (
    <>
      <FlexRow vPadding={'24'} cx={'project-date-container'}>
        <FlexCell width={'auto'} rawProps={{ 'data-auto-scroll': '' }}>
          <LabeledInput label={'Project End Date'} {...plannedEndDateProps}>
            <DatePicker
              filter={(day: Dayjs) => day.valueOf() >= minSelectableDate}
              format={DATE_FORMAT}
              {...plannedEndDateProps}
              value={dateFormatter(plannedEndDateProps.value, DATE_FORMAT)}
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
    </>
  );
}

export { EndDate };
