export enum RequestHeader {
  ACCEPT = 'Accept',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
  CACHE_CONTROL = 'Cache-Control',
  ACCESS_CONTROL_ALLOW_ORIGIN = 'Access-Control-Allow-Origin',
  ACCESS_CONTROL_ALLOW_HEADERS = 'Access-Control-Allow-Headers',
}

export enum AcceptType {
  JSON = 'application/json',
  URL_ENCODED = 'application/x-www-form-urlencoded',
}

export enum ContentType {
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
  URL_ENCODED = 'application/x-www-form-urlencoded',
  CONTROL_ALLOW_HEADERS = 'Origin, X-Requested-With, Content-Type, Accept',
}

export enum HttpStatusCode {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  NO_NETWORK_CONNECTION = 420,
  SERVER_ERROR = 500,
}
