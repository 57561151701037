import {
  IconButton,
  ModalBlocker,
  ModalWindow,
  ScrollBars,
  Spinner,
} from '@epam/promo';
import { useMemo } from 'react';
import { IProjectDetail } from '../../modal';
import { ReactComponent as Cross } from '@epam/assets/icons/common/navigation-close-24.svg';
import ProjectDetail from '../..';
import { useUuiContext } from '@epam/uui';
import './styles.scss';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../../../../constants/queryKeys';
import projectListService from '../../../../../services/api/project-list/projectListService';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

export const ProjectDetailsWrapper = (props: IProjectDetail) => {
  const svc = useUuiContext();

  const isOtherModalActive = useMemo(
    () => svc.uuiModals?.getOperations()?.length > 1,
    [props.modalProps]
  );

  const { data: currentProject } = useQuery(
    [QUERY_KEYS.PROJECTS.PROJECT_DETAILS, props.projectID],
    () => projectListService.getProjectById(props.projectID),
    {
      refetchOnWindowFocus: false,
      initialData: () => props.projectDetailProps,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );
  return (
    <ModalBlocker
      blockerShadow="dark"
      {...props.modalProps}
      abort={() => (isOtherModalActive ? null : props.modalProps.abort())}
    >
      <ModalWindow height="auto" cx="project-detail-modal">
        <IconButton
          icon={Cross}
          color="gray60"
          cx="project-detail-modal__close"
          onClick={props.modalProps.abort}
        />
        {!currentProject ? (
          <div className="loading-component">
            <Spinner />
          </div>
        ) : (
          <ScrollBars hasBottomShadow>
            <ProjectDetail
              editorSwitch={props.editorSwitch}
              project={currentProject}
              onCloseParent={() => props.modalProps.abort()}
              isShowroom={props.isShowroom}
            />
          </ScrollBars>
        )}
      </ModalWindow>
    </ModalBlocker>
  );
};
