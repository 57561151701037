import { DASHBOARD_API } from 'constants/apiConstants';
import {
  DictionaryModel,
  IListProjectsPayload,
  IProjectMemberPosition,
  ISkilloPayload,
  Project,
} from 'modules/project-list/models/interfaces';
import { BaseService } from '../baseService';
import { formatStringWithParams } from 'utils/stringUtils';
import {
  ISkilloTech,
  ITelescopeMember,
} from 'modules/project-list/components/project-card/modal';
import { ProjectStatus } from 'models/enums';

class ProjectListService extends BaseService {
  private static instance: ProjectListService;

  public static getInstance(): ProjectListService {
    if (!ProjectListService.instance) {
      ProjectListService.instance = new ProjectListService();
    }
    return ProjectListService.instance;
  }

  public getProjects() {
    return ProjectListService.instance.get<Array<Project>>(
      DASHBOARD_API.ProjectList
    );
  }

  public getProjectById(projectId: string | number) {
    return ProjectListService.instance.get<Project>(
      DASHBOARD_API.ProjectById(projectId)
    );
  }

  public searchProjects(
    status?: ProjectStatus | string | null,
    searchPhrase?: string | null,
    offset?: { amount: number; startPoint: number } | null,
    projectOwnerId?: number | null,
    statusShowroom?: string | null,
    challengeId?: string | null
  ) {
    return ProjectListService.instance.post<IListProjectsPayload>(
      DASHBOARD_API.ProjectSearch,
      {
        status,
        searchPhrase,
        offset,
        projectOwnerId,
        markedForDeletion: false,
        statusShowroom,
        challengeId,
      }
    );
  }
  public restoreProject(projectId: number) {
    return ProjectListService.instance.get(
      DASHBOARD_API.RestoreProjectById(projectId)
    );
  }
  public getProjectStatusById(projectId: number) {
    return ProjectListService.instance.get(
      DASHBOARD_API.GetProjectStatusById(projectId)
    );
  }

  public getProfileProjectPicture(imgUrl: string) {
    return formatStringWithParams(DASHBOARD_API.ProjectPictureUrl, imgUrl);
  }

  public getProjectMemberPositions() {
    return ProjectListService.instance.get<
      DictionaryModel<IProjectMemberPosition>
    >(DASHBOARD_API.ProjectMemberPositions);
  }

  public getTelescopeUsersByQuery(query: string) {
    return ProjectListService.instance.get<ITelescopeMember[]>(
      DASHBOARD_API.TelescopeUsers(query)
    );
  }

  public getSkilloTechsByQuery(query: string, numOfRes: number) {
    return ProjectListService.instance.get<ISkilloPayload>(
      DASHBOARD_API.SkilloTechs(query, numOfRes)
    );
  }

  public getSkilloTechsById(query: string) {
    return ProjectListService.instance.get<ISkilloTech[]>(
      DASHBOARD_API.SkilloTechsByID(query)
    );
  }

  public deleteProjectCoverImg(projectId: number) {
    return ProjectListService.instance.delete(
      DASHBOARD_API.DeleteProjectCoverImg(projectId)
    );
  }
}

export default ProjectListService.getInstance();
