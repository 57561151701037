import { REPORTS_API } from 'constants/apiConstants';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BaseService } from '../baseService';
import { formatDateForBE } from 'utils/dateUtils';

class ContributionReportService extends BaseService {
  private static instance: ContributionReportService;

  public static getInstance(): ContributionReportService {
    if (!ContributionReportService.instance) {
      dayjs.extend(utc);
      ContributionReportService.instance = new ContributionReportService();
    }
    return ContributionReportService.instance;
  }

  public requestReport(projectId: number, since: Dayjs, until: Dayjs) {
    return ContributionReportService.instance.get<void>(
      REPORTS_API.RequestProject(projectId),
      {
        params: {
          since: formatDateForBE(since),
          until: formatDateForBE(until),
        },
      }
    );
  }
}

export default ContributionReportService.getInstance();
