import React, { useContext, useState } from 'react';
import { Dropdown } from '@epam/uui-components';
import {
  Badge,
  SuccessNotification,
  DropdownContainer,
  EpamAdditionalColor,
} from '@epam/promo';
import { DropdownBodyProps } from '@epam/uui-core';
import { IDropdownToggler, useUuiContext } from '@epam/uui';
import checkIcon from 'assets/svg/check.svg';
import { checkDisabledStatus } from '../../../../utils';
import { EditorSwitchContext, queryClient } from '../../../../../../App';

import {
  NOTIFICATION_MESSAGES,
  PROJECT_STATUS,
  PROJECT_STATUS_COLOR,
} from '../../../../../../constants/uiConstants';
import './styles.scss';
import { AlertModal } from '../../../../../../component/AlertModal';
import { IProjectCard } from '../../../../../project-list/components/project-card/modal';
import { useMutation } from '@tanstack/react-query';
import projectManagementService from '../../../../../../services/api/project-management/projectManagementService';
import { QUERY_KEYS } from '../../../../../../constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { AddToShowroomProps } from '../../add-to-showroom/models/interfaces';
import { getLinkByType } from '../../add-to-showroom/helpers/utils';
import { LINK_TYPES } from '../../project-links/constants';
import AddToShowroom from '../../add-to-showroom';

export type Props = {
  project: IProjectCard;
  id: string;
  projectStatus: string;
  options: string[];
  userHasEditPermissions: boolean;
  isProjectHasActiveShowroomRequest: boolean;
  closeParentModal: () => void;
  canBeMarkedForDeletion: boolean;
  isShowroom?: boolean;
  info: AddToShowroomProps['info'];
  links: AddToShowroomProps['links'];
};

const ProjectStatus: React.FC<Props> = ({
  projectStatus: currentStatus,
  options: listOfStatuses,
  id: projectId,
  userHasEditPermissions,
  isProjectHasActiveShowroomRequest,
  closeParentModal,
  canBeMarkedForDeletion,
  isShowroom,
  info,
  links,
}) => {
  const isEditorModeEnabled = useContext(EditorSwitchContext);
  const svc = useUuiContext();
  const [disabled, setDisabled] = useState(false);
  const deleteProjectMutation = useMutation<any, any, number>(
    (id: number) => {
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);
      return projectManagementService.markProjectForDeletion(id);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([QUERY_KEYS.USERS.PROJECT_COUNT]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
        ]);

        useCloseBlocker(svc);
        closeParentModal();
        svc.uuiNotifications
          .show((props) => (
            <SuccessNotification {...props}>
              {NOTIFICATION_MESSAGES.success.isMarkedForDeletion}
            </SuccessNotification>
          ))
          .catch(() => null);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const updateProjectStatusMutation = useMutation<
    any,
    any,
    { id: number; status: string }
  >(
    ({ id, status }) => {
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);
      return projectManagementService.updateProjectStatus(id, status);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([QUERY_KEYS.USERS.PROJECT_COUNT]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
        ]);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);

        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const handleStatusChange = async (newProjectStatus: string) => {
    if (
      currentStatus === 'ACTIVE' &&
      newProjectStatus === 'CLOSED' &&
      !isProjectHasActiveShowroomRequest
    ) {
      const userEpamGitlabLink = getLinkByType(links, LINK_TYPES.GIT_OTHER);
      const userHasActiveRepository =
        await projectManagementService.getFieldsStatusToAddProjectToShowroom(
          parseInt(info.id),
          !!userEpamGitlabLink && userEpamGitlabLink.link
        );
      const onClosedFromActiveSubmitSuccess = () => {
        const updateProjectStatusMutationPromise =
          updateProjectStatusMutation.mutateAsync({
            id: parseInt(projectId),
            status: newProjectStatus,
          });

        return updateProjectStatusMutationPromise;
      };
      svc.uuiModals.show(
        (modalProps) => {
          return (
            <AddToShowroom
              modalProps={modalProps}
              info={info}
              links={links}
              userHasActiveRepository={userHasActiveRepository}
              enableCancelShowroomButton={() => setDisabled(false)}
              isClosedFromActive={true}
              onClosedFromActiveSubmitSuccess={onClosedFromActiveSubmitSuccess}
            />
          );
        },
        {
          modalId: 'blocker',
        }
      );
    } else if (newProjectStatus === 'DELETE') {
      if (canBeMarkedForDeletion) {
        svc.uuiModals
          .show((props) => (
            <AlertModal
              modalProps={props}
              alertContent={{
                title: 'Delete project? ',
                description:
                  'The project will be marked for deletion and will not be available from the Dashboard. Project gitlab repository will be archived.',
                closeText: 'Delete project',
                cancelBtn: true,
                noBorder: true,
              }}
            />
          ))
          .then(() => {
            deleteProjectMutation.mutate(parseInt(projectId));
          })
          .catch(() => useCloseBlocker(svc));
      } else {
        svc.uuiModals
          .show((props) => (
            <AlertModal
              modalProps={props}
              alertContent={{
                title: 'Cannot delete project ',
                description:
                  'Please remove all team members before deleting the project.',
                closeText: 'Got it',
                noBorder: true,
              }}
            />
          ))
          .then(() => useCloseBlocker(svc))
          .catch(() => useCloseBlocker(svc));
      }
    } else {
      updateProjectStatusMutation.mutate({
        id: parseInt(projectId),
        status: newProjectStatus,
      });
    }
  };

  // DropdownBodyProps has been removed as a type export from uui-components
  const renderDropdownBody = (props: DropdownBodyProps) => {
    return (
      <DropdownContainer width={140}>
        {listOfStatuses
          .filter(
            (status) =>
              !checkDisabledStatus(currentStatus, status, isEditorModeEnabled)
          )
          .map((status) => (
            <div
              key={status}
              className="dropdown-container"
              onClick={() => {
                if (
                  status == 'SUSPENDED' &&
                  isProjectHasActiveShowroomRequest
                ) {
                  svc.uuiModals.show((props) => (
                    <AlertModal
                      modalProps={props}
                      alertContent={{
                        title: 'Exception',
                        description:
                          'The showroom request should be revoked first.',
                      }}
                    />
                  ));
                } else {
                  if (props.onClose) {
                    props.onClose();
                  }
                  handleStatusChange(status);
                }
              }}
            >
              <div className="dropdown__item" data-testid="projectStatus">
                <span style={{ color: status === 'DELETE' ? '#BE3316' : '' }}>
                  {PROJECT_STATUS[status as keyof typeof PROJECT_STATUS]}
                </span>
              </div>
              {currentStatus === status && <img src={checkIcon} alt="check" />}
            </div>
          ))}
      </DropdownContainer>
    );
  };

  return (
    <Dropdown
      isNotUnfoldable={
        deleteProjectMutation.isLoading ||
        updateProjectStatusMutation.isLoading ||
        !userHasEditPermissions
      }
      renderBody={renderDropdownBody}
      renderTarget={(props: IDropdownToggler) => {
        const p = userHasEditPermissions && props;
        return (
          <Badge
            {...p}
            caption={
              PROJECT_STATUS[currentStatus as keyof typeof PROJECT_STATUS]
            }
            fill={'semitransparent'}
            color={
              PROJECT_STATUS_COLOR[
                currentStatus as keyof typeof PROJECT_STATUS_COLOR
              ] as EpamAdditionalColor
            }
            size={'30'}
            isDropdown={userHasEditPermissions}
          />
        );
      }}
    />
  );
};

export default ProjectStatus;
