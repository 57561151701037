import React, { createContext, FC, useContext } from 'react';

type ContextType = {
  width: number;
  height: number;
};

const useScreenSizeProvider = (): ContextType => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  });

  return windowSize;
};

const ScreenSizeContext = createContext<ContextType>({} as ContextType);

export const ScreenSizeProvider: FC = ({ children }) => {
  const screenSize = useScreenSizeProvider();

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

export const useScreenSize = () => useContext(ScreenSizeContext);
