import ProjectList from 'modules/project-list';
import { FilterModel } from 'modules/project-list/models/interfaces';
import { ProjectStatus } from 'modules/project-management/models/interfaces';
import React from 'react';

interface Props {
  editorSwitch: boolean;
}

const PATH_NAME = '/my-projects';

const MyProjects = ({ editorSwitch }: Props): React.ReactElement => {
  const initialFilter: FilterModel = {
    activeStatus: ProjectStatus.ALL,
    myProjects: true,
    page: 1,
  };
  return (
    <ProjectList
      editorSwitch={editorSwitch}
      pathName={PATH_NAME}
      initialFilter={initialFilter}
    />
  );
};

export default MyProjects;
