import { FC } from 'react';
import './permissions.scss';
import { Panel, Text } from '@epam/promo';
import { isCanAccessPermissionsPage } from '../../utils/rolesUtils';
import { NotFoundPage } from '../not-found-page/not-found-page';
import { GarageUsersTable } from './garage-users/garage-users-table';
import { useUserInfo } from '../../services/auth-client/user-info-provider';

type Props = {};

export const Permissions: FC<Props> = () => {
  const { userInfo } = useUserInfo();

  if (!userInfo) return null;

  if (!isCanAccessPermissionsPage(userInfo?.roles || [])) {
    return <NotFoundPage />;
  }

  return (
    <div className="permissions-route">
      <Panel cx="permission-card" background="white" shadow>
        <div className="container">
          <Text fontSize="24">
            <b>Permissions Dashboard</b>
          </Text>
        </div>
      </Panel>

      <div className="container">
        <GarageUsersTable />
      </div>
    </div>
  );
};
