import { FlexRow, IconButton, Panel, Text } from '@epam/promo';
import { IOpportunityItem } from '../../models/model';
import './opportunity-card.scss';
import React, { useEffect, useState } from 'react';
import CustomAvatar from '../../../../component/Avatar';
import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import { ReactComponent as telescopeIcon } from '@epam/assets/icons/common/communication-telescope-18.svg';
import { ReactComponent as teamsIcon } from '@epam/assets/icons/common/microsoft-teams-18.svg';
import { useUuiContext } from '@epam/uui';
import { OpportunityRequestButton } from '../../../project-management/details/components/current-opportunities/opportunity-request-button';
import userService from 'services/api/user/userService';
import { ProjectDetailsWrapper } from 'modules/project-management/details/components/project-details-wrapper';
import { useQuery } from '@tanstack/react-query';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import { dateFormatter } from '../../../../utils/dateUtils';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import OverflowTooltip from '../../../../utils/OverflowTooltip';
import { BadgesList } from 'component/BadgesList/badges-list';

interface Props {
  opportunityItem: IOpportunityItem;
  editorSwitch: boolean;
}

export const OpportunityCard = ({
  opportunityItem,
  editorSwitch,
}: Props): React.ReactElement => {
  const svc = useUuiContext();
  const { opportunity, project } = opportunityItem;
  const { userInfo } = useUserInfo();
  const [technologies, setTechnologies] = useState<string[]>([]);
  const isUpdated =
    opportunity.updated && opportunity.updated !== opportunity.created;

  const { data } = useQuery(
    [QUERY_KEYS.USERS.PROFILE_PHOTO, opportunityItem.project.owner.id],
    async () => {
      return userService.getUserProfilePicture(
        opportunityItem.project.owner.id
      );
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  useEffect(() => {
    setTechnologies(opportunity.technologies.map((t) => t.name));
  }, [opportunity.technologies]);

  return (
    <Panel
      cx="opportunity-card"
      background="white"
      shadow
      onClick={() => {
        {
          const oldUrl = window.location.href;
          window.history.replaceState(
            null,
            project.name,
            `/dashboard/project/${project.id}`
          );
          svc.uuiModals
            .show((modalProps) => {
              const detailProps = {
                modalProps,
                editorSwitch,
                projectID: project.id,
              };
              return <ProjectDetailsWrapper {...detailProps} />;
            })
            .catch(() => {
              window.history.replaceState(null, 'Dashboard', oldUrl);
            });
        }
      }}
    >
      <div className="op-card-container">
        <div className="op-card-project-details">
          <div
            className={`project-title ${
              project.technologies.length
                ? 'title-margin-with-tech'
                : 'title-margin-without-tech'
            }`}
          >
            <OverflowTooltip
              text={`${opportunity.name} for ${project.name}`}
              cx="opportunity-name"
            />
            <div className="date-created">
              <Text fontSize="12">
                <span className="created-text">
                  {isUpdated ? 'Updated' : 'Created'}:
                </span>{' '}
                {dateFormatter(
                  isUpdated ? opportunity.updated : opportunity.created
                )}
              </Text>
            </div>
          </div>
          {technologies.length > 0 && (
            <FlexRow alignItems="top" spacing="12" vPadding="12">
              <BadgesList
                className="tech-badges"
                color={'blue'}
                items={technologies}
              />
            </FlexRow>
          )}

          <Text>{opportunity.description}</Text>
        </div>
        <div className="op-card-project-owner">
          <div className="product-owner-information">
            <div className="project-owner">
              <div className="project-owner-avatar">
                <CustomAvatar
                  img={data}
                  alt="Project Owner avatar"
                  size={'36'}
                  initials={`${project.owner.firstName[0]}${project.owner.lastName[0]}`}
                />
              </div>

              <div className="project-owner-information">
                <Text
                  fontSize="14"
                  font="sans-semibold"
                  cx="project-owner-name"
                >
                  {project.owner.firstName} {project.owner.lastName}
                </Text>

                <Text fontSize="12" cx="project-owner-job-title">
                  Project Owner
                </Text>
                {userInfo?.id != project.owner.id && (
                  <div className="button-icons">
                    <IconButton
                      cx="mail-icon"
                      color="gray50"
                      icon={mailIcon}
                      href={`mailto:${project.owner.email}`}
                    />
                    <IconButton
                      cx="teams-icon"
                      color="gray50"
                      icon={teamsIcon}
                      onClick={() => {
                        window.open(
                          `https://teams.microsoft.com/l/chat/0/0?users=${project.owner.email}`,
                          '_blank'
                        );
                      }}
                    />
                    <IconButton
                      color="gray50"
                      cx="telescope-icon"
                      onClick={() => {
                        window.open(
                          `https://telescope.epam.com/who/key/upsaId/${project.owner.externalId}?tab=profile`,
                          '_blank'
                        );
                      }}
                      icon={telescopeIcon}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="request-to-join">
            <OpportunityRequestButton
              opportunity={opportunity}
              projectID={project.id}
              isVisible
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};
