import {
  FlexCell,
  FlexRow,
  LabeledInput,
  Panel,
  Text,
  TextInput,
} from '@epam/promo';
import './styles.scss';
import {
  IProjectLink,
  ProjectLinkType,
} from 'modules/project-management/models/interfaces';
import LinksRenderer from 'component/Artifacts/LinksRenderer';
import LinksWithDropdownRenderer from 'component/Artifacts/LinksWithDropdownRenderer';
import {
  BUTTON_CAPTION_VARIANT,
  CONTACT_LINKS_LIMIT_TOOTIP_INFO,
  tooltips,
} from 'modules/project-management/details/components/add-to-showroom/constants';
import { LINK_TYPES } from 'modules/project-management/details/components/project-links/constants';
import { ILens } from '@epam/uui';
import UploadFile from 'component/Artifacts/UploadFile';
import LabeledInputWithCustomTooltip from 'component/LabeledInputWithCustomTooltip';
import { getGitlabTooltip } from 'modules/project-management/details/utils';

const Artifacts = ({
  lens,
  projectLinks,
  dictionary,
  isReachedLinkLimit,
  gitlabUrl,
}: {
  lens: ILens<any>;
  projectLinks: IProjectLink[];
  dictionary: ProjectLinkType[];
  isReachedLinkLimit: boolean;
  gitlabUrl: string;
}) => {
  const gitlabTooltip = getGitlabTooltip(gitlabUrl);

  return (
    <Panel background="white" cx="artifact-panel-section__body">
      <Panel cx="artificat-panel-items__body__status">
        <LabeledInput
          label="Repositories:"
          cx="add-to-showroom-modal__body__status__title"
        />
        {!gitlabUrl ? (
          <Text fontSize={'16'} lineHeight={'24'} cx="artifact-caption py-6">
            Gitlab repository will be automatically provisioned to your project.
          </Text>
        ) : (
          <FlexRow cx="icon-name-container" vPadding="12" alignItems="top">
            <FlexCell
              cx="icon-name-container"
              alignSelf="center"
              minWidth={150}
            >
              <LabeledInputWithCustomTooltip
                label="Code on Gitlab"
                className="showroom-label"
                tooltip={gitlabTooltip}
              />
            </FlexCell>
            <FlexCell
              cx="showroom-label__input-block"
              style={{ flexBasis: 'auto' }}
              grow={1}
              shrink={1}
            >
              <TextInput
                placeholder="https://..."
                value={gitlabUrl}
                id={LINK_TYPES.GIT_REPO}
                onValueChange={() => {
                  return;
                }}
                isDisabled
              />
            </FlexCell>
          </FlexRow>
        )}
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.GIT_GITHUB, LINK_TYPES.GIT_OTHER]}
          className="showroom-label"
          isRemovable
          hideIconContainer
        />
        <LinksWithDropdownRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.GIT_GITHUB, LINK_TYPES.GIT_OTHER]}
          caption={BUTTON_CAPTION_VARIANT.ADD_SOURCE}
          isDisabled={isReachedLinkLimit}
          activeDropdownCategory={'Repositories'}
          className="showroom-label_add"
        />
      </Panel>
      <Panel cx="artificat-panel-items__body__status">
        <LabeledInput
          cx="add-to-showroom-modal__body__status__title"
          label="Project Info:"
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.DOCS_DECKS]}
          className="showroom-label"
          tooltip={[tooltips.DOCS_DECKS]}
          isRemovable
          hideIconContainer
        />
        <UploadFile
          lens={lens}
          label="Slide Deck"
          projectLinks={projectLinks}
          tooltip={tooltips.SLIDE_DECK}
          className="showroom-label__files"
          targetLinkType={LINK_TYPES.SLIDE_DECK}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.PROJECT_INFO_OTHER]}
          className="showroom-label"
          isRemovable
          hideIconContainer
        />
        <LinksWithDropdownRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[
            LINK_TYPES.DOCS_DECKS,
            LINK_TYPES.SLIDE_DECK,
            LINK_TYPES.PROJECT_INFO_OTHER,
          ]}
          caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
          isDisabled={isReachedLinkLimit}
          activeDropdownCategory={'Project Info'}
          className="showroom-label_add"
          multiOtherLinkType={LINK_TYPES.PROJECT_INFO_OTHER}
        />
      </Panel>
      <Panel cx="artificat-panel-items__body__status">
        <LabeledInput
          label="Environments:"
          cx="add-to-showroom-modal__body__status__title"
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.HOME_SITE]}
          className="showroom-label"
          tooltip={[tooltips.HOME_SITE]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.TEST_ENVIRONMENT]}
          className="showroom-label"
          tooltip={[tooltips.TEST_ENVIRONMENT]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.ENVIRONMENT_OTHER]}
          className="showroom-label"
          isRemovable
          hideIconContainer
        />
        <LinksWithDropdownRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[
            LINK_TYPES.HOME_SITE,
            LINK_TYPES.TEST_ENVIRONMENT,
            LINK_TYPES.ENVIRONMENT_OTHER,
          ]}
          caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
          isDisabled={isReachedLinkLimit}
          activeDropdownCategory={'Environments'}
          className="showroom-label_add"
          multiOtherLinkType={LINK_TYPES.ENVIRONMENT_OTHER}
        />
      </Panel>
      <Panel cx="artificat-panel-items__body__status">
        <LabeledInput
          label="Promotional Artifacts:"
          cx="add-to-showroom-modal__body__status__title"
        />
        <UploadFile
          lens={lens}
          label="Case Study"
          projectLinks={projectLinks}
          tooltip={tooltips.CASE_STUDY}
          className="showroom-label__files"
          targetLinkType={LINK_TYPES.CASE_STUDY}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.PROMO_SOLUTION_HUB]}
          className="showroom-label"
          tooltip={[tooltips.PROMO_SOLUTION_HUB]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.PROMO_PRESALES_PORTAL]}
          className="showroom-label"
          tooltip={[tooltips.PROMO_PRESALES_PORTAL]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.SHOWCASE]}
          className="showroom-label"
          tooltip={[tooltips.SHOWCASE]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.CHALLENGE_SOLVED]}
          className="showroom-label"
          isRemovable
          hideIconContainer
        />
        <LinksWithDropdownRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[
            LINK_TYPES.CASE_STUDY,
            LINK_TYPES.PROMO_SOLUTION_HUB,
            LINK_TYPES.PROMO_PRESALES_PORTAL,
            LINK_TYPES.SHOWCASE,
            LINK_TYPES.CHALLENGE_SOLVED,
          ]}
          caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
          isDisabled={isReachedLinkLimit}
          activeDropdownCategory={'Promotional artifacts'}
          className="showroom-label_add"
          multiOtherLinkType={LINK_TYPES.CHALLENGE_SOLVED}
        />
      </Panel>
      <Panel cx="artificat-panel-items__body__status">
        <LabeledInput
          label="Videos:"
          cx="add-to-showroom-modal__body__status__title"
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_LINK]}
          className="showroom-label"
          tooltip={[tooltips.VIDEO_LINK]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_PROMO]}
          className="showroom-label"
          tooltip={[tooltips.VIDEO_PROMO]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_WALKTHROUGH]}
          className="showroom-label"
          tooltip={[tooltips.VIDEO_WALKTHROUGH]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_OTHER]}
          className="showroom-label"
          isRemovable
          hideIconContainer
        />
        <LinksWithDropdownRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[
            LINK_TYPES.VIDEO_LINK,
            LINK_TYPES.VIDEO_PROMO,
            LINK_TYPES.VIDEO_WALKTHROUGH,
            LINK_TYPES.VIDEO_OTHER,
          ]}
          caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
          isDisabled={isReachedLinkLimit}
          activeDropdownCategory={'Videos'}
          className="showroom-label_add"
          multiOtherLinkType={LINK_TYPES.VIDEO_OTHER}
        />
      </Panel>
      <Panel cx="artificat-panel-items__body__status">
        <LabeledInput
          label="Contacts:"
          cx="add-to-showroom-modal__body__status__title"
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.TEAMS_CHANNEL]}
          className="showroom-label"
          tooltip={[tooltips.TEAMS_CHANNEL]}
          isRemovable
          hideIconContainer
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.EMAIL]}
          className="showroom-label"
          placeholder={'sample@domain.com'}
          tooltip={[tooltips.EMAIL]}
          isRemovable
          hideIconContainer
        />
        <LinksWithDropdownRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.TEAMS_CHANNEL, LINK_TYPES.EMAIL]}
          caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
          isDisabled={isReachedLinkLimit}
          activeDropdownCategory={'Contact'}
          reachTypesLimitTooltip={CONTACT_LINKS_LIMIT_TOOTIP_INFO}
          className="showroom-label_add"
        />
      </Panel>
    </Panel>
  );
};

export default Artifacts;
