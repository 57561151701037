export const getProjectRole = (position: string | undefined) => {
  switch (position) {
    case 'Scrum Master':
      return 'SCRUM_MASTER';
    case 'Business Analyst':
      return 'BUSINESS_ANALYST';
    case 'Product Manager':
      return 'PRODUCT_MANAGER';
    case 'Project Owner':
      return 'PROJECT_OWNER';
    case 'Solution Architect':
      return 'SOLUTION_ARCHITECT';

    default:
      break;
  }
};

export const projectRoles = [
  'PROJECT_OWNER',
  'SCRUM_MASTER',
  'PRODUCT_MANAGER',
  'BUSINESS_ANALYST',
  'SOLUTION_ARCHITECT',
];

export const getGitlabTooltip = (gitlabUrl: string) =>
  `Store your code in <a href="${gitlabUrl}" target="_blank"><span style="text-decoration: underline;">the gitlab group provided</span></a> or link to GitHub or EPAM GitLab with an internal permission level`;
