import { ModalBlocker } from '@epam/promo';
import { IModal, useUuiContext } from '@epam/uui';
import ConfirmLeavingModal from 'modules/project-management/update/components/confirm-leaving';
import React from 'react';

export const useLeaveConfirm = (modalProps: any, isChanged: boolean) => {
  const svc = useUuiContext();

  const callbackModal = () => {
    modalProps.abort();
  };

  const showLeaveConfirmation = () => {
    isChanged
      ? svc.uuiModals
          .show((confirmModalProps: IModal<boolean>) => (
            <ModalBlocker {...confirmModalProps}>
              <ConfirmLeavingModal
                modalProps={confirmModalProps}
                callback={callbackModal}
              />
            </ModalBlocker>
          ))
          .catch(() => null)
      : callbackModal();
  };

  return showLeaveConfirmation;
};
