import React from 'react';
import { FlexCell, FlexRow, Text } from '@epam/promo';
import { IProjectTime } from '../modal';
import { dateFormatter } from '../../../../../utils/dateUtils';

const ProjectTime = ({
  plannedStartDate,
  plannedEndDate,
  lastUpdate,
}: IProjectTime): React.ReactElement => {
  return (
    <FlexCell width="auto" cx="project-time">
      <FlexRow cx="project-time__item">
        <Text cx="project-time__title time-text">Last update</Text>
        <Text cx="project-time__value time-text">
          {dateFormatter(lastUpdate)}
        </Text>
      </FlexRow>
      <FlexRow cx="project-time__item">
        <Text cx="project-time__title time-text">Start date</Text>
        <Text cx="project-time__value time-text">
          {dateFormatter(plannedStartDate)}
        </Text>
      </FlexRow>
      <FlexRow cx="project-time__item">
        <Text cx="project-time__title time-text">End date</Text>
        <Text cx="project-time__value time-text">
          {dateFormatter(plannedEndDate)}
        </Text>
      </FlexRow>
    </FlexCell>
  );
};

export default ProjectTime;
