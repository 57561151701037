import { DropdownContainer, FlexRow } from '@epam/promo';
import { Dispatch, SetStateAction } from 'react';
import { ILens } from '@epam/uui';
import { addLink, getLinkByType } from './helpers/utils';
import { Text } from '@epam/uui-components';
import { useRef, useEffect } from 'react';
import { ProjectLinkType } from 'modules/project-management/models/interfaces';

export interface ILinksDropdown {
  lens: ILens<any>;
  options: ProjectLinkType[];
  setActiveDropdown: Dispatch<SetStateAction<string>>;
  multiOtherLinkType?: string;
}

const LinksDropdown = ({
  lens,
  options,
  setActiveDropdown,
  multiOtherLinkType,
}: ILinksDropdown) => {
  const dropdownRef = useRef<HTMLElement>();

  const handleClick = (e: any) => {
    if (
      dropdownRef?.current !== e.target ||
      !dropdownRef?.current!.contains(e.target)
    ) {
      setActiveDropdown('');
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, []);

  return (
    <DropdownContainer placement="top" cx={'links-dropdown'} ref={dropdownRef}>
      {options.map((option: ProjectLinkType, index: number) => {
        const isLinkTypeUserAlreadyAdded = !multiOtherLinkType
          ? getLinkByType(lens, option.value)
          : getLinkByType(lens, option.value) &&
            option.value !== multiOtherLinkType;
        if (isLinkTypeUserAlreadyAdded) return null;

        return (
          <FlexRow
            key={index}
            padding="12"
            cx={'links-dropdown__items'}
            onClick={() => {
              setActiveDropdown('');
              addLink(lens, {
                link: '',
                linkType: option.value,
              });
            }}
          >
            <Text>{option.label}</Text>
          </FlexRow>
        );
      })}
    </DropdownContainer>
  );
};

export default LinksDropdown;
