import { SuccessNotification, Text } from '@epam/promo';
import { useUuiContext } from '@epam/uui';
import { useMutation } from '@tanstack/react-query';

import { NOTIFICATION_MESSAGES } from 'constants/uiConstants';

import React, { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import projectManagementService from 'services/api/project-management/projectManagementService';
import { queryClient } from 'App';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

const ShowroomRequest = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();
  const svc = useUuiContext();
  const [action] = useState<string | null>(searchParams.get('action'));

  const [isDone, setDone] = useState<boolean>(false);

  const updateProject = async () =>
    queryClient.refetchQueries([
      QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
      projectId,
    ]);

  const acceptAddProjectToShowroomRequest = useMutation<
    any,
    any,
    { id: number }
  >(({ id }) => projectManagementService.acceptShowroomRequest(id), {
    onSuccess: async () => {
      await updateProject();
      setDone(true);
      svc.uuiNotifications.show(
        (props) => (
          <SuccessNotification {...props}>
            <Text>{NOTIFICATION_MESSAGES.success.acceptedShowroomRequest}</Text>
          </SuccessNotification>
        ),
        { position: 'bot-left' }
      );
    },
    onError: (error) => {
      setDone(true);
      useCloseBlocker(svc);
      svc.uuiNotifications
        .show((props) => (
          <DefaultError notificationProps={props} error={error} />
        ))
        .catch(() => null);
    },
  });

  const declineAddProjectToShowroomRequest = useMutation<
    any,
    any,
    { id: number }
  >(({ id }) => projectManagementService.declineShowroomRequest(id), {
    onSuccess: async () => {
      await updateProject();
      setDone(true);
      svc.uuiNotifications.show(
        (props) => (
          <SuccessNotification {...props}>
            <Text>{NOTIFICATION_MESSAGES.success.declinedShowroomRequest}</Text>
          </SuccessNotification>
        ),
        { position: 'bot-left' }
      );
    },
    onError: (error) => {
      setDone(true);
      useCloseBlocker(svc);
      svc.uuiNotifications
        .show((props) => (
          <DefaultError notificationProps={props} error={error} />
        ))
        .catch(() => null);
    },
  });

  const revokeAddProjectToShowroomRequest = useMutation<
    any,
    any,
    { id: number }
  >(({ id }) => projectManagementService.revokeShowroomRequest(id), {
    onSuccess: async () => {
      await updateProject();
      setDone(true);
      svc.uuiNotifications.show(
        (props) => (
          <SuccessNotification {...props}>
            <Text>{NOTIFICATION_MESSAGES.success.revokedShowroomRequest}</Text>
          </SuccessNotification>
        ),
        { position: 'bot-left' }
      );
    },
    onError: (error) => {
      setDone(true);
      useCloseBlocker(svc);
      svc.uuiNotifications
        .show((props) => (
          <DefaultError notificationProps={props} error={error} />
        ))
        .catch(() => null);
    },
  });

  useEffect(() => {
    if (projectId) {
      switch (action) {
        case 'accept':
          acceptAddProjectToShowroomRequest.mutate({
            id: parseInt(projectId),
          });
          break;
        case 'decline':
          declineAddProjectToShowroomRequest.mutate({
            id: parseInt(projectId),
          });
          break;
        case 'revoke':
          revokeAddProjectToShowroomRequest.mutate({
            id: parseInt(projectId),
          });
          break;
        default:
          setDone(true);
      }
    }
  }, [projectId, action]);

  return isDone ? <Navigate to={`/dashboard/project/${projectId}`} /> : <></>;
};

export default ShowroomRequest;
