import { FlexRow, WarningAlert } from '@epam/promo';

const WarningMessage = ({
  message,
  className,
}: {
  message: string;
  className: string;
}) => (
  <FlexRow cx={className}>
    <WarningAlert>{message}</WarningAlert>
  </FlexRow>
);

export default WarningMessage;
