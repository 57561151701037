import React from 'react';
import { Text } from '@epam/promo';
import './styles.scss';

export interface ICounter {
  currentValue: number;
  maxValue: number;
}

function Counter({ currentValue, maxValue }: ICounter) {
  return (
    <>
      <Text fontSize={'12'} lineHeight={'18'} cx={'counter'}>
        {currentValue}/{maxValue}
      </Text>
    </>
  );
}

export { Counter };
