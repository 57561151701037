import { FC } from 'react';
import { useState } from 'react';
import { IOpportunity } from '../../../../../list-opportunities/models/model';
import dayjs from 'dayjs';
import { Tooltip, Button } from '@epam/promo';
import opportunityService from 'services/api/opportunity/opportunityService';
import { ReactComponent as NavigationRefreshIcon } from '@epam/assets/icons/common/navigation-refresh-12.svg';
import useNotifications from 'hooks/useNotifications';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'App';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useUuiContext } from '@epam/uui';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { BlockerModal } from 'component/BlockerModal';

type Props = {
  opportunity: IOpportunity;
  projectId: number;
};

const initialButtonVisibility = (opportunity: IOpportunity): boolean => {
  return (
    dayjs().diff(opportunity.updated, 'month') >= 1 &&
    opportunity.applicants?.length === 0
  );
};

export const OpportunityRefreshButton: FC<Props> = ({
  opportunity,
  projectId,
}) => {
  const { showSuccess, showError } = useNotifications();
  const [refreshOpportunityVisible, setRefreshOpportunityVisible] = useState(
    () => initialButtonVisibility(opportunity)
  );
  const [disabled, setDisabled] = useState(false);
  const svc = useUuiContext();

  const refreshOpportunityMutation = useMutation<any, any>(
    () => opportunityService.refreshOpportunity(opportunity.id),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
        ]);
        useCloseBlocker(svc);
        setDisabled(false);
        showSuccess(
          'Your opportunity has been successfully refreshed and brought up to top in the opportunity list'
        );
        setRefreshOpportunityVisible(false);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        showError(error);
        setDisabled(false);
      },
    }
  );

  return refreshOpportunityVisible ? (
    <Tooltip content="No activity about your opportunity for 30 days, refresh to move it up in the list.">
      <Button
        icon={NavigationRefreshIcon}
        isDisabled={disabled}
        size="24"
        caption="Refresh"
        color="blue"
        fill="white"
        onClick={async () => {
          setDisabled(true);
          svc.uuiModals
            .show((props) => <BlockerModal modalProps={props} />, {
              modalId: 'blocker',
            })
            .catch(() => null);
          refreshOpportunityMutation.mutate();
        }}
      />
    </Tooltip>
  ) : null;
};
