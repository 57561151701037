import { CommonContexts } from '@epam/uui';
import { BlockerModal } from 'component/BlockerModal';

export const useBlocker = (svc: CommonContexts<any, any>) => {
  svc.uuiModals
    .show((props) => <BlockerModal modalProps={props} />, {
      modalId: 'blocker',
    })
    .catch(() => null);
};
