import { ProjectStatus } from 'models/enums';

export type WithPosition<T extends object> = T & {
  position: number | undefined;
};

export function checkDisabledStatus(
  currentProjectStatus: string,
  statusOption: string,
  isEditorModeEnabled: boolean
) {
  let disabledStatus: string[] = [];
  switch (currentProjectStatus) {
    case ProjectStatus.INITIAL:
      disabledStatus = [
        ProjectStatus.SUSPENDED,
        ProjectStatus.CLOSED,
        ProjectStatus.DELETE,
      ];
      break;
    case ProjectStatus.ACTIVE:
      disabledStatus = [ProjectStatus.INITIAL, ProjectStatus.DELETE];
      break;
    case ProjectStatus.SUSPENDED:
      disabledStatus = isEditorModeEnabled
        ? [ProjectStatus.INITIAL]
        : [ProjectStatus.INITIAL, ProjectStatus.DELETE];
      break;
    case ProjectStatus.CLOSED:
      disabledStatus = [
        ProjectStatus.INITIAL,
        ProjectStatus.SUSPENDED,
        ProjectStatus.DELETE,
      ];
      break;
    default:
      break;
  }
  return disabledStatus.includes(statusOption);
}

export function sortByPosition<T extends WithPosition<object>>(
  items: T[]
): T[] {
  return items?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
}
