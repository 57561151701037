import {
  Button,
  FlexSpacer,
  IconButton,
  LabeledInput,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  PickerInput,
  ScrollBars,
  SuccessNotification,
  Tooltip,
  Text,
  useForm,
} from '@epam/promo';
import {
  IModal,
  LazyDataSourceApiRequest,
  useArrayDataSource,
  useLazyDataSource,
  useUuiContext,
} from '@epam/uui';
import { useMutation } from '@tanstack/react-query';
import { NOTIFICATION_MESSAGES } from 'constants/uiConstants';
import {
  IRoleSubscription,
  SubscriptionObject,
} from 'modules/list-opportunities/models/model';
import { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { DefaultError } from 'component/notification/DefaultError';
import { queryClient } from 'App';
import { QUERY_KEYS } from 'constants/queryKeys';
import { ISkilloTech } from 'modules/project-list/components/project-card/modal';
import getSkilloResponse from '../../../project-management/update/components/technologies/utils';
import { ReactComponent as closeicon } from '@epam/assets/icons/common/navigation-close-24.svg';
import { ReactComponent as AddIcon } from '@epam/assets/icons/common/action-add-18.svg';
import opportunityService from 'services/api/opportunity/opportunityService';

type Props = {
  modalProps: IModal<string>;
  subscription: IRoleSubscription;
};

const PickerComponent = (props: any) => {
  const [value, setValue] = useState<string[]>([]);
  const items: {
    id: string;
    label: string;
  }[] = props.items;
  const pickerDataSource = useArrayDataSource({ items }, []);

  useEffect(() => setValue(props.passedValue || []), [props.passedValue]);

  const operateOptions = function (e: any, i: number) {
    setValue(e);
    props.operateOptions(e, i, 'pos');
  };

  return (
    <PickerInput
      dataSource={pickerDataSource}
      value={value}
      selectionMode="single"
      entityName="position"
      isInvalid={false}
      valueType="id"
      isRequired={true}
      disableClear={true}
      getName={(item: { id: string; label: string }) => item.label}
      onValueChange={(e) => operateOptions(e, props.index)}
    />
  );
};

const SubscriptionsModal = (props: Props) => {
  const [formSaveInProgress, setFormSaveInProgress] = useState<boolean>(false);
  const [isFormChanged, setIsFormChanged] = useState<boolean | null>(null);
  const svc = useUuiContext();
  const subscriptionObject: SubscriptionObject = {
    position: [],
    technologies: [],
  };

  const isNewSubscription = props.subscription.subscriptions.length == 0;

  const addIdToSkilloTech = function (subscription: SubscriptionObject[]) {
    const subscriptionTemp = subscription.map((s: SubscriptionObject) => {
      return {
        position: s.position,
        technologies: s?.technologies?.map((tech) => {
          return {
            skilloId: tech.skilloId,
            id: tech.skilloId,
            name: tech.name,
          };
        }),
      };
    });
    return subscriptionTemp;
  };

  const [formObject, setFormObject] = useState(
    isNewSubscription
      ? [Object.assign({}, subscriptionObject)]
      : addIdToSkilloTech(props.subscription.subscriptions)
  );
  const saveSubscriptionMutation = useMutation<
    any,
    any,
    { subscription: SubscriptionObject[] }
  >(
    () =>
      opportunityService.editSubscription(
        formObject.filter((item) => !!item.position.length)
      ),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          QUERY_KEYS.OPPORTUNITIES.SUBSCRIPTION_LIST,
        ]);
        svc.uuiNotifications
          .show(
            (props) => (
              <SuccessNotification {...props}>
                <Text>
                  {isNewSubscription
                    ? NOTIFICATION_MESSAGES.success.newSubscription
                    : NOTIFICATION_MESSAGES.success.changedSubscription}
                </Text>
              </SuccessNotification>
            ),
            { position: 'bot-left' }
          )
          .catch(() => null);
      },
      onError: (error) => {
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
      onSettled: () => {
        setFormSaveInProgress(false);
        useCloseBlocker(svc);
        props.modalProps.abort();
      },
    }
  );

  useEffect(() => {
    setIsFormChanged(isFormChanged === null ? false : true);
  }, [formObject]);

  const { lens, save } = useForm<SubscriptionObject[]>({
    value: formObject,
    onSave: async (subscriptionData) => {
      setFormSaveInProgress(true);
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);
      saveSubscriptionMutation.mutate({ subscription: subscriptionData });
    },
    beforeLeave: null,
    getMetadata: () => ({
      props: [
        {
          props: {
            position: { isRequired: false },
            technologies: { isRequired: false },
          },
        },
      ],
    }),
    validationOn: 'change',
  });

  const loadSkilloSkills = useCallback(
    (request: LazyDataSourceApiRequest<ISkilloTech, string, unknown>) => {
      return getSkilloResponse(request);
    },
    []
  );

  const skilloDS = useLazyDataSource(
    {
      api: loadSkilloSkills,
      selectAll: false,
    },
    []
  );

  const operateOptions = function (e: any, i: number, type: string) {
    const formObjectTemp = formObject;
    if (type === 'pos') {
      formObjectTemp[i].position = e;
    } else {
      formObjectTemp[i].technologies = e;
    }

    setFormObject(() => [...formObject]);
  };

  const addsubscriptionForm = function () {
    const formObjectTemp = formObject;
    formObjectTemp.push(Object.assign({}, subscriptionObject));
    setFormObject(() => [...formObjectTemp]);
  };

  const deletesubscriptionForm = function (i: number) {
    if (i === 0) {
      formObject[0].position = [];
      formObject[0].technologies = [];
    } else {
      formObject.splice(i, 1);
    }
    setFormObject(() => [...formObject]);
  };

  return (
    <ModalBlocker blockerShadow="dark" {...props.modalProps}>
      <ModalWindow cx={'subscribe-modal'}>
        <ModalHeader
          borderBottom
          title="Subscription"
          onClose={() => props.modalProps.abort()}
        />
        <ScrollBars hasTopShadow hasBottomShadow>
          <Panel background="white" cx="subscribe-modal__body">
            <Text
              fontSize={'16'}
              lineHeight={'24'}
              color="gray90"
              cx="subscribe-modal__body__description"
            >
              Pick the positions and technologies you are interested in to
              receive notifications about new opportunities.
            </Text>

            {formObject.map((item, index) => {
              return (
                <div
                  className="subscribe-modal__body__formContainer"
                  key={index}
                >
                  <Tooltip
                    content={
                      index === 0 &&
                      !formObject[0].position.length &&
                      "You don't have subscription"
                    }
                    placement="bottom"
                  >
                    <IconButton
                      cx="deleteFormButton"
                      icon={closeicon}
                      isDisabled={index === 0 && !formObject[0].position.length}
                      onClick={() => deletesubscriptionForm(index)}
                    />
                  </Tooltip>

                  <LabeledInput
                    cx="formInputLabel"
                    isRequired={true}
                    label="Position"
                  >
                    <PickerComponent
                      items={props.subscription.positions
                        .map((v) => ({
                          id: v.value,
                          label: v.label,
                        }))
                        .filter((v) => {
                          const tmp = formObject.map((iv) => iv.position);
                          return !tmp.includes(v.id) || tmp[index] == v.id;
                        })}
                      index={index}
                      passedValue={item.position}
                      operateOptions={operateOptions}
                    />
                  </LabeledInput>

                  <LabeledInput
                    label="Skills"
                    cx="subscribe-modal__body__technologies"
                  >
                    <PickerInput
                      dataSource={skilloDS}
                      onValueChange={(e) => operateOptions(e, index, 'tech')}
                      selectionMode={'multi'}
                      value={item.technologies}
                      valueType={'entity'}
                      isDisabled={item.position.length === 0}
                      placeholder="Add specific skills"
                      entityName="Technologies"
                      sorting={{ field: 'name', direction: 'asc' }}
                      getRowOptions={() => ({
                        checkbox: {
                          isVisible: true,
                          isDisabled: item?.technologies?.length >= 5,
                        },
                      })}
                    />
                  </LabeledInput>
                </div>
              );
            })}

            <Tooltip
              value={lens.get().length >= 5}
              placement={'bottom'}
              content="You have already reached the limit of 5 subscriptions"
            >
              <Button
                cx="addSubscriptionButton"
                caption={'Add Subscription'}
                icon={AddIcon}
                fill={'none'}
                onClick={() => addsubscriptionForm()}
                isDisabled={lens.get().length >= 5}
              />
            </Tooltip>
          </Panel>
        </ScrollBars>
        <ModalFooter borderTop>
          <FlexSpacer />
          <Button
            caption={'Cancel'}
            color={'gray50'}
            fill={'white'}
            onClick={props.modalProps.abort}
          />

          <Button
            caption={isNewSubscription ? 'Subscribe' : 'Save'}
            color={'blue'}
            onClick={save}
            isDisabled={
              formSaveInProgress ||
              !isFormChanged ||
              formObject.filter((s) => s.position.length > 0).length === 0
            }
          />
        </ModalFooter>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default SubscriptionsModal;
