import { useUuiContext } from '@epam/uui';
import { useQuery } from '@tanstack/react-query';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import {
  DictionaryModel,
  IProjectMemberPosition,
} from 'modules/project-list/models/interfaces';
import { createContext, FC, useContext } from 'react';
import dictionaryService from 'services/api/dictionary/dictionaryService';
import { QUERY_KEYS } from '../../constants/queryKeys';

type ContextType = {
  projectPositions?: DictionaryModel<IProjectMemberPosition> | null;
  refetch: () => void;
};

const useDictionaryProvider = (): ContextType => {
  const svc = useUuiContext();

  const { data: projectPositions, refetch } = useQuery(
    [QUERY_KEYS.DICTIONARY.PROJECT_POSITIONS],
    () => dictionaryService.getDictionary('project-position'),
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return {
    projectPositions: {
      name: 'project-position',
      items: projectPositions
        ? projectPositions.items.filter(
            (item) => item.label !== 'Project Owner'
          )
        : [],
    },
    refetch,
  };
};

const DictionaryContext = createContext<ContextType>({} as ContextType);

export const DictionaryProvider: FC<{}> = ({ children }) => {
  const auth = useDictionaryProvider();

  return (
    <DictionaryContext.Provider value={auth}>
      {children}
    </DictionaryContext.Provider>
  );
};

export const useDictionary = () => useContext(DictionaryContext);
