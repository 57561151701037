import {
  FlexRow,
  Text,
  Panel,
  Tooltip,
  Button,
  Badge,
  EpamAdditionalColor,
} from '@epam/promo';
import { IBackendProjectInfoModel } from '../modal';
import { ReactComponent as GitIcon } from 'assets/svg/git.svg';
import Avatar from 'component/Avatar';
import './styles.scss';
import { BadgesList } from '../../../../../component/BadgesList/badges-list';
import projectListService from 'services/api/project-list/projectListService';
import {
  PROJECT_STATUS,
  PROJECT_STATUS_COLOR,
} from '../../../../../constants/uiConstants';
import React from 'react';
import OverflowTooltip from '../../../../../utils/OverflowTooltip';

const ProjectInfo = ({
  name,
  projectCoverImgUrl,
  description,
  keywords,
  technologies,
  dataUpdatedAt,
  status,
  labUrl,
}: IBackendProjectInfoModel & { dataUpdatedAt: number; status: string }) => {
  const techNameList = technologies.map((tech) => tech.name);

  return (
    <FlexRow cx="project-info">
      <Avatar
        lastUpdatedTime={dataUpdatedAt}
        img={projectListService.getProfileProjectPicture(projectCoverImgUrl)}
        alt="Project cover"
        size={'144'}
        initials={name.charAt(0)}
        cx={'project-info__cover'}
      />
      <Panel cx={'project-info__container'}>
        <div>
          <FlexRow cx={'card-header'}>
            <OverflowTooltip
              text={name}
              cx="card-header__title"
              font="museo-sans"
            />
            <FlexRow cx={'card-header__controls'}>
              <Badge
                cx={'card-header__status-badge'}
                color={
                  PROJECT_STATUS_COLOR[
                    status as keyof typeof PROJECT_STATUS_COLOR
                  ] as EpamAdditionalColor
                }
                size={'18'}
                caption={PROJECT_STATUS[status as keyof typeof PROJECT_STATUS]}
                fill={'semitransparent'}
              />
              <Tooltip
                content={"Click to open project's Gitlab"}
                placement={'top'}
              >
                <Button
                  cx={'card-header__git-button'}
                  icon={GitIcon}
                  iconPosition={'left'}
                  caption={'Git'}
                  fill={'white'}
                  size={'24'}
                  href={labUrl}
                  target={'_blank'}
                />
              </Tooltip>
            </FlexRow>
          </FlexRow>
          <Text cx="project-info__description" fontSize="16">
            <span className="text-display">{description}</span>
          </Text>
        </div>
        <div>
          {keywords.length > 0 && (
            <BadgesList
              className="project-info__badges"
              color={'green'}
              items={keywords}
            />
          )}
          {techNameList.length > 0 && (
            <BadgesList
              className="project-info__badges"
              items={techNameList}
              color={'blue'}
            />
          )}
        </div>
      </Panel>
    </FlexRow>
  );
};

export default ProjectInfo;
