import { IProjectMember } from '../modules/project-list/components/project-card/modal';
import { ProjectPosition } from '../models/enums';

export const sortMembers = (members: IProjectMember[]) => {
  const sortedTeamMembers = [...members];

  sortedTeamMembers.forEach((member, index) => {
    if (member.position === ProjectPosition.PO) {
      sortedTeamMembers.unshift(...sortedTeamMembers.splice(index, 1));
    }
  });

  return sortedTeamMembers;
};

export const getUniqMemberLocations = (members: IProjectMember[]) =>
  members
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.country === value.country && t.city === value.city
        )
    )
    .sort((a, b) =>
      a.position === ProjectPosition.PO
        ? -1
        : b.position === ProjectPosition.PO
        ? 1
        : a.country.localeCompare(b.country)
    );
