import './opportunities.scss';
import React, { FC, useRef, useState } from 'react';
import {
  FlexCell,
  FlexRow,
  Paginator,
  SearchInput,
  Spinner,
} from '@epam/promo';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import useDebounce from '../../component/hooks/use-debounce';
import { OpportunityCard } from './components/opportunity-card/opportunity-card';
import EmptyPanel from '../project-list/components/empty-panel';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants/queryKeys';
import opportunityService from '../../services/api/opportunity/opportunityService';
import { OPPORTUNITY_PAGE_SIZE } from '../../constants/uiConstants';
import { useCloseBlocker } from '../../hooks/useCloseBlocker';
import { DefaultError } from '../../component/notification/DefaultError';
import { useUuiContext } from '@epam/uui';
import { OpportunityFilter } from './components/opportunity-filter';

export interface Props {
  editorSwitch: boolean;
  pathName: string;
}

const OpportunityList: FC<Props> = ({ editorSwitch, pathName }) => {
  const svc = useUuiContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const [prevSearchValue, setPrevSearchValue] = useState<string>('');
  const [positions, setPositions] = useState<string[]>([]);
  const [technologies, setTechnologies] = useState<string[]>([]);
  const totalItemRef = useRef(0);

  const setNavigationParams = (searchPhrase: string) => {
    const params = { search: searchPhrase };
    handleNavigate(params);
    setPage(1);
  };

  useDebounce(searchValue, 400, (value) => {
    if (value.length >= 2 || (prevSearchValue && value === '')) {
      setPrevSearchValue(value);
      setNavigationParams(value);
    }
  });

  const handleNavigate = (params: { search: string }) => {
    navigate({
      pathname: pathName,
      search: `?${createSearchParams(params)}`,
    });
  };

  const { data } = useQuery(
    [
      QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
      searchValue,
      page,
      positions,
      technologies,
    ],
    () =>
      opportunityService.getOpportunities(
        OPPORTUNITY_PAGE_SIZE,
        (page - 1) * OPPORTUNITY_PAGE_SIZE,
        searchValue,
        positions,
        technologies
      ),
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  if (data) {
    totalItemRef.current = data?.totalNumber;
  }

  return (
    <div className="dashboard-container">
      <FlexRow cx="opportunity" alignItems="top">
        <div className="opportunity__filter">
          <OpportunityFilter
            onPositionsChange={(value) => {
              setPositions(value);
              setPage(1);
            }}
            onTechnologiesChange={(value) => {
              setTechnologies(value);
              setPage(1);
            }}
          />
        </div>
        <div className="opportunity__content">
          <FlexCell width="auto" cx="search-container__search_div">
            <SearchInput
              value={searchValue}
              onValueChange={(value) => setSearchValue(value || '')}
              placeholder="Search by opportunity description, project name, project keywords and project owner"
              debounceDelay={1000}
              cx="search-container__search"
            />
          </FlexCell>
          {!data ? (
            <Spinner color="blue" cx="loading-spinner" />
          ) : (
            <div className="opportunity__list">
              {data.opportunityList.length ? (
                data.opportunityList.map((item) => (
                  <OpportunityCard
                    key={item.opportunity.id}
                    editorSwitch={editorSwitch}
                    opportunityItem={item}
                  />
                ))
              ) : (
                <EmptyPanel />
              )}
            </div>
          )}

          {totalItemRef.current > OPPORTUNITY_PAGE_SIZE && data && (
            <div className="pagination">
              <Paginator
                size="30"
                totalPages={Math.ceil(
                  totalItemRef.current / OPPORTUNITY_PAGE_SIZE
                )}
                value={page}
                onValueChange={(value) => {
                  window.scroll(0, 0);
                  setPage(value);
                }}
              />
            </div>
          )}
        </div>
      </FlexRow>
    </div>
  );
};

export default OpportunityList;
