import React, { memo } from 'react';
import { FlexCell, FlexRow, FlexSpacer, IconButton, Text } from '@epam/promo';
import { ReactComponent as CompanyLogo } from 'assets/svg/company-logo.svg';

import './styles.scss';
import dayjs from 'dayjs';
const Footer = () => {
  const copyrightString = `© EPAM ${dayjs().year()}. All Rights reserved`;
  return (
    <div className="footer">
      <div className="footer-container">
        <FlexCell grow={1}>
          <FlexRow
            borderBottom="gray40"
            vPadding="24"
            cx="footer-container__row"
          >
            <IconButton
              icon={CompanyLogo}
              cx="company-logo"
              href="https://www.epam.com/"
            />
            <Text
              fontSize="12"
              lineHeight="30"
              color="gray5"
              font="sans"
              cx="footer-container__row__text"
            >
              {copyrightString}
            </Text>
            <FlexSpacer />
            <Text
              fontSize="12"
              lineHeight="30"
              color="gray5"
              font="sans"
              cx="footer-container__row__text"
            >
              {' '}
              For more information: garage@epam.com
            </Text>
          </FlexRow>
        </FlexCell>
      </div>
    </div>
  );
};

export default memo(Footer);
