import { Panel, LabeledInput, Text } from '@epam/promo';
import { addLink } from '../helpers/utils';
import { ILens } from '@epam/uui';
import { tooltips } from '../constants';
import {
  LINKS_LIMIT_TOOLTIP_INFO,
  BUTTON_CAPTION_VARIANT,
  CONTACT_LINKS_LIMIT_TOOTIP_INFO,
} from '../constants';
import { LINK_TYPES } from '../../project-links/constants';
import LinksRenderer from '../../../../../../component/Artifacts/LinksRenderer';
import AddLinkButton from '../../../../../../component/Artifacts/AddLinkButton';
import LinksWithDropdownRenderer from 'component/Artifacts/LinksWithDropdownRenderer';
import { MAX_PROJECT_LINKS } from 'constants/uiConstants';
import UploadFile from 'component/Artifacts/UploadFile';
import { ProjectLinkType } from 'modules/project-management/models/interfaces';

const OptionalArtifactsForm = ({
  lens,
  dictionary,
  projectLinks,
  isReachedLinkLimit,
  isProjectInShowroom,
  className,
  currentLinkCount,
}: {
  lens: ILens<any>;
  dictionary: ProjectLinkType[];
  projectLinks: any;
  isReachedLinkLimit: boolean;
  isProjectInShowroom?: boolean;
  className?: string;
  currentLinkCount: number;
}) => {
  return (
    <Panel background="white" cx={className ? `${className}__body` : ''}>
      <Panel cx={className ? `${className}__body__status` : ''}>
        <LabeledInput
          label="Environments:"
          cx={className ? `${className}__body__status__title` : ''}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.HOME_SITE]}
          className="showroom-label"
          tooltip={[tooltips.HOME_SITE]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.TEST_ENVIRONMENT]}
          className="showroom-label"
          tooltip={[tooltips.TEST_ENVIRONMENT]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.ENVIRONMENT_OTHER]}
          className="showroom-label"
          isRemovable
        />
        {isProjectInShowroom ? (
          <LinksWithDropdownRenderer
            lens={lens}
            projectLinks={projectLinks}
            dictionary={dictionary}
            targetLinkTypes={[
              LINK_TYPES.HOME_SITE,
              LINK_TYPES.TEST_ENVIRONMENT,
              LINK_TYPES.ENVIRONMENT_OTHER,
            ]}
            caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
            isDisabled={isReachedLinkLimit}
            activeDropdownCategory={'Environments'}
            className="showroom-label_add"
            multiOtherLinkType={`${LINK_TYPES.ENVIRONMENT_OTHER}`}
          />
        ) : (
          <AddLinkButton
            fill="white"
            color="blue"
            className="showroom-label_add"
            tooltip={LINKS_LIMIT_TOOLTIP_INFO}
            caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
            isDisabled={isReachedLinkLimit}
            onClick={() =>
              addLink(lens, {
                id: null,
                link: '',
                linkType: LINK_TYPES.ENVIRONMENT_OTHER,
              })
            }
          />
        )}
      </Panel>
      <Panel cx={className ? `${className}__body__status` : ''}>
        <LabeledInput
          label="Promotional artifacts:"
          cx={className ? `${className}__body__status__title` : ''}
        />
        <UploadFile
          lens={lens}
          label="Case Study"
          projectLinks={projectLinks}
          tooltip={tooltips.CASE_STUDY}
          className="showroom-label__files"
          targetLinkType={LINK_TYPES.CASE_STUDY}
          isRemovable
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.PROMO_SOLUTION_HUB]}
          className="showroom-label"
          tooltip={[tooltips.PROMO_SOLUTION_HUB]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.PROMO_PRESALES_PORTAL]}
          className="showroom-label"
          tooltip={[tooltips.PROMO_PRESALES_PORTAL]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.SHOWCASE]}
          className="showroom-label"
          tooltip={[tooltips.SHOWCASE]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.CHALLENGE_SOLVED]}
          className="showroom-label"
          isRemovable
        />
        {isProjectInShowroom ? (
          <LinksWithDropdownRenderer
            lens={lens}
            projectLinks={projectLinks}
            dictionary={dictionary}
            targetLinkTypes={[
              LINK_TYPES.PROMO_SOLUTION_HUB,
              LINK_TYPES.PROMO_PRESALES_PORTAL,
              LINK_TYPES.SHOWCASE,
              LINK_TYPES.CHALLENGE_SOLVED,
            ]}
            caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
            isDisabled={isReachedLinkLimit}
            activeDropdownCategory={'Environments'}
            className="showroom-label_add"
            multiOtherLinkType={`${LINK_TYPES.CHALLENGE_SOLVED}`}
          />
        ) : (
          <AddLinkButton
            fill="white"
            color="blue"
            className="showroom-label_add"
            tooltip={LINKS_LIMIT_TOOLTIP_INFO}
            caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
            isDisabled={isReachedLinkLimit}
            onClick={() =>
              addLink(lens, {
                id: null,
                link: '',
                linkType: LINK_TYPES.CHALLENGE_SOLVED,
              })
            }
          />
        )}
      </Panel>
      <Panel cx={className ? `${className}__body__status` : ''}>
        <LabeledInput
          label="Videos:"
          cx={className ? `${className}__body__status__title` : ''}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_LINK]}
          className="showroom-label"
          tooltip={[tooltips.VIDEO_LINK]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_PROMO]}
          className="showroom-label"
          tooltip={[tooltips.VIDEO_PROMO]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_WALKTHROUGH]}
          className="showroom-label"
          tooltip={[tooltips.VIDEO_WALKTHROUGH]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.VIDEO_OTHER]}
          className="showroom-label"
          isRemovable
        />
        {isProjectInShowroom ? (
          <LinksWithDropdownRenderer
            lens={lens}
            projectLinks={projectLinks}
            dictionary={dictionary}
            targetLinkTypes={[
              LINK_TYPES.VIDEO_LINK,
              LINK_TYPES.VIDEO_PROMO,
              LINK_TYPES.VIDEO_WALKTHROUGH,
              LINK_TYPES.VIDEO_OTHER,
            ]}
            caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
            isDisabled={isReachedLinkLimit}
            activeDropdownCategory={'Environments'}
            className="showroom-label_add"
            multiOtherLinkType={`${LINK_TYPES.VIDEO_OTHER}`}
          />
        ) : (
          <AddLinkButton
            fill="white"
            color="blue"
            className="showroom-label_add"
            tooltip={LINKS_LIMIT_TOOLTIP_INFO}
            caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
            isDisabled={isReachedLinkLimit}
            onClick={() =>
              addLink(lens, {
                id: null,
                link: '',
                linkType: LINK_TYPES.VIDEO_OTHER,
              })
            }
          />
        )}
      </Panel>
      <Panel cx={className ? `${className}__body__status` : ''}>
        <LabeledInput
          label="Contact:"
          cx={className ? `${className}__body__status__title` : ''}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.TEAMS_CHANNEL]}
          className="showroom-label"
          tooltip={[tooltips.TEAMS_CHANNEL]}
          isRemovable={isProjectInShowroom}
        />
        <LinksRenderer
          lens={lens}
          projectLinks={projectLinks}
          dictionary={dictionary}
          targetLinkTypes={[LINK_TYPES.EMAIL]}
          className="showroom-label"
          placeholder={'sample@domain.com'}
          tooltip={[tooltips.EMAIL]}
          isRemovable={isProjectInShowroom}
        />
        {isProjectInShowroom && (
          <LinksWithDropdownRenderer
            lens={lens}
            projectLinks={projectLinks}
            dictionary={dictionary}
            targetLinkTypes={[LINK_TYPES.TEAMS_CHANNEL, LINK_TYPES.EMAIL]}
            caption={BUTTON_CAPTION_VARIANT.ADD_LINK}
            isDisabled={isReachedLinkLimit}
            reachTypesLimitTooltip={CONTACT_LINKS_LIMIT_TOOTIP_INFO}
            activeDropdownCategory={'Environments'}
            className="showroom-label_add"
          />
        )}
        <Text cx="add-to-showroom-modal__counter">
          {currentLinkCount} / {MAX_PROJECT_LINKS}
        </Text>
      </Panel>
    </Panel>
  );
};

export default OptionalArtifactsForm;
