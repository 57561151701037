import { OPPORTUNITY_API } from 'constants/apiConstants';
import { BaseService } from '../baseService';
import {
  IOpportunityItem,
  SubscriptionObject,
} from '../../../modules/list-opportunities/models/model';

class OpportunityServices extends BaseService {
  private static instance: OpportunityServices;

  public static getInstance(): OpportunityServices {
    if (!OpportunityServices.instance) {
      OpportunityServices.instance = new OpportunityServices();
    }
    return OpportunityServices.instance;
  }

  public getOpportunities(
    amount: number,
    startPoint: number,
    searchPhrase: string,
    positions?: string[],
    technologies?: string[]
  ) {
    return OpportunityServices.instance.get<{
      opportunityList: IOpportunityItem[];
      totalNumber: number;
    }>(
      OPPORTUNITY_API.GetOpportunities(
        amount,
        startPoint,
        searchPhrase,
        positions,
        technologies
      )
    );
  }

  public delOpportunity(opportunityId: number) {
    return OpportunityServices.instance.delete(
      OPPORTUNITY_API.DeleteOpportunity(opportunityId)
    );
  }

  public editOpportunity(opportunityId: number, opportunityData: any) {
    return OpportunityServices.instance.put(
      OPPORTUNITY_API.EditOpportunity(opportunityId),
      opportunityData
    );
  }

  public joinOpportunity(opportunityId: number, comment: string) {
    return OpportunityServices.instance.post<IOpportunityItem>(
      OPPORTUNITY_API.RequestToJoinOpportunity(opportunityId),
      { comment }
    );
  }

  public getSubscription() {
    return OpportunityServices.instance.get<string[]>(
      OPPORTUNITY_API.GetSubscription
    );
  }

  public editSubscription(positions: SubscriptionObject[]) {
    return OpportunityServices.instance.put<string[]>(
      OPPORTUNITY_API.EditSubscription,
      positions
    );
  }

  public refreshOpportunity(opportunityId: number) {
    return OpportunityServices.instance.post(
      OPPORTUNITY_API.RefreshOpportunity(opportunityId)
    );
  }
}

export default OpportunityServices.getInstance();
