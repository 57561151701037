import React, { useRef, useState } from 'react';
import {
  Button,
  FlexCell,
  FlexRow,
  LabeledInput,
  Panel,
  Text,
} from '@epam/promo';
import Avatar from '../../../../../component/Avatar';
import placeholderCover from 'assets/svg/add-cover.svg';
import { ILens, useUuiContext } from '@epam/uui';
import projectListService from 'services/api/project-list/projectListService';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
import './styles.scss';
import CropImageModal from './image-crop';
import { AlertModal } from '../../../../../component/AlertModal';
import { ProjectDetails } from 'modules/project-management/models/interfaces';
import useNotificationsModal from 'component/NotifiactionsModal';

const ALLOWED_IMG_TYPES = ['image/jpeg', 'image/png'];

function Cover({ lens }: { lens: ILens<ProjectDetails['cover']> }) {
  const svc = useUuiContext();
  const imgInputRef = useRef<HTMLInputElement>(null);

  const existingImage = lens.prop('imgUrl').toProps().value;
  const uploadedImage = lens.prop('uploadedImage').toProps().value;
  const [projectCover, setProjectCover] = useState<
    string | { base64file: string }
  >(
    uploadedImage
      ? { base64file: uploadedImage }
      : existingImage
      ? projectListService.getProfileProjectPicture(existingImage)
      : placeholderCover
  );

  const isPlaceholderImage = () => projectCover === placeholderCover;
  const maxFileSize = 2 * 1024 * 1024; // 2mb
  const { warningNotification } = useNotificationsModal();

  async function handleCoverUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files;
    if (!file) {
      return;
    }
    const imgType = file[0].type;
    if (!ALLOWED_IMG_TYPES.includes(imgType)) {
      warningNotification('Please upload only .jpg or .png files.');
      return;
    }
    if (file[0].size > maxFileSize) {
      warningNotification('File size exceeds the maximum allowed size (2 MB).');
      return;
    }
    const uploadedImage = URL.createObjectURL(file[0]);
    const croppedImage = await svc.uuiModals
      .show((props) => (
        <CropImageModal modalProps={props} imageSrc={uploadedImage} />
      ))
      .catch(() => null);

    if (croppedImage !== null) {
      setProjectCover({ base64file: croppedImage as string });
      lens.prop('uploadedImage').set(croppedImage as string);
    }
    e.target.value = '';
  }

  function handleCoverRemoval() {
    svc.uuiModals
      .show((props) => (
        <AlertModal
          modalProps={props}
          alertContent={{
            title: 'Delete project cover? ',
            description:
              'Are you sure you want to delete the project cover image? You will not be able to restore it.',
            cancelBtn: true,
            closeText: 'Delete cover',
          }}
        />
      ))
      .then(async () => {
        setProjectCover(placeholderCover);
        lens.prop('uploadedImage').set('');
        lens.prop('imgUrl').set('');
      })
      .catch(() => null);
  }

  return (
    <>
      <FlexCell minWidth={150} cx={'project-cover-container'}>
        <Panel>
          <LabeledInput
            label={'Project cover'}
            cx={'project-cover-container__label'}
          />
          <div
            className={'cover__img-wrapper'}
            onClick={() => imgInputRef.current?.click()}
          >
            <Avatar
              initials={''}
              size={'144'}
              img={projectCover}
              cx={'cover__img'}
            />
          </div>
          <FlexRow cx={'cover__controls'}>
            <Button
              fill="white"
              color="blue"
              caption={`${isPlaceholderImage() ? 'Add' : 'Change'} cover`}
              cx={`${
                isPlaceholderImage()
                  ? 'cover__button_add'
                  : 'cover__button_change'
              }`}
              onClick={() => imgInputRef.current?.click()}
            />
            {!isPlaceholderImage() && (
              <Button
                size="36"
                color="blue"
                fill="white"
                icon={deleteIcon}
                cx={'cover__button_delete'}
                onClick={handleCoverRemoval}
              />
            )}
          </FlexRow>
          <Text fontSize={'12'} cx={'cover__placeholder'}>
            Upload only JPG or PNG files. Size no more than 2 MB.
          </Text>
          <input
            ref={imgInputRef}
            style={{ display: 'none' }}
            type={'file'}
            accept={'.png, .jpg, .jpeg'}
            onChange={handleCoverUpload}
          />
        </Panel>
      </FlexCell>
    </>
  );
}

export { Cover };
