import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import * as TimezoneCountries from 'countries-and-timezones';
import { createContext, FC, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { Timezone as OriginalTimezone } from 'countries-and-timezones';

dayjs.extend(timezone);
interface ContextType {
  timezones: Timezone[];
  userTimezone: Timezone;
  findTimezone: (timezone: string) => Timezone;
}

export interface Timezone {
  id: number;
  value: string;
  name: string;
}

const isDST = (date: Dayjs) => {
  const jan = date.set('month', 0).set('day', 1).utcOffset();
  const jul = date.set('month', 6).set('day', 1).utcOffset();
  return Math.max(jan, jul) === date.utcOffset();
};

const allTimezones = Object.values(
  TimezoneCountries.getAllTimezones({ deprecated: true })
);

const formatTimezone = (
  timezone: OriginalTimezone,
  index: number,
  date?: Dayjs
) => ({
  id: index + 1,
  value: `(UTC ${
    isDST((date ? date : dayjs()).tz(timezone.name))
      ? timezone.dstOffsetStr
      : timezone.utcOffsetStr
  }) ${timezone.name}`,
  name: timezone.name,
});

const formatAllTimezones = (): Promise<Timezone[]> => {
  const date = dayjs();
  return new Promise((resolve) =>
    resolve(
      allTimezones.map((list, index) => formatTimezone(list, index, date))
    )
  );
};

const getUserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const findTimezone = (timezone: string): Timezone => {
  const timezoneIndex = allTimezones.findIndex((t) => t.name === timezone);
  return timezoneIndex < 0
    ? formatTimezone(allTimezones[0], 0)
    : formatTimezone(allTimezones[timezoneIndex], timezoneIndex);
};

const useTimezonesProvider = (): ContextType => {
  const initialData = findTimezone(getUserTimeZone());

  const { data } = useQuery(
    [QUERY_KEYS.TIMEZONES.TIMEZONES_LIST],
    () => formatAllTimezones(),
    {
      refetchOnWindowFocus: false,
      initialData: () => [initialData],
    }
  );

  return {
    timezones: data,
    userTimezone: initialData,
    findTimezone,
  };
};

const TimezonesContext = createContext<ContextType>({} as ContextType);

export const TimezonesProvider: FC = ({ children }) => {
  const timezones = useTimezonesProvider();

  return (
    <TimezonesContext.Provider value={timezones}>
      {children}
    </TimezonesContext.Provider>
  );
};

export const useTimezones = () => useContext(TimezonesContext);
