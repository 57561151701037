import { ProjectPosition } from 'models/enums';
import React from 'react';
import ProjectOwner, { OwnerProps } from './components/project-owner';
import TeamMember, { MemberProps } from './components/team-member';

export interface Person {
  firstName: string;
  lastName: string;
  position: string;
  id: string;
}
export interface Props {
  hasEditPermission: boolean;
  canUserTransferOwnership: boolean;
}
const ProjectTeam: React.FC<OwnerProps & MemberProps & Props> = ({
  member,
  value,
  dataSource,
  onValueChange,
  getName,
  projectId,
  onEditClick,
  hasEditPermission,
  canUserTransferOwnership,
  userInfo,
}) => {
  return member.position === ProjectPosition.PO ? (
    <ProjectOwner
      member={member}
      value={value}
      dataSource={dataSource}
      onValueChange={onValueChange}
      getName={getName}
      projectId={projectId}
      hasEditPermission={canUserTransferOwnership}
    />
  ) : (
    <TeamMember
      member={member}
      projectId={projectId}
      onEditClick={onEditClick}
      hasEditPermission={hasEditPermission}
      userInfo={userInfo}
    />
  );
};

export default ProjectTeam;
