import { IEditable, ILens } from '@epam/uui';
export const useLensProps = (
  lens: ILens<any>
): {
  getFullPropObject: (target: string) => any;
  setProp: (target: string, value: any) => void;
  getPropValue: (target: string) => any;
} => {
  // This returns value, Metadata, validation, and changeHandler
  const getFullPropObject = (target: string): IEditable<any> => {
    return lens.prop(target).toProps();
  };
  // This just returns Value
  const getPropValue = (target: string): any => {
    return lens.prop(target).get();
  };
  const setProp = (target: string, value: any): void => {
    lens.prop(target).set(value);
  };
  return { getFullPropObject, setProp, getPropValue };
};
