import { Tooltip, Button, FlexSpacer } from '@epam/promo';
import { FillStyle, ButtonColor } from '@epam/promo';
import { ReactComponent as activeAddIcon } from 'assets/svg/blue_add_icon.svg';
import { ReactComponent as disabledAddIcon } from 'assets/svg/grey_add_icon.svg';

const AddLinkButton = ({
  fill,
  color,
  className,
  tooltip,
  caption,
  onClick,
  isDisabled,
}: {
  fill?: FillStyle;
  color?: ButtonColor;
  className: string;
  tooltip: string;
  caption: string;
  onClick: any;
  isDisabled: boolean;
}) => (
  <>
    <Tooltip content={isDisabled && tooltip}>
      <Button
        fill={fill}
        color={color}
        cx={className}
        caption={caption}
        isDisabled={isDisabled}
        onClick={onClick}
        icon={isDisabled ? disabledAddIcon : activeAddIcon}
      />
    </Tooltip>
    <FlexSpacer />
  </>
);

export default AddLinkButton;
