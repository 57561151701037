import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import { ReactComponent as telescopeIcon } from '@epam/assets/icons/common/communication-telescope-18.svg';
import { ReactComponent as teamsIcon } from '@epam/assets/icons/common/microsoft-teams-18.svg';
import { IconButton } from '@epam/promo';
import { useUuiContext } from '@epam/uui';
import { useQuery } from '@tanstack/react-query';
import CustomAvatar from 'component/Avatar';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { UserInfo } from 'services/api/user/userInfo';
import userService from 'services/api/user/userService';
import { useUserInfo } from 'services/auth-client/user-info-provider';

export const UserCard = (props: UserInfo) => {
  const svc = useUuiContext();

  const { userInfo } = useUserInfo();
  const { data } = useQuery(
    [QUERY_KEYS.USERS.PROFILE_PHOTO, props.id],
    async () => {
      return userService.getUserProfilePicture(props.id);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return (
    <div className="column-name">
      <CustomAvatar
        img={data}
        alt="User Avatar"
        size={'36'}
        cx="user-avatar"
        initials={`${props.firstName[0]}${props.lastName[0]}`}
      />

      <div className="user-information">
        {props.firstName} {props.lastName}
        {userInfo?.id != props.id && (
          <div className="user-contacts">
            <IconButton
              cx="mail-icon"
              color="gray50"
              icon={mailIcon}
              href={`mailto:${props.email}`}
            />
            <IconButton
              cx="teams-icon"
              color="gray50"
              icon={teamsIcon}
              onClick={() => {
                window.open(
                  `https://teams.microsoft.com/l/chat/0/0?users=${props.email}`,
                  '_blank'
                );
              }}
            />
            <IconButton
              color="gray50"
              cx="telescope-icon"
              onClick={() => {
                window.open(
                  `https://telescope.epam.com/who/key/upsaId/${props.externalId}?tab=profile`,
                  '_blank'
                );
              }}
              icon={telescopeIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};
