import { IProjectLink } from '../models/interfaces';
import { ISkilloTech } from '../../project-list/components/project-card/modal';
import { LINK_TYPES } from '../details/components/project-links/constants';
import { getLinkByType } from '../details/components/add-to-showroom/helpers/utils';
import dayjs, { Dayjs } from 'dayjs';
import { formatDateForBE } from 'utils/dateUtils';
const { EMAIL, SLIDE_DECK, CASE_STUDY } = LINK_TYPES;

export const formatName = (userInput: string): string =>
  userInput.replace(/\s{2,}/g, ' ').trim();

export const formatDate = (date: Dayjs): string =>
  formatDateForBE(
    date
      .set('hours', 23)
      .set('minutes', 59)
      .set('seconds', 59)
      .set('milliseconds', 0)
  );

export const convertToBlob = async (dataURL: string) => {
  const blob = await (await fetch(dataURL)).blob();
  return new File([blob], 'logo.png', { type: 'image/png' });
};

export const prepareFormData = (project: any): FormData => {
  const formData = new FormData();
  Object.keys(project).forEach((key: string) => {
    if (key === 'projectLinks') {
      (project[key] || []).forEach((link: IProjectLink, index: number) => {
        if (link.link && link.linkType) {
          formData.set(key + `[${index}].link`, link.link);
          formData.set(key + `[${index}].linkType`, link.linkType);
        }
      });
    } else if (key === 'technologies') {
      (project[key] || []).forEach((tech: ISkilloTech, index: number) => {
        if (tech.skilloId && tech.name) {
          formData.set(key + `[${index}].skilloId`, tech.skilloId);
          formData.set(key + `[${index}].name`, tech.name);
        }
      });
    } else {
      formData.set(key, project[key]);
    }
  });
  return formData;
};

export const createProjectDataObject = async (
  projectFormData: any,
  isNewProject?: any,
  userInfo?: any
) => {
  const projectData: any = {
    keywords: projectFormData.keywords,
    id: projectFormData.id,
    status: projectFormData.status,
    description: projectFormData.projectMetadata.description,
    name: formatName(projectFormData.projectMetadata.name),
    plannedEndDate: formatDate(
      dayjs(projectFormData.projectTimestamps.plannedEndDate)
    ),
  };

  if (projectFormData.technologies?.length > 0) {
    projectData.technologies = projectFormData.technologies;
  }

  if (projectFormData.projectLinks?.length > 0) {
    projectData.projectLinks = projectFormData.projectLinks
      .filter(
        (link: IProjectLink) => link.link && typeof link.link === 'string'
      )
      .map((link: IProjectLink) => ({
        ...link,
        link: link.linkType === EMAIL ? `mailto:${link.link}` : link.link,
      }));
  }

  const caseStudy = getLinkByType(projectFormData.projectLinks, CASE_STUDY);
  const slideDeck = getLinkByType(projectFormData.projectLinks, SLIDE_DECK);

  if (typeof caseStudy?.link !== 'string' && caseStudy?.file) {
    projectData.caseStudy = caseStudy.file;
  }

  if (typeof slideDeck?.link !== 'string' && slideDeck?.file) {
    projectData.slideDeck = slideDeck.file;
  }

  if (isNewProject && userInfo) {
    projectData.plannedStartDate = formatDateForBE(dayjs());
    projectData.projectOwnerId = userInfo.id;
  }

  if (projectFormData.cover.uploadedImage) {
    projectData.file = await convertToBlob(projectFormData.cover.uploadedImage);
  }

  if (projectFormData.event) {
    projectData.challengeId = projectFormData.event.id;
  }

  return projectData;
};
