import {
  IModal,
  Metadata,
  RangeDatePickerValue,
  useUuiContext,
} from '@epam/uui';
import {
  Button,
  FlexRow,
  FlexSpacer,
  LabeledInput,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  Panel,
  RangeDatePicker,
  SuccessNotification,
  Text,
  useForm,
} from '@epam/promo';
import dayjs, { Dayjs } from 'dayjs';
import './contribution-report.scss';
import contributionReportService from '../../../../../services/api/report/contributionReportService';
import { showErrorMessages } from 'component/errorMessagesRenderer/errorMessagesRenderer';
import { validationErrors } from 'models/errors';

interface Props {
  projectId: number;
  modalProps: IModal<string>;
}

interface Range {
  range: RangeDatePickerValue;
}

const ContributionReportModal = ({ projectId, modalProps }: Props) => {
  const svc = useUuiContext();
  const getMetadata = (): Metadata<Range> => ({
    props: {
      range: {
        isRequired: true,
        validators: [
          (val) => {
            const diffMonth =
              val && val.from && val.to
                ? dayjs(val.to).add(1, 'day').diff(dayjs(val.from), 'months')
                : 0;
            if (!(diffMonth >= 1 && diffMonth <= 12))
              return ['The period must be between 1 and 12 months'];
            else return [];
          },
        ],
      },
    },
  });

  const { lens, save } = useForm<Range>({
    validationOn: 'save',
    value: {
      range: {
        from: null,
        to: null,
      },
    },
    onSave: async (rangeDateValue) => {
      await contributionReportService.requestReport(
        projectId,
        dayjs.utc(rangeDateValue.range.from),
        dayjs.utc(rangeDateValue.range.to).add(1, 'day')
      );
      svc.uuiNotifications
        .show((props) => (
          <SuccessNotification {...props}>
            <Text>
              The report will be delivered to your email in 5-10 minutes
            </Text>
          </SuccessNotification>
        ))
        .catch(() => null);
      modalProps.abort();
    },
    onError: (errors: validationErrors) => {
      showErrorMessages(svc, errors);
    },
    getMetadata,
  });

  const today = dayjs();

  return (
    <ModalBlocker
      cx="contribution-report"
      disallowClickOutside
      blockerShadow="dark"
      {...modalProps}
    >
      <ModalWindow cx="contribution-container">
        <Panel>
          <ModalHeader
            title="Contribution Report"
            onClose={() => modalProps.abort()}
          />
          <FlexRow padding="24">
            <Text>
              You can generate report to see how much each team member has
              contributed to project.
            </Text>
          </FlexRow>
          <FlexRow padding="24" cx="contribution-range">
            <LabeledInput
              label="Period"
              cx={
                lens.prop('range').toProps().isInvalid
                  ? 'title-invalid'
                  : 'title'
              }
              {...lens.prop('range').toProps()}
            >
              <FlexRow>
                <RangeDatePicker
                  {...lens.prop('range').toProps()}
                  format="YYYY-MM-DD"
                  filter={(day: Dayjs) => day.valueOf() < today.valueOf()}
                />
              </FlexRow>
            </LabeledInput>
          </FlexRow>

          <ModalFooter cx="contribution-footer">
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => modalProps.abort()}
            />
            <Button
              color="blue"
              caption="Generate Report"
              onClick={() => save()}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default ContributionReportModal;
