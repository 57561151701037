import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DatePicker,
  ModalBlocker,
  ModalHeader,
  ModalWindow,
} from '@epam/promo';
import { useUuiContext, IModal } from '@epam/uui';
import {
  FlexCell,
  FlexRow,
  FlexSpacer,
  Button,
  LabeledInput,
  useForm,
  RadioGroup,
  Alert,
  Text,
} from '@epam/promo';
import { ReactComponent as NotificatioExclamationMark } from '@epam/assets/icons/common/notification-error-fill-24.svg';
import ProjectManagementService from '../../../../../src/services/api/project-management/projectManagementService';
import usePreventReloadOnFormChanged from 'component/hooks/use-prevent-reload';
import './styles.scss';
import { reportSchema } from './validationSchema';
import { Report, ReportParams } from '../../models/interfaces';
import useNotifications from 'hooks/useNotifications';
import { useBlocker } from 'hooks/useBlocker';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { useLeaveConfirm } from 'hooks/useLeaveConfirm';
import { showErrorMessages } from 'component/errorMessagesRenderer/errorMessagesRenderer';
import { validationErrors } from 'models/errors';
import { formatDateForBE } from 'utils/dateUtils';
import dayjs from 'dayjs';

interface Props {
  modalProps: IModal<boolean>;
  reportParams?: ReportParams;
}

const GenerateReport = ({ modalProps, reportParams }: Props) => {
  const location = useLocation();
  const statusParam = new URLSearchParams(location.search).get('status');

  const svc = useUuiContext();
  const { showSuccess } = useNotifications();
  const { lens, save, isChanged } = useForm<Report>({
    value: {
      fileType: 'XLS',
      reportType: 1,
      data2: '',
    },
    onSave: async () => {
      useBlocker(svc);
      const startDate = formatDateForBE(dayjs(lens.get().startDate));
      const endDate = formatDateForBE(dayjs(lens.get().endDate));
      try {
        if (lens.get().reportType == 1) {
          await ProjectManagementService.getContributionReport(
            lens.get().fileType,
            startDate,
            endDate,
            reportParams
          );
        } else if (lens.get().reportType == 2) {
          await ProjectManagementService.getProjectReport(
            lens.get().fileType,
            reportParams
          );
        } else {
          return Promise.reject('Invalid report type!');
        }

        showSuccess(
          'The report will be delivered to your email in 5-10 minutes'
        );
      } catch (errors: any) {
        return Promise.reject(errors);
      } finally {
        useCloseBlocker(svc);
      }
    },
    onError: (errors: validationErrors) => {
      showErrorMessages(svc, errors);
    },
    getMetadata: reportSchema,
    beforeLeave: null,
  });

  const showLeaveConfirmation = useLeaveConfirm(modalProps, isChanged);
  usePreventReloadOnFormChanged({ isChanged: !!isChanged });

  const isOtherModalActive = useMemo(
    () => svc.uuiModals?.getOperations()?.length > 1,
    [modalProps]
  );

  return (
    <ModalBlocker
      {...modalProps}
      abort={showLeaveConfirmation}
      cx="generate-report"
    >
      <ModalWindow>
        <div className="generate-report__modalHeader">
          <ModalHeader
            borderBottom
            onClose={() =>
              isOtherModalActive ? null : showLeaveConfirmation()
            }
          >
            Generate Report
          </ModalHeader>
        </div>
        <div className="generate-report__modalContent">
          {reportParams?.searchPhrase !== '' || statusParam ? (
            <Alert
              cx={'generate-report__notification'}
              color="blue"
              icon={NotificatioExclamationMark}
            >
              <Text size="30">
                This report will be generated based on currently applied
                filters.
              </Text>
            </Alert>
          ) : null}
          <FlexCell width="100%">
            <FlexRow vPadding="12">
              <FlexCell grow={1}>
                <LabeledInput
                  label="Report Type"
                  {...lens.prop('reportType').toProps()}
                >
                  <RadioGroup
                    cx="generate-report__radioGroup"
                    items={[
                      { id: 1, name: 'Contribution Report' },
                      { id: 2, name: 'Project Report' },
                    ]}
                    {...lens.prop('reportType').toProps()}
                  ></RadioGroup>
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            <FlexRow vPadding="12">
              <FlexCell grow={1}>
                <LabeledInput
                  label="File Type"
                  {...lens.prop('fileType').toProps()}
                >
                  <RadioGroup
                    cx="generate-report__radioGroup"
                    items={[
                      { id: 'XLS', name: '.xls' },
                      { id: 'XLSX', name: '.xlsx' },
                    ]}
                    {...lens.prop('fileType').toProps()}
                  ></RadioGroup>
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            {lens.get().reportType === 1 ? (
              <FlexRow vPadding="12">
                <FlexCell grow={1}>
                  <LabeledInput label="Period">
                    <FlexRow vPadding="12" cx="generate-report__dateRow">
                      <div className="generate-report__startDate">
                        <FlexCell>
                          <LabeledInput
                            label="Start Date"
                            {...lens.prop('startDate').toProps()}
                            cx="generate-report__startDateLabel"
                          >
                            <div onKeyDown={(e) => e.preventDefault()}>
                              <DatePicker
                                format="YYYY-MM-DD"
                                {...lens.prop('startDate').toProps()}
                              />
                            </div>
                          </LabeledInput>
                        </FlexCell>
                      </div>
                      <div className="generate-report__endDate">
                        <FlexCell>
                          <LabeledInput
                            label="End Date"
                            {...lens.prop('endDate').toProps()}
                            cx="generate-report__endDateLabel"
                          >
                            <div onKeyDown={(e) => e.preventDefault()}>
                              <DatePicker
                                format="YYYY-MM-DD"
                                {...lens.prop('endDate').toProps()}
                              />
                            </div>
                          </LabeledInput>
                        </FlexCell>
                      </div>
                    </FlexRow>
                  </LabeledInput>
                </FlexCell>
              </FlexRow>
            ) : (
              ''
            )}
            <FlexRow vPadding="12" cx="generate-report__buttons">
              <FlexSpacer />
              <Button
                cx="generate-report__cancelButton"
                caption="Cancel"
                fill="white"
                color="gray50"
                onClick={showLeaveConfirmation}
              />
              <Button color="blue" caption="Download Report" onClick={save} />
            </FlexRow>
          </FlexCell>
        </div>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default GenerateReport;
