import { INotification, CommonContexts } from '@epam/uui';
import { Text, ErrorNotification } from '@epam/promo';

export const showErrorMessages = (
  svc: CommonContexts<any, any>,
  errors: string[]
) =>
  errors.map((error: string) =>
    svc.uuiNotifications
      .show(
        (props: INotification) => (
          <ErrorNotification {...props} cx={'error-message-popup'}>
            <Text>{error}</Text>
          </ErrorNotification>
        ),
        { position: 'bot-left', duration: 5 }
      )
      .catch(() => null)
  );
