import ProjectManagementService from '../../../../../services/api/project-management/projectManagementService';
import AddToShowroomModal from './index';
import { INotification, useUuiContext } from '@epam/uui';
import {
  Button,
  ErrorNotification,
  SuccessNotification,
  Text,
} from '@epam/promo';
import { useState } from 'react';
import {
  IBackendProjectInfoModel,
  IShowroom,
} from '../../../../project-list/components/project-card/modal';
import {
  ProjectShowroomStatus,
  ProjectStatus as EnumProjectStatus,
} from '../../../../../models/enums';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../../../App';
import { QUERY_KEYS } from '../../../../../constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { NOTIFICATION_MESSAGES } from 'constants/uiConstants';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { getLinkByType } from './helpers/utils';
import { LINK_TYPES } from '../project-links/constants';
import RemoveFromShowroomConfirmation from '../remove-from-showroom-confirmation';

export function ShowroomButtons({
  statusShowroom,
  projectStatus,
  links,
  info,
  projectHasActiveShowroomRequest,
  isShowroom,
}: {
  statusShowroom: IShowroom;
  projectStatus: string;
  links: any[];
  info: IBackendProjectInfoModel;
  projectHasActiveShowroomRequest: boolean;
  isShowroom: undefined | boolean;
}) {
  const [disabled, setDisabled] = useState(false);
  const svc = useUuiContext();

  const projectCanBeAddedToShowroom =
    statusShowroom.status !== ProjectShowroomStatus.IN_SHOWROOM &&
    statusShowroom.status !== ProjectShowroomStatus.SHOWROOM_REQUESTED;
  const projectIsInShowroom =
    statusShowroom.status === ProjectShowroomStatus.IN_SHOWROOM;

  const projectIsActiveOrClosed =
    projectStatus === EnumProjectStatus.ACTIVE ||
    projectStatus === EnumProjectStatus.CLOSED;

  const refresh = async () =>
    Promise.all([
      queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
      queryClient.refetchQueries([
        QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
        info.id,
      ]),
    ]);

  const removeFromShowroomMutation = useMutation<any, any, { id: number }>(
    ({ id }) => ProjectManagementService.removeShowroomRequest(id),
    {
      onSuccess: async () => {
        await refresh();
        setDisabled(false);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const revokeShowroomMutation = useMutation<any, any, { id: number }>(
    ({ id }) => ProjectManagementService.revokeShowroomRequest(id),
    {
      onSuccess: async () => {
        await refresh();
        setDisabled(false);
        useCloseBlocker(svc);
        svc.uuiNotifications.show(
          (props) => (
            <SuccessNotification {...props}>
              <Text>
                {NOTIFICATION_MESSAGES.success.revokedShowroomRequest}
              </Text>
            </SuccessNotification>
          ),
          { position: 'bot-left' }
        );
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return (
    <>
      {projectCanBeAddedToShowroom && projectIsActiveOrClosed && (
        <Button
          cx="project-button"
          caption={'Add to Showroom'}
          size="30"
          fill="white"
          color="blue"
          isDisabled={disabled}
          onClick={async () => {
            try {
              setDisabled(true);
              svc.uuiModals
                .show((props) => <BlockerModal modalProps={props} />, {
                  modalId: 'blocker',
                })
                .catch(() => null);
              await refresh();
              const userEpamGitlabLink = getLinkByType(
                links,
                LINK_TYPES.GIT_OTHER
              );
              const userHasActiveRepository =
                await ProjectManagementService.getFieldsStatusToAddProjectToShowroom(
                  parseInt(info.id),
                  !!userEpamGitlabLink && userEpamGitlabLink.link
                );
              useCloseBlocker(svc);
              svc.uuiModals
                .show((modalProps: any) => (
                  <AddToShowroomModal
                    modalProps={modalProps}
                    info={info}
                    links={links}
                    userHasActiveRepository={userHasActiveRepository}
                    enableCancelShowroomButton={() => setDisabled(false)}
                    isClosedFromActive={false}
                    onClosedFromActiveSubmitSuccess={() => {
                      null;
                    }}
                  />
                ))
                .catch(() => setDisabled(false));
            } catch (err) {
              useCloseBlocker(svc);
              svc.uuiNotifications
                .show(
                  (props: INotification) => (
                    <ErrorNotification {...props}>
                      <Text>
                        Error while adding Project to the Showroom, please try
                        again. {<br />}Error: {err}
                      </Text>
                    </ErrorNotification>
                  ),
                  { position: 'bot-left' }
                )
                .catch(() => null);
            }
          }}
        />
      )}

      {projectIsInShowroom && (
        <Button
          cx="project-button"
          caption="Remove from Showroom"
          size="30"
          fill="white"
          color="blue"
          isDisabled={disabled}
          onClick={() => {
            setDisabled(true);
            svc.uuiModals
              .show((modalProps: any) => (
                <RemoveFromShowroomConfirmation
                  modalProps={modalProps}
                  callback={async () => {
                    removeFromShowroomMutation.mutate({
                      id: parseInt(info.id),
                    });
                    if (isShowroom) {
                      modalProps.abort();
                      window.open('/showroom', '_self');
                    }
                  }}
                />
              ))
              .catch(() => {
                setDisabled(false);
              });
          }}
        />
      )}

      {projectHasActiveShowroomRequest && (
        <Button
          cx="project-button"
          caption="Cancel Showroom"
          size="30"
          fill="white"
          color="blue"
          isDisabled={disabled}
          onClick={async () => {
            setDisabled(true);
            svc.uuiModals
              .show((props) => <BlockerModal modalProps={props} />, {
                modalId: 'blocker',
              })
              .catch(() => null);
            revokeShowroomMutation.mutate({ id: parseInt(info.id) });
          }}
        />
      )}
    </>
  );
}
