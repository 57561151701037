import './styles.scss';
import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import EventsRoute from 'modules/events/events-route';
import Opportunities from 'modules/list-opportunities/opportunities';
import MembershipRequest from 'modules/membership-requests';
import { NotFoundPage } from 'modules/not-found-page/not-found-page';
import Dashboard from 'modules/project-list/pages/dashboard';
import DashboardProject from 'modules/project-list/pages/dashboard-project';
import RestorePage from 'modules/project-list/pages/restore-page';
import MyProjects from 'modules/project-list/pages/my-projects';
import ShowroomRequest from 'modules/showroom-requests';
import Showroom from 'modules/showroom/showroom';
import { Permissions } from 'modules/permissions/permissions';
import Footer from '../footer';
import Scrollbar from '../scrollbar';

export const Content: FC<{ editorSwitch: boolean }> = ({ editorSwitch }) => {
  return (
    <Scrollbar>
      <div className="content-container">
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="/my-projects"
            element={<MyProjects editorSwitch={editorSwitch} />}
          />
          <Route
            path="/opportunities"
            element={<Opportunities editorSwitch={editorSwitch} />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard editorSwitch={editorSwitch} />}
          />
          <Route path="/permissions" element={<Permissions />} />
          <Route
            path="/dashboard/createProject"
            element={<Dashboard editorSwitch={editorSwitch} />}
          />
          <Route
            path="/dashboard/project/:projectId"
            element={<DashboardProject editorSwitch={editorSwitch} />}
          />
          <Route
            path="/dashboard/project/:projectId/showroom"
            element={<ShowroomRequest />}
          />
          <Route
            path="/dashboard/project/:projectId/membership/:membershipId"
            element={<MembershipRequest />}
          />
          <Route
            path="/showroom"
            element={<Showroom editorSwitch={editorSwitch} />}
          />
          <Route path="/events" element={<EventsRoute />} />
          <Route
            path="dashboard/project/:projectId/restore"
            element={<RestorePage editorSwitch={editorSwitch} />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </Scrollbar>
  );
};

export default Content;
