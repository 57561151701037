import { RoleType } from '../services/api/user/userInfo';

export const GLOBAL_LOCATION = 'Global';
export const MAX_INITIAL_ACTIVE_PROJECTS = 20;
export const PROJECTS_PAGE_SIZE = 5;
export const OPPORTUNITY_PAGE_SIZE = 5;
export const PROJECT_NAME_CHAR_LIMIT = 40;
export const PROJECT_DESCRIPTION_CHAR_LIMIT = 512;

export const MAX_RESULTS_SKILLO_API = 10;
export const MAX_TECHNOLOGIES = 20;
export const MAX_OPPORTUNITIES_TECHNOLOGIES = 7;
export const MAX_KEYWORD_LENGTH = 40;
export const MAX_PROJECT_LINKS = 30;
export const MAX_PROJECT_NAME_LENGTH = 40;
export const MAX_PROJECT_DESCRIPTION_LENGTH = 512;
export const MAX_RESULTS_GARAGE_USERS = 10;
export const MAX_LINKS_COLUMNS = 3;

export const EVENTS_PAGE_SIZE = 6;
export const EVENT_NAME_CHAR_LIMIT = 60;
export const EVENT_DESCRIPTION_CHAR_LIMIT = 500;
export const EVENT_NAME_MIN_CHAR = 2;

// Enum that decides whether to refresh or not, and whether or not to show a loading spinner
export const enum PROJECT_LIST_REFRESH {
  LOAD_REFRESH,
  BG_REFRESH,
  NONE,
}

export const PROJECT_STATUS = Object.freeze({
  ALL: 'All',
  INITIAL: 'Initial',
  ACTIVE: 'Active',
  CLOSED: 'Completed',
  SUSPENDED: 'Archived',
  DELETE: 'Delete',
});

export const PROJECT_STATUS_COLOR = Object.freeze({
  INITIAL: 'amber',
  ACTIVE: 'green',
  CLOSED: 'blue',
  SUSPENDED: 'gray30',
});

export const EVENT_STATUS = Object.freeze({
  UPCOMING: 'Upcoming',
  IN_PROGRESS: 'In progress',
  ENDED: 'Ended',
});

export const EVENT_STATUS_COLOR = Object.freeze({
  UPCOMING: 'blue',
  IN_PROGRESS: 'green',
  ENDED: 'gray30',
});

/*
 * It is probably not the best place for the below object
 * */
export const NOTIFICATION_MESSAGES = Object.freeze({
  success: {
    isMarkedForDeletion:
      'The project was removed from display and will be permanently deleted in 30 days. To restore, check your email.',
    revokedOpportunityRequest:
      'You have successfully revoked your Opportunity request.',
    createdShowroomRequest:
      'Your project has been sent to approval. Once your request is processed, you will receive an email notification.',
    acceptedShowroomRequest:
      'You have successfully accepted the Showroom request.',
    declinedShowroomRequest:
      'You have successfully declined the Showroom request.',
    revokedShowroomRequest:
      'You have successfully revoked your Showroom request.',
    acceptedMembershipDeletedOpportunity:
      'You have successfully accepted the membership request. The associated opportunity has been deleted.',
    acceptedMembershipKeptOpportunity:
      'You have successfully accepted the membership request. The associated opportunity has been kept.',
    declinedMembershipRequest:
      'You have successfully declined the membership request.',
    newSubscription:
      'You successfully subscribed to notifications about new opportunities',
    changedSubscription: 'Your subscription has changed',
  },
});

export const USER_ROLES: { [key in RoleType]: RoleType } = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
  EVENT_ORGANIZER: 'EVENT_ORGANIZER',
};

export const HEADER_STYLES = {
  COMMON: {
    background: 'linear-gradient(89.78deg, #4181f0 3.35%, #49a8e3 99.67%)',
    color: '#ffffff',
  },
};
