import { useEffect } from 'react';
import { AvatarProps } from '@epam/uui-components';
import { Avatar } from '@epam/promo';
import { drawInitials, fetchImage } from './utils';
import { useQuery } from '@tanstack/react-query';
import { useUuiContext } from '@epam/uui';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

export interface ICustomAvatar extends Omit<AvatarProps, 'img'> {
  initials: string;
  img?: string | { base64file: string };
  lastUpdatedTime?: number;
}

const CustomAvatar = (props: ICustomAvatar) => {
  const svc = useUuiContext();
  const { data, isSuccess, refetch } = useQuery(
    [QUERY_KEYS.PROJECTS.PROJECT_COVER, props.img],
    async () => {
      if (!props.img) return '';
      if (typeof props.img !== 'string') {
        return props.img?.base64file;
      }
      if (props.img.toString().includes('/static/media/')) return props.img;
      if (props.img.toString().includes('undefined')) return '';

      try {
        const url = new URL(props.img);
        return fetchImage(url.toString(), props.initials);
      } catch (err) {
        return '';
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  useEffect(() => {
    if (props.lastUpdatedTime) {
      refetch();
    }
  }, [props.lastUpdatedTime]);

  const image = data === '' ? drawInitials(props.initials, 'grey') : data;

  const p = { ...props, img: image as string };
  return <Avatar {...p} isLoading={!isSuccess} />;
};

export default CustomAvatar;
