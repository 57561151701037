const UNEXPECTED_ERROR_STRING = 'UNEXPECTED_ERROR';

export function getErrorMessage(error: any) {
  if (!error) return '';
  return error instanceof Error
    ? error.message
    : typeof error === 'string'
    ? error
    : UNEXPECTED_ERROR_STRING;
}
