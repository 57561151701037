import axios from 'axios';
import { getErrorMessage } from 'utils/errorsHandling';
import { subError } from 'models/errors';
import { upperCaseFirstLetter, lowerCaseFirstLetter } from 'utils/stringUtils';

import {
  RequestHeader,
  AcceptType,
  ContentType,
  HttpStatusCode,
} from '../api/apiDefinitions';

const requestHeaders = {
  [RequestHeader.ACCEPT]: AcceptType.JSON,
  [RequestHeader.CONTENT_TYPE]: ContentType.JSON,
  [RequestHeader.ACCESS_CONTROL_ALLOW_ORIGIN]: '*',
};

const SKILLO_API_SECRET = process.env.REACT_APP_API_SKILLO_KEY;

const correctSuccessfulStatus = (status: number) => {
  if (Math.floor(status / 100) !== 2) {
    return status;
  }
  return Math.floor(status / 100) * 100;
};

const axiosInstance = axios.create({
  timeout: 300000,
  headers: requestHeaders,
  validateStatus(status) {
    return [HttpStatusCode.SUCCESS].includes(correctSuccessfulStatus(status));
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');

    if (config.url) {
      // remove duplicate slashes
      config.url = config.url.replace(/([^:]\/)\/+/g, '$1');
    }

    if (config.url?.includes('api.epam.com/skill/') && config.headers) {
      config.withCredentials = false;
      delete config.headers[RequestHeader.ACCESS_CONTROL_ALLOW_ORIGIN];
      config.headers['X-Api-Key'] = SKILLO_API_SECRET as string;
      return config;
    }

    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(getErrorMessage(error));
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.url?.includes('dictionary/project-link-type')) {
      response.data.items.forEach((item: any) => {
        if (item.label.includes('Other')) {
          item.label = 'Other';
        }
      });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(['Network Error.']);
    }
    const errorMessages = [];
    if (error.response?.data?.message) {
      errorMessages.push(error.response.data.message);
    }

    const subErrors = error.response?.data?.subErrors;
    if (subErrors) {
      subErrors.forEach((subError: subError) =>
        errorMessages.push(
          subError.field
            ? `${upperCaseFirstLetter(subError.field)}: ${lowerCaseFirstLetter(
                subError.message
              )}`
            : upperCaseFirstLetter(subError.message)
        )
      );
    }
    return Promise.reject(
      errorMessages.length ? errorMessages : getErrorMessage(error)
    );
  }
);

export const { CancelToken } = axios;
export const updateAxiosHeader = (token: string) => {
  axiosInstance.defaults.headers.common[RequestHeader.AUTHORIZATION] =
    'Bearer ' + token;
};

export default axiosInstance;
