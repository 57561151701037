import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import projectListService from 'services/api/project-list/projectListService';
import { queryClient } from 'App';
import { QUERY_KEYS } from 'constants/queryKeys';
import { SuccessNotification, Text } from '@epam/promo';
import { useUuiContext } from '@epam/uui';
import { useMutation, useQuery } from '@tanstack/react-query';
import Dashboard from '../dashboard';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

interface Props {
  editorSwitch: boolean;
}

const RestorePage = ({ editorSwitch }: Props): React.ReactElement | null => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const svc = useUuiContext();

  if (!projectId) {
    return <Dashboard editorSwitch={editorSwitch} />;
  }

  const { data: currentProject } = useQuery(
    [QUERY_KEYS.PROJECTS.PROJECT_DETAILS, projectId],
    () => projectListService.getProjectById(projectId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { mutate } = useMutation<any, any, number>(
    (id: number) => projectListService.restoreProject(id),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
        ]);
        navigate('/dashboard');
        svc.uuiNotifications
          .show((props) => (
            <SuccessNotification {...props}>
              <Text>
                You have successfully restored the project [
                {currentProject?.name}].
              </Text>
            </SuccessNotification>
          ))
          .catch(() => null);
      },
      onError: (error: any) => {
        useCloseBlocker(svc);
        navigate('/dashboard');
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  useEffect(() => {
    if (currentProject) {
      mutate(parseInt(projectId));
    }
  }, [currentProject]);
  return null;
};

export default RestorePage;
