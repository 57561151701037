import React from 'react';
import { FlexRow, IconButton, Panel, Text, Tooltip } from '@epam/promo';
import './styles.scss';
import { cx, useUuiContext } from '@epam/uui';
import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import { ReactComponent as teamsIcon } from '@epam/assets/icons/common/microsoft-teams-18.svg';
import { ReactComponent as telescopeIcon } from '@epam/assets/icons/common/communication-telescope-18.svg';
import { FlexSpacer } from '@epam/uui-components';
import ProjectMemberDeletionModal from '../../../project-member-deletion-modal';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-24.svg';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-24.svg';
import { IProjectMember } from 'modules/project-list/components/project-card/modal';
import Avatar from 'component/Avatar';
import { UserInfo } from 'services/api/user/userInfo';
import { useQuery } from '@tanstack/react-query';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import userService from 'services/api/user/userService';
import { useCloseBlocker } from 'hooks/useCloseBlocker';

export interface MemberProps {
  member: IProjectMember;
  projectId: string;
  onEditClick: (member: IProjectMember) => void;
  hasEditPermission: boolean;
  userInfo?: UserInfo | null;
}

const TeamMember: React.FC<MemberProps> = ({
  member,
  projectId,
  onEditClick,
  hasEditPermission,
  userInfo,
}) => {
  const svc = useUuiContext();

  const SESSION_KEY = sessionStorage.key(0) || '';
  const SESSION_STORAGE_STRING = sessionStorage
    .getItem(SESSION_KEY)
    ?.toLowerCase();

  const { data } = useQuery(
    [QUERY_KEYS.USERS.PROFILE_PHOTO, member.id],
    async () => {
      return userService.getUserProfilePicture(member.id);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  return (
    <FlexRow
      cx={cx(
        'project-detail-team-member',
        (hasEditPermission || member.externalId === userInfo?.externalId) &&
          'has-action'
      )}
      alignItems="center"
      spacing="12"
    >
      <Avatar
        alt="member"
        img={data}
        size={'36'}
        initials={`${member.firstName.charAt(0)}${member.lastName.charAt(0)}`}
      />

      <Panel>
        <FlexRow cx="project-detail-team-member__name">
          <Text font="sans-semibold">{`${member.firstName} ${member.lastName}`}</Text>
          {!SESSION_STORAGE_STRING?.includes(member.email.toLowerCase()) &&
          member.id != userInfo?.id ? (
            <div className="project-detail-team-member__icons">
              <IconButton
                cx="mail-icon"
                color="gray50"
                icon={mailIcon}
                href={`mailto:${member.email}`}
              />
              <IconButton
                cx="teams-icon"
                color="gray50"
                icon={teamsIcon}
                onClick={() => {
                  window.open(
                    `https://teams.microsoft.com/l/chat/0/0?users=${member.email}`,
                    '_blank'
                  );
                }}
              />
              <IconButton
                color="gray50"
                cx="telescope-icon"
                onClick={() => {
                  window.open(
                    `https://telescope.epam.com/who/key/upsaId/${member.externalId}?tab=profile`,
                    '_blank'
                  );
                }}
                icon={telescopeIcon}
              />
            </div>
          ) : (
            ''
          )}
        </FlexRow>

        <FlexRow cx="project-detail-team-member__role">
          <Text size="24">{member.position}</Text>
        </FlexRow>
      </Panel>

      <FlexSpacer />

      {(hasEditPermission || member.externalId === userInfo?.externalId) && (
        <>
          <div className="project-detail-team-member__btnicon">
            <Tooltip content="Delete" placement="top">
              <IconButton
                color="blue"
                icon={deleteIcon}
                onClick={() =>
                  svc.uuiModals.show((modalProps) => (
                    <ProjectMemberDeletionModal
                      modalBlockerProps={modalProps}
                      projectId={projectId}
                      member={member}
                    />
                  ))
                }
              />
            </Tooltip>
          </div>
        </>
      )}
      {hasEditPermission && (
        <>
          <div className="project-detail-team-member__btnicon">
            <Tooltip content="Edit" placement="top">
              <IconButton
                color="blue"
                icon={editIcon}
                onClick={() => onEditClick(member)}
              />
            </Tooltip>
          </div>
        </>
      )}
    </FlexRow>
  );
};

export default TeamMember;
