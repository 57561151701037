import { Step, StepLabel, Stepper } from '@mui/material';
import './styles.scss';
import { useEffect, useState } from 'react';

type StepperProps = {
  activeStep: number;
  steps: Array<string>; // In the context of a stepper, utilize an empty string as the last element in the array to display a horizontal line solely at the end of the stepper
  stepClick: (n: number) => void;
  stepsCompleted: {
    [k: number]: boolean;
  };
  enableAllSteps?: boolean;
};

const StepperController = ({
  activeStep,
  steps,
  stepClick,
  stepsCompleted,
  enableAllSteps,
}: StepperProps) => {
  const [allVisitedSteps, setActiveStep] = useState<number[]>([]);
  const activatedStep = activeStep + 1;

  const stepCounterIcon = (props: any) => {
    return (
      <span
        className={`circle ${
          activatedStep === props.icon ? 'circle-active' : ''
        }`}
      >
        <span className="circle-text">{props.icon}</span>
      </span>
    );
  };

  const handleStep = (step: number) => () => {
    if (enableAllSteps) {
      stepClick(step);
      return;
    }
    setActiveStep(
      allVisitedSteps.filter((visitedStep: number) => visitedStep <= step)
    );
    if (allVisitedSteps.indexOf(step + 1) !== -1) {
      stepClick(step);
    }
  };

  useEffect(() => {
    setActiveStep((oldArray: number[]) => [...oldArray, activatedStep]);
  }, [activatedStep]);

  return (
    <Stepper
      activeStep={activeStep}
      classes={{
        root: 'stepper-root',
      }}
    >
      {steps.map((label, index) => {
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        return (
          <Step
            key={label}
            classes={{
              root: 'step-root',
            }}
            completed={stepsCompleted[index + 1]}
            style={
              activatedStep !== index + 1 &&
              allVisitedSteps.indexOf(index + 1) !== -1
                ? { cursor: 'pointer' }
                : {}
            }
          >
            {label && (
              <StepLabel
                classes={{
                  root: 'step-label-root',
                  iconContainer: 'step-counter',
                  labelContainer: 'step-label',
                  active: 'step-active',
                }}
                StepIconComponent={(props) => stepCounterIcon(props)}
                {...labelProps}
                onClick={handleStep(index)}
                style={
                  (activatedStep !== index + 1 &&
                    allVisitedSteps.indexOf(index + 1) !== -1) ||
                  enableAllSteps
                    ? { cursor: 'pointer' }
                    : {}
                }
              >
                {label}
              </StepLabel>
            )}
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperController;
