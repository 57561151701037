import React, { createContext, FC, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import userService from '../api/user/userService';
import { useAuth } from 'react-oidc-context';
import { UserInfo } from '../api/user/userInfo';
import projectListService from '../api/project-list/projectListService';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { ProjectStatus } from '../../models/enums';
import { useUuiContext } from '@epam/uui';
import { DefaultError } from 'component/notification/DefaultError';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { MAX_INITIAL_ACTIVE_PROJECTS } from '../../constants/uiConstants';

const useUserInfoProvider = (): ContextType => {
  const auth = useAuth();
  const svc = useUuiContext();

  const { data: userInfo, refetch } = useQuery(
    [QUERY_KEYS.USERS.ME],
    () => userService.getUserProfile(),
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const { data: activeProjectsCount, isFetching } = useQuery(
    [QUERY_KEYS.USERS.PROJECT_COUNT],
    async () => {
      const projects = await projectListService.searchProjects(
        null,
        null,
        null,
        userInfo?.id
      );
      return projects.requestedProjectList.filter(
        (project) =>
          project.status === ProjectStatus.INITIAL ||
          project.status == ProjectStatus.ACTIVE
      ).length;
    },
    {
      enabled: !!userInfo,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const logOut = async () => {
    try {
      const redirect_uri = window.location.href.includes(
        process.env.REACT_APP_NEW_DOMAIN_URL as string
      )
        ? process.env.REACT_APP_NEW_DOMAIN_URL
        : window.location.href.includes(
            process.env.REACT_APP_BASE_URL as string
          ) ||
          window.location.href.includes(process.env.REACT_APP_LAB_URL as string)
        ? process.env.REACT_APP_BASE_URL
        : process.env.REACT_APP_PROXY_URL;

      await auth.signoutRedirect({
        extraQueryParams: {
          redirect_uri: encodeURI(redirect_uri as string),
        },
      });
      localStorage.clear();
      sessionStorage.clear();
    } catch (er) {
      console.log(er);
    }
  };

  return {
    userInfo,
    refetch,
    logOut,
    hasProjectCreateQuota: isFetching
      ? false
      : (activeProjectsCount as number) < MAX_INITIAL_ACTIVE_PROJECTS,
  };
};

type ContextType = {
  userInfo?: UserInfo | null;
  refetch: () => void;
  logOut: (cb?: () => void) => void;
  hasProjectCreateQuota: boolean;
};

const UserInfoContext = createContext<ContextType>({} as ContextType);

export const UserInfoProvider: FC<{}> = ({ children }) => {
  const auth = useUserInfoProvider();

  return (
    <UserInfoContext.Provider value={auth}>{children}</UserInfoContext.Provider>
  );
};

export const useUserInfo = () => useContext(UserInfoContext);
