import { useUuiContext } from '@epam/uui';
import { useEffect } from 'react';

function usePreventReloadOnFormChanged({
  isChanged,
}: {
  isChanged: boolean;
}): void {
  const svc = useUuiContext();
  useEffect(() => {
    if (isChanged) {
      svc.uuiLocks.acquire(() => Promise.resolve());
    }
    return () => {
      if (isChanged) {
        svc.uuiLocks.release(svc.uuiLocks.getCurrentLock() as object);
      }
    };
  }, [isChanged]);
}

export default usePreventReloadOnFormChanged;
