import {
  FlexRow,
  Panel,
  Tag,
  FlexSpacer,
  Tooltip,
  IconButton,
  Text,
} from '@epam/promo';
import { cx, useUuiContext } from '@epam/uui';
import { IOpportunity } from 'modules/list-opportunities/models/model';
import { FC, useEffect, useState } from 'react';
import { OnDeleteModel } from '../on-delete';
import { OpportunityRequestButton } from '../opportunity-request-button';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-18.svg';
import { queryClient } from 'App';
import useNotificationsModal from 'component/NotifiactionsModal';
import { QUERY_KEYS } from 'constants/queryKeys';
import opportunityService from 'services/api/opportunity/opportunityService';
import { OpportunityRefreshButton } from '../opportunity-refresh-button';
import ApplicantsList from '../../applicant-list';

export interface IOpportunityProps {
  opportunity: IOpportunity;
  userHasEditPermission: boolean;
  projectId: number;
  projectName: string;
  canModifyApplicants: boolean;
  onEdit: (opportunity: IOpportunity) => void;
}

export const Opportunity: FC<IOpportunityProps> = ({
  opportunity,
  userHasEditPermission,
  projectName,
  projectId,
  canModifyApplicants,
  onEdit,
}) => {
  const svc = useUuiContext();
  const { successNotification } = useNotificationsModal();
  const [applicants, setApplicants] = useState(opportunity.applicants);
  const [isOpportunityOpen, setIsOpportunityOpen] = useState(true);

  useEffect(
    () => setApplicants(opportunity.applicants),
    [opportunity.applicants]
  );

  const onApplicantAccept = async (
    requestId: number,
    closeOpportunity: boolean
  ) => {
    if (closeOpportunity) {
      setIsOpportunityOpen(false);
      setApplicants([]);
      await opportunityService.delOpportunity(opportunity.id);
    } else {
      setApplicants((prevApplicants) =>
        prevApplicants?.filter(
          (applicant) => applicant.membershipRequestId !== requestId
        )
      );
    }

    await Promise.all([
      queryClient.refetchQueries([QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST]),
      queryClient.refetchQueries([
        QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
        projectId,
      ]),
    ]);

    if (!closeOpportunity) {
      successNotification(
        'Your opportunity has been successfully updated and brought up to top in the opportunity list.'
      );
    }
  };

  const onApplicantReject = async (requestId: number) => {
    setApplicants((prevApplicants) =>
      prevApplicants?.filter(
        (applicant) => applicant.membershipRequestId !== requestId
      )
    );

    await Promise.all([
      queryClient.refetchQueries([QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST]),
      queryClient.refetchQueries([
        QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
        projectId,
      ]),
    ]);

    successNotification(
      'Your opportunity has been successfully updated and brought up to top in the opportunity list.'
    );
  };

  return (
    <Panel cx="project-detail">
      <FlexRow
        cx={cx(
          'project-detail__opportunities',
          userHasEditPermission && 'has-action'
        )}
        alignItems="center"
        spacing="12"
      >
        <Panel>
          <FlexRow cx={'project-detail__opportunities__name'} vPadding="12">
            <Text
              cx="project-detail__opportunities__name"
              font="sans-semibold"
              fontSize="16"
            >
              {opportunity.name}
            </Text>
          </FlexRow>
          {opportunity.technologies.length !== 0 && (
            <FlexRow alignItems="top" spacing="6" cx={'wrapped'}>
              {opportunity.technologies &&
                opportunity.technologies.map((tech) => (
                  <Tag
                    key={tech.skilloId}
                    size={'18'}
                    caption={tech.name}
                    cx="project-detail-tech"
                  />
                ))}
            </FlexRow>
          )}
          <FlexRow cx={'project-detail__opportunities__description'}>
            <Text font="sans" fontSize="14">
              {opportunity.description}
            </Text>
          </FlexRow>
          <OpportunityRequestButton
            opportunity={opportunity}
            projectID={projectId}
            isVisible={false}
          />
        </Panel>
        <FlexSpacer />
        {userHasEditPermission && (
          <>
            <div className="project-detail__opportunities__refresh">
              <OpportunityRefreshButton
                opportunity={opportunity}
                projectId={projectId}
              />
            </div>
            <div className="project-detail__opportunities__icon">
              <Tooltip content="Edit">
                <IconButton
                  color="blue"
                  icon={editIcon}
                  onClick={() => onEdit(opportunity)}
                />
              </Tooltip>
            </div>
            <div className="project-detail__opportunities__icon">
              <Tooltip content="Delete">
                <IconButton
                  color="blue"
                  icon={deleteIcon}
                  onClick={() =>
                    svc.uuiModals.show((modalProps: any) => (
                      <OnDeleteModel
                        modalProps={modalProps}
                        projectId={projectId}
                        info={opportunity}
                      />
                    ))
                  }
                />
              </Tooltip>
            </div>
          </>
        )}
      </FlexRow>
      {!!applicants?.length && isOpportunityOpen && canModifyApplicants && (
        <ApplicantsList
          applicants={applicants}
          opportunityName={opportunity.name}
          projectName={projectName}
          onAccept={onApplicantAccept}
          onReject={onApplicantReject}
        />
      )}
    </Panel>
  );
};
