import { Metadata } from '@epam/uui';
import { Report } from '../../models/interfaces';
import dayjs from 'dayjs';
import { getCurrentDate } from 'utils/dateUtils';

export const reportSchema = (reportInfo: Report): Metadata<Report> => ({
  props: {
    reportType: { isRequired: false },
    fileType: { isRequired: false },
    startDate: {
      isRequired: reportInfo.reportType === 1 ? true : false,
      validators: [
        (startVal, parentVal) => {
          if (reportInfo.reportType !== 1) {
            return [];
          }

          if (!startVal) {
            return ['This field is required.'];
          }

          const startDate = dayjs(startVal);
          if (startDate > getCurrentDate()) {
            return ['Start Date cannot be in the future.'];
          }

          if (parentVal.endDate) {
            const endDate = dayjs(parentVal.endDate);
            if (startDate > endDate) {
              return ['Start Date cannot be later than End Date.'];
            }

            const monthDiff = endDate.add(1, 'day').diff(startDate, 'months');
            if (monthDiff < 1 || monthDiff > 12) {
              return ['Date difference must be between 1 and 12 months'];
            }
          }

          return [false];
        },
      ],
    },
    endDate: {
      isRequired: reportInfo.reportType === 1 ? true : false,
      validators: [
        (endVal) => {
          if (reportInfo.reportType === 1 && !endVal) {
            return ['This field is required.'];
          }
          if (
            reportInfo.reportType === 1 &&
            dayjs(endVal || '') > getCurrentDate()
          ) {
            return ['End Date cannot be in the future.'];
          }
          return [false];
        },
      ],
    },
  },
});
